import dayjs from 'dayjs'
import * as z from 'zod'
import i18n from '../../../../../i18n/i18n'

const schema = z
  .object({
    startDate: z
      .string()
      .refine(
        date => dayjs(date, 'DD/MM/YYYY', true).isValid(),
        i18n.t('dismemberment-table.form.error.invalid-start-date')
      ),
    endDate: z
      .string()
      .nullable()
      .optional()
      .refine(
        date => !date || dayjs(date, 'DD/MM/YYYY', true).isValid(),
        i18n.t('dismemberment-table.form.error.invalid-end-date')
      ),
  })
  .superRefine((data, ctx) => {
    if (data.endDate) {
      const start = dayjs(data.startDate, 'DD/MM/YYYY')
      const end = dayjs(data.endDate, 'DD/MM/YYYY')

      if (start.isAfter(end)) {
        ctx.addIssue({
          code: 'custom',
          path: ['endDate'],
          message: i18n.t('dismemberment-table.form.error.start-date-before-end-date'),
        })
      }
    }
  })

type FormValues = z.infer<typeof schema>

export { schema }
export type { FormValues }
