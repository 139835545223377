import React from 'react'
import { LineProps } from './Props'

const DismembermentTableRow = ({ title, lines, rateKey, classes }: LineProps) => {
  return (
    <tr>
      <td align='left' className='table__header'>
        {title}
      </td>
      {lines.map(line => (
        <td key={line.years} className={classes} align='center'>
          {line[rateKey]}%
        </td>
      ))}
    </tr>
  )
}

export default DismembermentTableRow
