import React, { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import { SubmitHandler, useForm, useFormState } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
// Notification lib
import 'react-toastify/dist/ReactToastify.min.css'
import eyeIcon from '../../../../../../assets/images/icons/eye.svg'
import uploadIcon from '../../../../../../assets/images/icons/upload.svg'
import '../../../../../../assets/styles/components/_rating.scss'
import { useCheckRole } from '../../../../../../domain/CustomHooks/useCheckRole'
import { AccountingInterface } from '../../../../../../domain/Product/Accounting'
import { CategorizationInterface } from '../../../../../../domain/Product/Categorization'
import { DocumentProductInterface } from '../../../../../../domain/Product/Document/Document'
import { DocumentationInterface } from '../../../../../../domain/Product/Documentation'
import { GeneralInformationInterface } from '../../../../../../domain/Product/GeneralInformation'
import { NetAssetValueInterface } from '../../../../../../domain/Product/NetAssetValue'
import { ProductInterface } from '../../../../../../domain/Product/Product'
import { ProductType } from '../../../../../../domain/Product/ProductType'
import { Usage, UsageInterface } from '../../../../../../domain/Product/Usage'
import { UsageItem } from '../../../../../../domain/Product/UsageItem'
import { ValorisationInterface } from '../../../../../../domain/Product/Valorisation'
import { WithdrawalInterface } from '../../../../../../domain/Product/Withdrawal'
import { ReferentielInterface } from '../../../../../../domain/Referentiel/ReferentielInterface'
import { DecimalStepOptions } from '../../../../../../fixtures/DecimalStepOptions'
import DocumentGateway from '../../../../../../gateway/Product/Document/DocumentGateway'
import ProductGateway from '../../../../../../gateway/Product/ProductGateway'
import AddUseCase from '../../../../../../useCase/Product/Add/AddUseCase'
import UpdateUseCase from '../../../../../../useCase/Product/Update/UpdateUseCase'
import { setOpenDocumentList, setOpenProductUploadForm } from '../../../../../store/component/event'
import { reloadProduct } from '../../../../../store/component/product'
import { useAppDispatch, useAppSelector } from '../../../../../store/hook'
import envVariable from '../../../../util/envVariable'
import { usePrompt } from '../../../../util/Navigation'
import { setReadOnlyFormElements } from '../../../../util/setReadOnlyFormElements'
import { toastError, toastSuccess } from '../../../../util/Toast'
import FooterBlock from '../../../Block/FooterBlock'
import CityAndPostcode from '../../../Datalist/CityAndPostcode/CityAndPostcode'
import CountryDatalist from '../../../Datalist/Country/CountryDatalist'
import CheckboxToggle from '../../../Elements/CheckboxToggle'
import Input from '../../../Elements/Input'
import InputDate from '../../../Elements/InputDate'
import InputNumber from '../../../Elements/InputNumber'
import RadioChoices from '../../../Elements/RadioChoices'
import SelectCustom from '../../../Elements/Select'
import ConfirmationModal from '../../../Modal/ConfirmationModal'

interface IFormInput {
  status: string
  createdAt: string
  unicia: string
  classification: string
  generalInformation: GeneralInformationInterface
  valorisation: ValorisationInterface
  usage: UsageInterface
  withdrawal: WithdrawalInterface
  accounting: AccountingInterface
  categorization: CategorizationInterface
  documentation: DocumentationInterface
  netAssetValue: NetAssetValueInterface
  distributionRadio: string
  msConfrontationRadio: string
  recurrenceRadio: string
  categorizationEmtEnabledRadio: string
  categorizationEptEnabledRadio: string
  categorizationTptEnabledRadio: string
  decimalStep: number
}

type Props = {
  product: ProductInterface
  callbackIsMultiProduct: (response: boolean) => void
  referential: ReferentielInterface
  isLectureMode?: boolean
}

const GeneralInformationBlock: FunctionComponent<Props> = ({
  product,
  callbackIsMultiProduct,
  referential,
  isLectureMode,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { productType } = useParams()
  const navigate = useNavigate()
  const productGateway = new ProductGateway()

  const countryByDefault = useAppSelector(({ referential }) => referential.countryByDefault)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [documentArray, setDocumentArray] = useState<DocumentProductInterface[] | null>(null)
  const [documentStatusArray, setDocumentStatusArray] = useState<DocumentProductInterface[] | null>(null)
  const [documentDicArray, setDocumentDicArray] = useState<DocumentProductInterface[] | null>(null)
  const [documentInformationArray, setDocumentInformationArray] = useState<DocumentProductInterface[] | null>(null)
  const [documentSubscriptionArray, setDocumentSubscriptionArray] = useState<DocumentProductInterface[] | null>(null)
  const [documentAnnualReportArray, setDocumentAnnualReportArray] = useState<DocumentProductInterface[] | null>(null)

  const openProductUploadForm = useAppSelector(state => state.event.openProductUploadForm)

  useEffect(() => {
    if (countryByDefault.length > 0) {
      if (product && product.generalInformation) {
        setValue(
          'generalInformation.country',
          product.generalInformation.country ? product.generalInformation.country : countryByDefault[0]
        )
        if (product.generalInformation.city) {
          setValue('generalInformation.city', product.generalInformation.city)
        }
      } else {
        setValue('generalInformation.country', countryByDefault[0])
      }
    }
  }, [countryByDefault])

  useEffect(() => {
    if (openProductUploadForm.documents) {
      handleSubmit(onConfirm)()
    }
  }, [openProductUploadForm])

  useEffect(() => {
    if (undefined !== product && null !== product) {
      setValue('status', product.status ?? '')
      setValue('decimalStep', product.decimalStep ?? '')
      setValue('unicia', product.unicia ?? '')
      setValue('createdAt', product.createdAt || '')
      setValue('classification', product.classification ?? '')
      if (product.generalInformation) {
        setValue('generalInformation', product.generalInformation)
        setValue('generalInformation.country', product.generalInformation.country)
        setValue('msConfrontationRadio', product.generalInformation.msConfrontation ? '1' : '0')
        setValue('recurrenceRadio', product.generalInformation.recurrence ? '1' : '0')
        setValue(
          'generalInformation.registryKeeper',
          product.generalInformation.registryKeeper
            ? product.generalInformation.registryKeeper
            : String(referential.product?.registry_keeper[0]?.value ?? '')
        )
      }
      if (product.valorisation) {
        setValue('valorisation', product.valorisation)
      }
      if (product.usage) {
        setValue('usage', product.usage)
      }
      if (product.withdraw) {
        setValue('withdrawal', product.withdraw)
      }

      if (product.accounting) {
        setValue('accounting', product.accounting)
      }
      if (product.categorization) {
        setValue('categorization', product.categorization)
        setValue('categorizationEmtEnabledRadio', product.categorization.emt.enabled ? '1' : '0')
        setValue('categorizationEptEnabledRadio', product.categorization.ept.enabled ? '1' : '0')
        setValue('categorizationTptEnabledRadio', product.categorization.tpt.enabled ? '1' : '0')
      }

      if (product.id !== null) {
        new DocumentGateway(product.id).getFiles().then(response => {
          setDocumentArray(response)
        })
      }
    }
  }, [product?.id, openProductUploadForm.show])

  useEffect(() => {
    if (documentArray && documentArray.length > 0) {
      setDocumentStatusArray(documentArray.filter(item => item.type === 'STATUS'))
      setDocumentDicArray(documentArray.filter(item => item.type === 'DIC'))
      setDocumentInformationArray(documentArray.filter(item => item.type === 'INFORMATION'))
      setDocumentSubscriptionArray(documentArray.filter(item => item.type === 'SUBSCRIPTION'))
      setDocumentAnnualReportArray(documentArray.filter(item => item.type === 'ANNUALREPORT'))
    }
  }, [documentArray])

  useEffect(() => {
    setValue(
      'documentation.status.date',
      documentStatusArray && documentStatusArray.length > 0 ? documentStatusArray[0].updatedAt : ''
    )
  }, [documentStatusArray])

  useEffect(() => {
    setValue(
      'documentation.dic.date',
      documentDicArray && documentDicArray.length > 0 ? documentDicArray[0].updatedAt : ''
    )
  }, [documentDicArray])

  useEffect(() => {
    setValue(
      'documentation.information.date',
      documentInformationArray && documentInformationArray.length > 0 ? documentInformationArray[0].updatedAt : ''
    )
  }, [documentInformationArray])

  useEffect(() => {
    setValue(
      'documentation.subscription.date',
      documentSubscriptionArray && documentSubscriptionArray.length > 0 ? documentSubscriptionArray[0].updatedAt : ''
    )
  }, [documentSubscriptionArray])

  useEffect(() => {
    setValue(
      'documentation.annualReport.date',
      documentAnnualReportArray && documentAnnualReportArray.length > 0 ? documentAnnualReportArray[0].updatedAt : ''
    )
  }, [documentAnnualReportArray])

  const {
    register,
    watch,
    control,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    getValues,
  } = useForm<IFormInput>()

  const watchCountry = watch('generalInformation.country')

  const onSubmit: SubmitHandler<IFormInput> = data => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return <ConfirmationModal onConfirm={() => onConfirm(data)} onClose={onClose} />
      },
    })
  }

  const onConfirm = (data: any) => {
    reset(data)
    if (
      null == product.productType &&
      referential.product.product_type.findIndex(type => type.value === productType) > -1
    ) {
      product.productType = new ProductType(
        null,
        null,
        null,
        null,
        undefined !== productType ? productType : 'other',
        null,
        null,
        true
      )
    }
    product.status = data.status
    product.classification = data.classification
    product.unicia = data.unicia
    product.generalInformation = data.generalInformation
    product.valorisation = data.valorisation
    product.withdraw = data.withdrawal

    product.accounting = data.accounting
    product.categorization = data.categorization
    product.documentation = data.documentation
    if (data.usage) {
      product.usage = new Usage(
        new UsageItem(data.usage.newPart.delay, data.usage.newPart.duration),
        new UsageItem(data.usage.transfer.delay, data.usage.transfer.duration),
        new UsageItem(data.usage.mutation.delay, data.usage.mutation.duration)
      )
    }

    product.decimalStep = parseInt(data.decimalStep)

    if (null !== product.id) {
      setIsLoading(true)
      new UpdateUseCase(productGateway).execute(product).then(response => {
        if (null !== response) {
          toastSuccess(t('product.notify.update-success'))
        } else {
          toastError(t('product.notify.update-error'))
        }
        setIsLoading(false)
      })
    } else {
      setIsLoading(true)
      new AddUseCase(productGateway).execute(product).then(uuid => {
        if (null !== uuid) {
          toastSuccess(t('product.notify.add-success'))
          dispatch(reloadProduct())
          navigate(`/${t('url.product.edit')}/${uuid}`)
        } else {
          toastError(t('product.notify.add-error'))
        }
        setIsLoading(false)
      })
    }
  }

  const { isDirty } = useFormState({
    control,
  })
  usePrompt(isLectureMode ? false : isDirty, handleSubmit(onConfirm))

  const isMultiProduct = watch('generalInformation.multiproduct')
  const isSubProduct = watch('generalInformation.subproduct')
  const watchUsageNewPartDelay = watch('usage.newPart.delay')
  const watchUsageTransferDelay = watch('usage.transfer.delay')
  const watchUsageMutationDelay = watch('usage.mutation.delay')
  const watchIsin = watch('generalInformation.isin')
  const watchStep = watch('decimalStep')

  useEffect(() => {
    callbackIsMultiProduct(isMultiProduct)
  }, [isMultiProduct])

  function viewFile(documents: DocumentProductInterface[] | null) {
    dispatch(
      setOpenDocumentList({
        show: true,
        documentList: documents,
        gateway: new DocumentGateway(product.id),
      })
    )
  }

  const showMultiProduct = () => {
    return productType !== 'scpi' || product?.productType?.slug !== 'scpi'
  }

  const watchStatus = watch('status')

  const measuredRef = useCallback(node => {
    if (node !== null && isLectureMode) {
      setReadOnlyFormElements(true, node)
    }
  }, [])

  return (
    <form onSubmit={handleSubmit(onSubmit)} ref={measuredRef}>
      <div className='flex-container'>
        {(productType === 'scpi' || product?.productType?.slug === 'scpi') && (
          <RadioChoices
            classes={'col-md-12'}
            id='classification'
            name='classification'
            label={t('product.form.classification')}
            register={register}
            options={referential.product.classification_scpi}
          />
        )}
        {(productType === 'fia' ||
          product?.productType?.slug === 'fia' ||
          productType == 'sc' ||
          product?.productType?.slug === 'sc' ||
          productType === 'other' ||
          product?.productType?.slug === 'other') && (
          <RadioChoices
            classes={'col-md-12'}
            id='classification'
            name='classification'
            label={t('product.form.classification')}
            register={register}
            options={referential.product.classification_fia}
          />
        )}
        <div className='col-md-12'>
          <div className={`form-bloc form--bloc--general-information`}>
            <div className='form-bloc__title'>{t('product.form.general-information.title')}</div>
            <div className='form-bloc__form flex-container'>
              {showMultiProduct() && (
                <div className='col-md-12'>
                  <div className='flex-container no-compensation'>
                    <div>
                      <CheckboxToggle
                        classesLabel='form-control--label-auto'
                        id='generalInformation.multiproduct'
                        name='generalInformation.multiproduct'
                        register={register}
                        label={t('product.form.general-information.multiProduct')}
                        readOnly={isSubProduct}
                      />
                    </div>
                    <div className='u-mxm'>
                      <CheckboxToggle
                        classesLabel='form-control--label-auto'
                        id='generalInformation.subproduct'
                        name='generalInformation.subproduct'
                        register={register}
                        label={t('product.form.general-information.subproduct')}
                        readOnly={isMultiProduct}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className='col-md-6'>
                <div className='form-control'>
                  <label htmlFor='acronyme' className='form-control__label mandatory-field'>
                    {t('product.form.status')}
                  </label>
                  <div className='form-control__input '>
                    <select {...register('status')} className={!watchStatus ? 'field-in-error' : ''}>
                      <option value=''>{t('common.choice-option')}</option>
                      {referential.product.status.map((item, index) => (
                        <option key={index} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <Input
                register={register}
                classes={'col-md-3'}
                label={t('product.form.general-information.unicia')}
                type={'text'}
                name={'unicia'}
                id={'unicia'}
                readOnly={true}
              />
              <InputDate
                classes='col-md-3'
                id='createdAt'
                name='createdAt'
                label={t('product.form.general-information.created-at')}
                register={register}
                control={control}
                readOnly={true}
              />
              <div className='col-md-6'>
                <div className='form-control'>
                  <label htmlFor='acronyme' className='form-control__label'>
                    {t('product.form.general-information.acronyme')}
                  </label>
                  <div className='form-control__input'>
                    <input type='text' id='acronyme' {...register('generalInformation.acronyme')} />
                  </div>
                </div>
              </div>
              <InputDate
                classes='col-md-3'
                id='generalInformation.marketingDate'
                name='generalInformation.marketingDate'
                label={t('product.form.general-information.marketingDate')}
                register={register}
                control={control}
              />
              <InputDate
                classes='col-md-3'
                id='generalInformation.liquidationDate'
                name='generalInformation.liquidationDate'
                label={t('product.form.general-information.liquidation-at')}
                register={register}
                control={control}
              />
              <div className='col-md-6'>
                <div className='form-control'>
                  <label htmlFor='label' className='form-control__label'>
                    {t('product.form.general-information.label')}
                  </label>
                  <div className='form-control__input'>
                    <input type='text' id='label' {...register('generalInformation.label')} />
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='form-control'>
                  <label htmlFor='validatedAt' className='form-control__label'>
                    {t('product.form.general-information.validation-at')}
                  </label>
                  <div className='form-control__input'>
                    <InputDate
                      id='validatedAt'
                      name='generalInformation.validatedAt'
                      register={register}
                      control={control}
                    />
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='form-control'>
                  <label htmlFor='address' className='form-control__label'>
                    {t('product.form.general-information.address')}
                  </label>
                  <div className='form-control__input'>
                    <input type='text' id='address' {...register('generalInformation.address')} />
                  </div>
                </div>
              </div>

              <SelectCustom
                classes='col-md-6'
                id='generalInformation.capital'
                name='generalInformation.capital'
                label={t('product.form.general-information.capital')}
                options={referential.product.capital}
                register={register}
              />

              <CityAndPostcode
                control={control}
                nameCity='generalInformation.city'
                namePostcode='generalInformation.postcode'
                country={getValues('generalInformation.country')}
                register={register}
                setValue={setValue}
                getValues={getValues}
                mode='col'
                classes='col-md-6 flex u-px0'
                disabled={isLectureMode}
              />
              <div className='col-md-12'>
                <div className='form-control'>
                  <label htmlFor='country' className='form-control__label'>
                    {t('product.form.general-information.country')}
                  </label>
                  <div className='form-control__input'>
                    <CountryDatalist
                      control={control}
                      isOnError={!watchCountry}
                      name={'generalInformation.country'}
                      defaultValue={getValues('generalInformation.country')}
                      disabled={isLectureMode}
                    />
                  </div>
                </div>
              </div>
              <SelectCustom
                classes='col-md-6'
                id='categorization.eligibility'
                name='categorization.eligibility'
                label={t('product.form.categorization.eligibility')}
                options={referential.product.categorization_eligibility}
                register={register}
              />
              <div className='col-md-6'></div>
              <div className='col-md-6'>
                <div className='form-control'>
                  <label htmlFor='shareType' className='form-control__label'>
                    {t('product.form.general-information.share-type')}
                  </label>
                  <div className='form-control__input'>
                    <select {...register('generalInformation.shareType')}>
                      <option value=''>{t('common.choice-option')}</option>
                      {referential.product.share_type.map((item, index) => (
                        <option key={index} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='form-control'>
                  <label htmlFor='visaAmf' className='form-control__label'>
                    {t('product.form.general-information.visaAmf')}
                  </label>
                  <div className='form-control__input'>
                    <input type='text' id='visaAmf' {...register('generalInformation.visaAmf')} />
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='form-control'>
                  <label htmlFor='shareCount' className='form-control__label'>
                    {t('product.form.general-information.share-count')}
                  </label>
                  <div className='form-control__input'>
                    <input
                      type='number'
                      id='shareCount'
                      {...register('generalInformation.shareCount')}
                      readOnly={true}
                    />
                  </div>
                </div>
              </div>
              <InputDate
                classes='col-md-3'
                id='generalInformation.visaDate'
                name='generalInformation.visaDate'
                label={t('product.form.general-information.visaDate')}
                register={register}
                control={control}
              />
              <div className='col-md-3' />
              <div className='col-md-6'>
                <div className='form-control'>
                  <label htmlFor='lastNumber' className='form-control__label'>
                    {t('product.form.general-information.share-lastNumber')}
                  </label>
                  <div className='form-control__input'>
                    <input
                      type='number'
                      id='lastNumber'
                      {...register('generalInformation.shareLastNumber')}
                      readOnly={true}
                    />
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='form-control'>
                  <label htmlFor='isin' className='form-control__label mandatory-field'>
                    {t('product.form.general-information.isin')}
                  </label>
                  <div className='form-control__input'>
                    <input
                      type='text'
                      id='isin'
                      className={!watchIsin ? 'field-in-error' : ''}
                      {...register('generalInformation.isin')}
                    />
                  </div>
                </div>
              </div>
              <div className='col-md-6' />
              <div className='col-md-6'>
                <div className='form-control'>
                  <label htmlFor='kbis' className='form-control__label'>
                    {t('product.form.general-information.kbis')}
                  </label>
                  <div className='form-control__input'>
                    <input type='text' id='kbis' {...register('generalInformation.kbis')} />
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='form-control'>
                  <label className='form-control__label'>{t('product.form.general-information.msConfrontation')}</label>
                  <div className='form-control form-control--label-auto'>
                    <div className='form-control__input'>
                      <label className='form-label form-label--input-radio'>
                        <input
                          type='radio'
                          value='1'
                          {...register('msConfrontationRadio')}
                          onChange={e => {
                            if (product && product.generalInformation) {
                              product.generalInformation.msConfrontation = true
                            }
                          }}
                        />
                        {t('common.yes')}
                      </label>
                    </div>
                    <div className='form-control__input'>
                      <label className='form-label form-label--input-radio'>
                        <input
                          type='radio'
                          value='0'
                          {...register('msConfrontationRadio')}
                          onChange={e => {
                            if (product && product.generalInformation) {
                              product.generalInformation.msConfrontation = false
                            }
                          }}
                        />
                        {t('common.no')}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='form-control'>
                  <label htmlFor='giin' className='form-control__label'>
                    {t('product.form.general-information.giin')}
                  </label>
                  <div className='form-control__input'>
                    <input type='text' id='giin' {...register('generalInformation.giin')} />
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='form-control'>
                  <label className='form-control__label'>{t('product.form.general-information.recurrence')}</label>
                  <div className='form-control form-control--label-auto'>
                    <div className='form-control__input'>
                      <label className='form-label form-label--input-radio'>
                        <input
                          type='radio'
                          value='1'
                          {...register('recurrenceRadio')}
                          onChange={e => {
                            if (product && product.generalInformation) {
                              product.generalInformation.recurrence = true
                            }
                          }}
                        />
                        {t('common.yes')}
                      </label>
                    </div>
                    <div className='form-control__input'>
                      <label className='form-label form-label--input-radio'>
                        <input
                          type='radio'
                          value='0'
                          {...register('recurrenceRadio')}
                          onChange={e => {
                            if (product && product.generalInformation) {
                              product.generalInformation.recurrence = false
                            }
                          }}
                        />
                        {t('common.no')}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='form-control'>
                  <label htmlFor='rcs' className='form-control__label'>
                    {t('product.form.general-information.rcs')}
                  </label>
                  <div className='form-control__input'>
                    <input type='text' id='rcs' {...register('generalInformation.rcs')} />
                  </div>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='form-control'>
                  <label htmlFor='rcsPlace' className='form-control__label'>
                    {t('product.form.general-information.rcsPlace')}
                  </label>
                  <div className='form-control__input'>
                    <input type='text' id='rcsPlace' {...register('generalInformation.rcsPlace')} />
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='form-control'>
                  <label htmlFor='subscribable' className='form-control__label'>
                    {t('product.form.general-information.subscribable')}
                  </label>
                  <div className='form-control__input'>
                    <select {...register('generalInformation.subscribable')}>
                      <option value=''>{t('common.choice-option')}</option>
                      {referential.product.subscribable.map((item, index) => (
                        <option key={index} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='form-control'>
                  <label htmlFor='aif' className='form-control__label'>
                    {t('product.form.general-information.aif')}
                  </label>
                  <div className='form-control__input'>
                    <input type='text' id='aif' {...register('generalInformation.aif')} />
                  </div>
                </div>
              </div>
              <div className='col-md-3' />
              <div className='col-md-3'>
                <div className='form-control'>
                  <label htmlFor='logo' className='form-control__label'>
                    {t('product.form.general-information.logo')}
                  </label>
                  <div className='form-control__input'></div>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='form-control'>
                  <label htmlFor='lei' className='form-control__label'>
                    {t('product.form.general-information.lei')}
                  </label>
                  <div className='form-control__input'>
                    <input type='text' id='lei' {...register('generalInformation.lei')} />
                  </div>
                </div>
              </div>
              <SelectCustom
                id={`generalInformation.registryKeeper`}
                name={`generalInformation.registryKeeper`}
                classes='col-md-6'
                register={register}
                label={t('product.form.general-information.record-keeper')}
                noChoiceOption={true}
                options={referential?.product.registry_keeper || null}
                defaultOption={referential?.product.registry_keeper[0]}
              />
            </div>
          </div>
        </div>
        <div className='col-md-6'>
          <div className={`form-bloc form--bloc--valorisation`}>
            <div className='form-bloc__title'>{t('product.form.valorisation.title')}</div>
            <div className='form-bloc__form'>
              <div className='form-control form-control--label-auto'>
                <label htmlFor='customerPatrimony' className='form-control__label'>
                  {t('product.form.valorisation.customerPatrimony')}
                </label>
                <div className='form-control__input'>
                  <select {...register('valorisation.customerPatrimony')}>
                    <option value=''>{t('common.choice-option')}</option>
                    {referential.product.valorisation_customer_patrimony.map((item, index) => (
                      <option key={index} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className='col-md-6'>
                  <SelectCustom
                    label={t('product.form.general-information.decimalStep')}
                    noChoiceOption={true}
                    classes={'col-md-12'}
                    id={'stepdecimalStep'}
                    name={'decimalStep'}
                    options={DecimalStepOptions}
                    register={register}
                    readOnly={!useCheckRole('ROLE_SUPER_ADMIN')}
                  />
                </div>
              </div>
              <table className='table--valorisation'>
                <thead>
                  <tr>
                    <th />
                    <th className='form-control__label'>{t('product.form.valorisation.th-value')}</th>
                    <th className='form-control__label'>{t('product.form.valorisation.th-startDate')}</th>
                    <th className='form-control__label'>{t('product.form.valorisation.th-endDate')}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <label htmlFor='nominalValue' className='form-control__label'>
                        {t('product.form.valorisation.nominal')}
                      </label>
                    </td>
                    <td>
                      <div className='form-control__input'>
                        <InputNumber
                          id={'nominalValue'}
                          name={'valorisation.nominal.value'}
                          control={control}
                          decimalScale={watchStep}
                          register={register}
                        />
                      </div>
                    </td>
                    <td>
                      <div className='form-control__input'>
                        <InputDate
                          id=''
                          name='valorisation.nominal.period.start'
                          register={register}
                          control={control}
                        />
                      </div>
                    </td>
                    <td>
                      <div className='form-control__input'>
                        <InputDate id='' name='valorisation.nominal.period.end' register={register} control={control} />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label htmlFor='emissionValue' className='form-control__label'>
                        {t('product.form.valorisation.emission')}
                      </label>
                    </td>
                    <td>
                      <div className='form-control__input'>
                        <InputNumber
                          id={'emissionValue'}
                          name={'valorisation.emission.value'}
                          control={control}
                          decimalScale={watchStep}
                          register={register}
                        />
                      </div>
                    </td>
                    <td>
                      <div className='form-control__input'>
                        <InputDate
                          id=''
                          name='valorisation.emission.period.start'
                          register={register}
                          control={control}
                        />
                      </div>
                    </td>
                    <td>
                      <div className='form-control__input'>
                        <InputDate
                          id=''
                          name='valorisation.emission.period.end'
                          register={register}
                          control={control}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label htmlFor='admissionValue' className='form-control__label'>
                        {t('product.form.valorisation.admission')}
                      </label>
                    </td>
                    <td>
                      <div className='form-control__input'>
                        <InputNumber
                          id={'admissionValue'}
                          name={'valorisation.admission.value'}
                          control={control}
                          decimalScale={watchStep}
                          register={register}
                        />
                      </div>
                    </td>
                    <td>
                      <div className='form-control__input'>
                        <InputDate
                          id=''
                          name='valorisation.admission.period.start'
                          register={register}
                          control={control}
                        />
                      </div>
                    </td>
                    <td>
                      <div className='form-control__input'>
                        <InputDate
                          id=''
                          name='valorisation.admission.period.end'
                          register={register}
                          control={control}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label htmlFor='subscriptionValue' className='form-control__label'>
                        {t('product.form.valorisation.subscription')}
                      </label>
                    </td>
                    <td>
                      <div className='form-control__input'>
                        <InputNumber
                          id={'subscriptionValue'}
                          name={'valorisation.subscription.value'}
                          control={control}
                          decimalScale={watchStep}
                          register={register}
                        />
                      </div>
                    </td>
                    <td>
                      <div className='form-control__input'>
                        <InputDate
                          id=''
                          name='valorisation.subscription.period.start'
                          register={register}
                          control={control}
                        />
                      </div>
                    </td>
                    <td>
                      <div className='form-control__input'>
                        <InputDate
                          id=''
                          name='valorisation.subscription.period.end'
                          register={register}
                          control={control}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label htmlFor='withdrawValue' className='form-control__label'>
                        {t('product.form.valorisation.withdraw')}
                      </label>
                    </td>
                    <td>
                      <div className='form-control__input'>
                        <InputNumber
                          id={'withdrawValue'}
                          name={'valorisation.withdraw.value'}
                          control={control}
                          decimalScale={watchStep}
                          register={register}
                        />
                      </div>
                    </td>
                    <td>
                      <div className='form-control__input'>
                        <InputDate
                          id=''
                          name='valorisation.withdraw.period.start'
                          register={register}
                          control={control}
                        />
                      </div>
                    </td>
                    <td>
                      <div className='form-control__input'>
                        <InputDate
                          id=''
                          name='valorisation.withdraw.period.end'
                          register={register}
                          control={control}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label htmlFor='realisationValue' className='form-control__label'>
                        {t('product.form.valorisation.realisation')}
                      </label>
                    </td>
                    <td>
                      <div className='form-control__input'>
                        <InputNumber
                          id={'realisationValue'}
                          name={'valorisation.realisation.value'}
                          control={control}
                          decimalScale={watchStep}
                          register={register}
                        />
                      </div>
                    </td>
                    <td>
                      <div className='form-control__input'>
                        <InputDate
                          id=''
                          name='valorisation.realisation.period.start'
                          register={register}
                          control={control}
                        />
                      </div>
                    </td>
                    <td>
                      <div className='form-control__input'>
                        <InputDate
                          id=''
                          name='valorisation.realisation.period.end'
                          register={register}
                          control={control}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label htmlFor='reconstitutionValue' className='form-control__label'>
                        {t('product.form.valorisation.reconstitution')}
                      </label>
                    </td>
                    <td>
                      <div className='form-control__input'>
                        <InputNumber
                          id={'reconstitutionValue'}
                          name={'valorisation.reconstitution.value'}
                          control={control}
                          decimalScale={watchStep}
                          register={register}
                        />
                      </div>
                    </td>
                    <td>
                      <div className='form-control__input'>
                        <InputDate
                          id=''
                          name='valorisation.reconstitution.period.start'
                          register={register}
                          control={control}
                        />
                      </div>
                    </td>
                    <td>
                      <div className='form-control__input'>
                        <InputDate
                          id=''
                          name='valorisation.reconstitution.period.end'
                          register={register}
                          control={control}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label htmlFor='ifiValue' className='form-control__label'>
                        {t('product.form.valorisation.ifi')}
                      </label>
                    </td>
                    <td>
                      <div className='form-control__input'>
                        <InputNumber
                          id={'ifiValue'}
                          name={'valorisation.ifi.value'}
                          control={control}
                          decimalScale={watchStep}
                          register={register}
                        />
                      </div>
                    </td>
                    <td>
                      <div className='form-control__input'>
                        <InputDate id='' name='valorisation.ifi.period.start' register={register} control={control} />
                      </div>
                    </td>
                    <td>
                      <div className='form-control__input'>
                        <InputDate id='' name='valorisation.ifi.period.end' register={register} control={control} />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label htmlFor='ifiNonResidentValue' className='form-control__label'>
                        {t('product.form.valorisation.ifiNonResident')}
                      </label>
                    </td>
                    <td>
                      <div className='form-control__input'>
                        <InputNumber
                          id={'ifiNonResidentValue'}
                          name={'valorisation.ifiNonResident.value'}
                          control={control}
                          decimalScale={watchStep}
                          register={register}
                        />
                      </div>
                    </td>
                    <td>
                      <div className='form-control__input'>
                        <InputDate
                          id=''
                          name='valorisation.ifiNonResident.period.start'
                          register={register}
                          control={control}
                        />
                      </div>
                    </td>
                    <td>
                      <div className='form-control__input'>
                        <InputDate
                          id=''
                          name='valorisation.ifiNonResident.period.end'
                          register={register}
                          control={control}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label htmlFor='marketRateValue' className='form-control__label'>
                        {t('product.form.valorisation.marketRate')}
                      </label>
                    </td>
                    <td>
                      <div className='form-control__input'>
                        <InputNumber
                          id={'marketRateValue'}
                          name={'valorisation.marketRate.value'}
                          control={control}
                          decimalScale={watchStep}
                          register={register}
                        />
                      </div>
                    </td>
                    <td>
                      <div className='form-control__input'>
                        <InputDate
                          id=''
                          name='valorisation.marketRate.period.start'
                          register={register}
                          control={control}
                        />
                      </div>
                    </td>
                    <td>
                      <div className='form-control__input'>
                        <InputDate
                          id=''
                          name='valorisation.marketRate.period.end'
                          register={register}
                          control={control}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label htmlFor='quaterlyValue' className='form-control__label'>
                        {t('product.form.valorisation.quaterly')}
                      </label>
                    </td>
                    <td>
                      <div className='form-control__input'>
                        <InputNumber
                          id={'quaterlyValue'}
                          name={'valorisation.quaterly.value'}
                          control={control}
                          decimalScale={watchStep}
                          register={register}
                        />
                      </div>
                    </td>
                    <td>
                      <div className='form-control__input'>
                        <InputDate
                          id=''
                          name='valorisation.quaterly.period.start'
                          register={register}
                          control={control}
                        />
                      </div>
                    </td>
                    <td>
                      <div className='form-control__input'>
                        <InputDate
                          id=''
                          name='valorisation.quaterly.period.end'
                          register={register}
                          control={control}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label htmlFor='yearlyValue' className='form-control__label'>
                        {t('product.form.valorisation.yearly')}
                      </label>
                    </td>
                    <td>
                      <div className='form-control__input'>
                        <InputNumber
                          id={'yearlyValue'}
                          name={'valorisation.yearly.value'}
                          control={control}
                          decimalScale={watchStep}
                          register={register}
                        />
                      </div>
                    </td>
                    <td>
                      <div className='form-control__input'>
                        <InputDate
                          id=''
                          name='valorisation.yearly.period.start'
                          register={register}
                          control={control}
                        />
                      </div>
                    </td>
                    <td>
                      <div className='form-control__input'>
                        <InputDate id='' name='valorisation.yearly.period.end' register={register} control={control} />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label htmlFor='yieldFieldValue' className='form-control__label'>
                        {t('product.form.valorisation.yieldField')}
                      </label>
                    </td>
                    <td>
                      <div className='form-control__input'>
                        <InputNumber
                          id={'yieldFieldValue'}
                          name={'valorisation.yield.value'}
                          control={control}
                          decimalScale={watchStep}
                          register={register}
                        />
                      </div>
                    </td>
                    <td>
                      <div className='form-control__input'>
                        <InputDate id='' name='valorisation.yield.period.start' register={register} control={control} />
                      </div>
                    </td>
                    <td>
                      <div className='form-control__input'>
                        <InputDate id='' name='valorisation.yield.period.end' register={register} control={control} />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label htmlFor='yieldFieldValue' className='form-control__label'>
                        {t('product.form.valorisation.source')}
                      </label>
                    </td>
                    <td>
                      <div className='form-control__input'>
                        <InputNumber
                          id={'yieldFieldValue'}
                          name={'valorisation.source.value'}
                          control={control}
                          decimalScale={watchStep}
                          register={register}
                        />
                      </div>
                    </td>
                    <td>
                      <div className='form-control__input'>
                        <InputDate
                          id=''
                          name='valorisation.source.period.start'
                          register={register}
                          control={control}
                        />
                      </div>
                    </td>
                    <td>
                      <div className='form-control__input'>
                        <InputDate id='' name='valorisation.source.period.end' register={register} control={control} />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className='col-md-6'>
          <div className={`form-bloc form--bloc--usage`}>
            <div className='form-bloc__title'>{t('product.form.usage.title')}</div>
            <div className='form-bloc__form flex-container'>
              <CheckboxToggle
                classes='col-md-12'
                id='usage.newPart.delay'
                name='usage.newPart.delay'
                label={t('product.form.usage.newPartDelay')}
                register={register}
              />
              {watchUsageNewPartDelay && (
                <div className='col-md-12'>
                  <div className='form-control'>
                    <label htmlFor='newPartDuration' className='form-control__label'>
                      {t('product.form.usage.newPartDuration')}
                    </label>
                    <div className='form-control__input'>
                      <select {...register('usage.newPart.duration')}>
                        <option value=''>{t('common.choice-option')}</option>
                        {referential.product.temporality.map((item, index) => (
                          <option key={index} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              )}
              <CheckboxToggle
                classes='col-md-12'
                id='usage.transfer.delay'
                name='usage.transfer.delay'
                label={t('product.form.usage.transferDelay')}
                register={register}
              />
              {watchUsageTransferDelay && (
                <div className='col-md-12'>
                  <div className='form-control'>
                    <label htmlFor='transferDuration' className='form-control__label'>
                      {t('product.form.usage.transferDuration')}
                    </label>
                    <div className='form-control__input'>
                      <select {...register('usage.transfer.duration')}>
                        <option value=''>{t('common.choice-option')}</option>
                        {referential.product.temporality.map((item, index) => (
                          <option key={index} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              )}
              <CheckboxToggle
                classes='col-md-12'
                id='usage.mutation.delay'
                name='usage.mutation.delay'
                label={t('product.form.usage.mutationDelay')}
                register={register}
              />
              {watchUsageMutationDelay && (
                <div className='col-md-12'>
                  <div className='form-control'>
                    <label htmlFor='mutationDuration' className='form-control__label'>
                      {t('product.form.usage.mutationDuration')}
                    </label>
                    <div className='form-control__input'>
                      <select {...register('usage.mutation.duration')}>
                        <option value=''>{t('common.choice-option')}</option>
                        {referential.product.temporality.map((item, index) => (
                          <option key={index} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={`form-bloc form--bloc--withdrawal`}>
            <div className='form-bloc__title'>{t('product.form.withdrawal.title')}</div>
            <div className='form-bloc__form flex-container'>
              <div className='col-md-12'>
                <div className='form-control'>
                  <label htmlFor='withdrawalAllowedAt' className='form-control__label'>
                    {t('product.form.withdrawal.withdrawalAllowedAt')}
                  </label>
                  <div className='form-control__input'>
                    <InputDate
                      id='withdrawalAllowedAt'
                      name='withdrawal.allowedAt'
                      register={register}
                      control={control}
                    />
                  </div>
                </div>
              </div>
              <div className='col-md-12'>
                <div className='form-control'>
                  <label htmlFor='withdrawalCondition' className='form-control__label'>
                    {t('product.form.withdrawal.withdrawalCondition')}
                  </label>
                  <div className='form-control__input'>
                    <textarea id='withdrawalCondition' {...register('withdrawal.condition')} />
                  </div>
                </div>
              </div>
              <div className='col-md-12'>
                <div className='form-control'>
                  <label htmlFor='withdrawalCost' className='form-control__label'>
                    {t('product.form.withdrawal.withdrawalCost')}
                  </label>
                  <div className='form-control__input'>
                    <input type='text' id='withdrawalCost' {...register('withdrawal.cost')} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`form-bloc form--bloc--accounting`}>
            <div className='form-bloc__title'>{t('product.form.accounting.title')}</div>
            <div className='form-bloc__form flex-container'>
              <div className='col-md-6'>
                <div className='form-control'>
                  <label htmlFor='cost' className='form-control__label'>
                    {t('product.form.accounting.cost')}
                  </label>
                  <div className='form-control__input'>
                    <input
                      type='number'
                      step={1 / Math.pow(10, Number(envVariable('REACT_APP_COMMA_NUMBER')))}
                      id='cost'
                      {...register('accounting.cost')}
                    />
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='form-control'>
                  <label htmlFor='periodicity' className='form-control__label'>
                    {t('product.form.accounting.periodicity')}
                  </label>
                  <div className='form-control__input'>
                    <select {...register('accounting.periodicity')}>
                      <option value=''>{t('common.choice-option')}</option>
                      {referential.product.periodicity.map((item, index) => (
                        <option key={index} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className='col-md-12'>
                <div className='form-control'>
                  <label htmlFor='number' className='form-control__label'>
                    {t('product.form.accounting.number')}
                  </label>
                  <div className='form-control__input'>
                    <input type='text' id='number' {...register('accounting.number')} />
                  </div>
                </div>
              </div>
              <CheckboxToggle
                classes='col-md-12'
                id='accounting.upFront.tva'
                name='accounting.upFront.tva'
                label={t('product.form.accounting.upfrontTva')}
                register={register}
              />
              <CheckboxToggle
                classes='col-md-12'
                id='accounting.running.tva'
                name='accounting.running.tva'
                label={t('product.form.accounting.runningTva')}
                register={register}
              />
            </div>
          </div>
        </div>
        {/* <div className="col-md-12">
          <div className={`form-bloc form--bloc--categorization`}>
            <div className="form-bloc__title">{t('product.form.categorization.title')}</div>
            <div className="form-bloc__form flex-container">
              <div className="col-md-6">
                <div className="form-control">
                  <label htmlFor="categorizationTarget"
                         className="form-control__label">{t('product.form.categorization.target')}</label>
                  <div className="form-control__input">
                    <select {...register('categorization.target')}>
                      <option value="">{t('common.choice-option')}</option>
                      {referential.product.categorization_target.map((item, index) =>
                        <option key={index} value={item.value}>{item.label}</option>)}
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-md-6"/>
              <div className="col-md-3">
                <div className="form-control">
                  <label htmlFor="categorizationEmtEnabled"
                         className="form-control__label">{t('product.form.categorization.emtEnabled')}</label>
                  <div className="form-control form-control--label-auto">
                    <div className="form-control__input">
                      <label className="form-label form-label--input-radio">
                        <input
                          type="radio"
                          value="1"
                          {...register('categorizationEmtEnabledRadio')}
                          onChange={(e) => {
                            if (product && product.categorization) {
                              product.categorization.emt.enabled = true
                            }
                          }}
                        />
                        {t('common.yes')}
                      </label>
                    </div>
                    <div className="form-control__input">
                      <label className="form-label form-label--input-radio">
                        <input
                          type="radio"
                          value="0"
                          {...register('categorizationEmtEnabledRadio')}
                          onChange={(e) => {
                            if (product && product.categorization) {
                              product.categorization.emt.enabled = false
                            }
                          }}
                        />
                        {t('common.no')}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-control">
                  <label htmlFor="categorizationEmtDate"
                         className="form-control__label">{t('product.form.categorization.emtDate')}</label>
                  <div className="form-control__input">
                    <InputDate id="categorizationEmtDate"
                               name="categorization.emt.date"
                               register={register}
                               control={control}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-control">
                  <label htmlFor="categorizationEmtTypologyZeroSeven"
                         className="form-control__label">{t('product.form.categorization.emtTypologyZeroSeven')}</label>
                  <div className="form-control__input">
                    <input type="text"
                           id="categorizationEmtTypologyZeroSeven" {...register('categorization.emt.typologyZeroSeven')} />
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-control">
                  <label htmlFor="categorizationEmtTypologyZeroHeight"
                         className="form-control__label">{t('product.form.categorization.emtTypologyZeroHeight')}</label>
                  <div className="form-control__input">
                    <input type="text"
                           id="categorizationEmtTypologyZeroHeight" {...register('categorization.emt.typologyZeroHeight')} />
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-control">
                  <label htmlFor="categorizationEptEnabled"
                         className="form-control__label">{t('product.form.categorization.eptEnabled')}</label>
                  <div className="form-control form-control--label-auto">
                    <div className="form-control__input">
                      <label className="form-label form-label--input-radio">
                        <input
                          type="radio"
                          value="1"
                          {...register('categorizationEptEnabledRadio')}
                          onChange={(e) => {
                            if (product && product.categorization) {
                              product.categorization.ept.enabled = true
                            }
                          }}
                        />
                        {t('common.yes')}
                      </label>
                    </div>
                    <div className="form-control__input">
                      <label className="form-label form-label--input-radio">
                        <input
                          type="radio"
                          value="0"
                          {...register('categorizationEptEnabledRadio')}
                          onChange={(e) => {
                            if (product && product.categorization) {
                              product.categorization.ept.enabled = false
                            }
                          }}
                        />
                        {t('common.no')}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-control">
                  <label htmlFor="categorizationEmtDate"
                         className="form-control__label">{t('product.form.categorization.eptDate')}</label>
                  <div className="form-control__input">
                    <InputDate id="categorizationEmtDate"
                               name="categorization.ept.date"
                               register={register}
                               control={control}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-control">
                  <label htmlFor="categorizationEmtTypologyZeroSeven"
                         className="form-control__label">{t('product.form.categorization.eptTypologyZeroSeven')}</label>
                  <div className="form-control__input">
                    <input type="text"
                           id="categorizationEmtTypologyZeroSeven" {...register('categorization.ept.typologyZeroSeven')} />
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-control">
                  <label htmlFor="categorizationEmtTypologyZeroHeight"
                         className="form-control__label">{t('product.form.categorization.eptTypologyZeroHeight')}</label>
                  <div className="form-control__input">
                    <input type="text"
                           id="categorizationEmtTypologyZeroHeight" {...register('categorization.ept.typologyZeroHeight')} />
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-control">
                  <label htmlFor="categorizationTptEnabled"
                         className="form-control__label">{t('product.form.categorization.tptEnabled')}</label>
                  <div className="form-control form-control--label-auto">
                    <div className="form-control__input">
                      <label className="form-label form-label--input-radio">
                        <input
                          type="radio"
                          value="1"
                          {...register('categorizationTptEnabledRadio')}
                          onChange={(e) => {
                            if (product && product.categorization) {
                              product.categorization.tpt.enabled = true
                            }
                          }}
                        />
                        {t('common.yes')}
                      </label>
                    </div>
                    <div className="form-control__input">
                      <label className="form-label form-label--input-radio">
                        <input
                          type="radio"
                          value="0"
                          {...register('categorizationTptEnabledRadio')}
                          onChange={(e) => {
                            if (product && product.categorization) {
                              product.categorization.tpt.enabled = false
                            }
                          }}
                        />
                        {t('common.no')}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-control">
                  <label htmlFor="categorizationEmtDate"
                         className="form-control__label">{t('product.form.categorization.tptDate')}</label>
                  <div className="form-control__input">
                    <InputDate id="categorizationEmtDate"
                               name="categorization.tpt.date"
                               register={register}
                               control={control}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-control">
                  <label htmlFor="categorizationEmtTypologyZeroSeven"
                         className="form-control__label">{t('product.form.categorization.tptTypologyZeroSeven')}</label>
                  <div className="form-control__input">
                    <input type="text"
                           id="categorizationEmtTypologyZeroSeven" {...register('categorization.tpt.typologyZeroSeven')} />
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-control">
                  <label htmlFor="categorizationEmtTypologyZeroHeight"
                         className="form-control__label">{t('product.form.categorization.tptTypologyZeroHeight')}</label>
                  <div className="form-control__input">
                    <input type="text"
                           id="categorizationEmtTypologyZeroHeight" {...register('categorization.tpt.typologyZeroHeight')} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {product && (
          <div className='col-md-12'>
            <div className={`form-bloc form--bloc--documentation`}>
              <div className='form-bloc__title'>{t('product.form.documentation.title')}</div>
              <div className='form-bloc__form'>
                <table className='table--documentation'>
                  <thead>
                    <tr>
                      <th />
                      <th className='form-control__label'>{t('product.form.documentation.th-last-date-update')}</th>
                      <th className='form-control__label'>{t('common.multiple-action')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <label className='form-control__label'>{t('product.form.documentation.status')}</label>
                      </td>
                      <td>
                        <div className='form-control__input'>
                          <InputDate id='' name='documentation.status.date' register={register} control={control} />
                        </div>
                      </td>
                      <td>
                        <button
                          type='button'
                          className='button-reset'
                          name='read.document'
                          disabled={documentStatusArray !== null && documentStatusArray.length === 0}
                          onClick={() => viewFile(documentStatusArray)}
                        >
                          <img src={eyeIcon} alt='' />
                        </button>
                        {!isLectureMode && (
                          <button
                            type='button'
                            className='button-reset'
                            onClick={() =>
                              dispatch(
                                setOpenProductUploadForm({
                                  show: true,
                                  documents: null,
                                  productId: product?.id,
                                  type: 'STATUS',
                                })
                              )
                            }
                          >
                            <img src={uploadIcon} alt='' />
                          </button>
                        )}
                        {/*<button type="button" className="button-reset">*/}
                        {/*  <img src={emailIcon} alt=""/>*/}
                        {/*</button>*/}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label className='form-control__label'>{t('product.form.documentation.dic')}</label>
                      </td>
                      <td>
                        <div className='form-control__input'>
                          <InputDate id='' name='documentation.dic.date' register={register} control={control} />
                        </div>
                      </td>
                      <td>
                        <button
                          type='button'
                          className='button-reset'
                          name='read.document'
                          disabled={documentDicArray !== null && documentDicArray.length === 0}
                          onClick={() => viewFile(documentDicArray)}
                        >
                          <img src={eyeIcon} alt='' />
                        </button>
                        {!isLectureMode && (
                          <button
                            type='button'
                            className='button-reset'
                            onClick={() =>
                              dispatch(
                                setOpenProductUploadForm({
                                  show: true,
                                  documents: null,
                                  productId: product?.id,
                                  type: 'DIC',
                                })
                              )
                            }
                          >
                            <img src={uploadIcon} alt='' />
                          </button>
                        )}
                        {/*<button type="button" className="button-reset">*/}
                        {/*  <img src={emailIcon} alt=""/>*/}
                        {/*</button>*/}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label className='form-control__label'>{t('product.form.documentation.information')}</label>
                      </td>
                      <td>
                        <div className='form-control__input'>
                          <InputDate
                            id=''
                            name='documentation.information.date'
                            register={register}
                            control={control}
                          />
                        </div>
                      </td>
                      <td>
                        <button
                          type='button'
                          className='button-reset'
                          name='read.document'
                          disabled={documentInformationArray !== null && documentInformationArray.length === 0}
                          onClick={() => viewFile(documentInformationArray)}
                        >
                          <img src={eyeIcon} alt='' />
                        </button>
                        {!isLectureMode && (
                          <button
                            type='button'
                            className='button-reset'
                            onClick={() =>
                              dispatch(
                                setOpenProductUploadForm({
                                  show: true,
                                  documents: null,
                                  productId: product?.id,
                                  type: 'INFORMATION',
                                })
                              )
                            }
                          >
                            <img src={uploadIcon} alt='' />
                          </button>
                        )}
                        {/*<button type="button" className="button-reset">*/}
                        {/*  <img src={emailIcon} alt=""/>*/}
                        {/*</button>*/}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label className='form-control__label'>{t('product.form.documentation.subscription')}</label>
                      </td>
                      <td>
                        <div className='form-control__input'>
                          <InputDate
                            id=''
                            name='documentation.subscription.date'
                            register={register}
                            control={control}
                          />
                        </div>
                      </td>
                      <td>
                        <button
                          type='button'
                          className='button-reset'
                          name='read.document'
                          disabled={documentSubscriptionArray !== null && documentSubscriptionArray.length === 0}
                          onClick={() => viewFile(documentSubscriptionArray)}
                        >
                          <img src={eyeIcon} alt='' />
                        </button>
                        {!isLectureMode && (
                          <button
                            type='button'
                            className='button-reset'
                            onClick={() =>
                              dispatch(
                                setOpenProductUploadForm({
                                  show: true,
                                  documents: null,
                                  productId: product?.id,
                                  type: 'SUBSCRIPTION',
                                })
                              )
                            }
                          >
                            <img src={uploadIcon} alt='' />
                          </button>
                        )}
                        {/*<button type="button" className="button-reset">*/}
                        {/*  <img src={emailIcon} alt=""/>*/}
                        {/*</button>*/}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label className='form-control__label'>{t('product.form.documentation.annualReport')}</label>
                      </td>
                      <td>
                        <div className='form-control__input'>
                          <InputDate
                            id=''
                            name='documentation.annualReport.date'
                            register={register}
                            control={control}
                          />
                        </div>
                      </td>
                      <td>
                        <button
                          type='button'
                          className='button-reset'
                          name='read.document'
                          disabled={documentAnnualReportArray !== null && documentAnnualReportArray.length === 0}
                          onClick={() => viewFile(documentAnnualReportArray)}
                        >
                          <img src={eyeIcon} alt='' />
                        </button>
                        {!isLectureMode && (
                          <button
                            type='button'
                            className='button-reset'
                            onClick={() =>
                              dispatch(
                                setOpenProductUploadForm({
                                  show: true,
                                  documents: null,
                                  productId: product?.id,
                                  type: 'ANNUALREPORT',
                                })
                              )
                            }
                          >
                            <img src={uploadIcon} alt='' />
                          </button>
                        )}
                        {/*<button type="button" className="button-reset">*/}
                        {/*  <img src={emailIcon} alt=""/>*/}
                        {/*</button>*/}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
      {!isLectureMode && <FooterBlock disabled={!watchStatus || isLoading} onCancelUrl={t('url.product.dashboard')} />}
    </form>
  )
}

export default GeneralInformationBlock
