import { zodResolver } from '@hookform/resolvers/zod'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import DismembermentTableGateway from '../../../../../../gateway/DismembermentTable/DismembermentTableGateway'
import useDismembermentTableList from '../../../../customHook/useDismembermentTableList'
import { toastError, toastSuccess } from '../../../../util/Toast'
import InputDate from '../../../Elements/InputDate'
import ConfirmationModal from '../../../Modal/ConfirmationModal'
import Spinner from '../../../Spinner/Spinner'
import DismembermentTableItem from '../../../Table/DismembermentTable/DismembermentTableItem'
import FormFooter from '../../Footer/FormFooter'
import { FormValues, schema } from './schema'

type Props = {
  productId: string
}

const DismembermentTableForm = ({ productId }: Props) => {
  const { t } = useTranslation()
  const { isLoading, dismembermentTables } = useDismembermentTableList()
  const [selectedTableId, setSelectedTableId] = useState<string | null>(null)

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
    defaultValues: {
      startDate: dayjs().format('DD/MM/YYYY'),
      endDate: null,
    },
  })

  const handleSelect = (id: string) => {
    setSelectedTableId(id)
  }

  const onSubmit = (data: FormValues) => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmationModal onConfirm={() => onConfirm(data.startDate, data.endDate)} onClose={onClose} />
      ),
    })
  }

  const onConfirm = (startDate: string, endDate: string | null | undefined) => {
    if (selectedTableId) {
      new DismembermentTableGateway()
        .link(productId, selectedTableId, startDate, endDate)
        .then(() => {
          toastSuccess(t('dismemberment-table.notify.success-link'))
        })
        .catch(e => {
          if (e?.code === 400 && typeof e?.messageApi === 'string') {
            toastError(e.messageApi)
            return
          }
          toastError(t('dismemberment-table.notify.error-link'))
        })
    }
  }

  if (isLoading) {
    return (
      <section className='container--spinner'>
        <Spinner size={150} />
      </section>
    )
  }

  if (!dismembermentTables || dismembermentTables.length === 0) {
    return <p>{t('dismemberment-table.noData')}</p>
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className='flex-container u-mxm'>
        <div className='col-md-6'>
          <InputDate
            register={register}
            control={control}
            id='startDate'
            name='startDate'
            label={t('dismemberment-table.form.validity-date-start')}
            dynamicError={errors.startDate?.message}
          />
        </div>

        <div className='col-md-6'>
          <InputDate
            register={register}
            control={control}
            id='endDate'
            name='endDate'
            label={t('dismemberment-table.form.validity-date-end')}
            dynamicError={errors.endDate?.message}
          />
        </div>

        {dismembermentTables.map(dismembermentTable => (
          <div
            key={dismembermentTable.dismembermentId}
            className={`dismemberment-table__item flex-container items-center ${
              selectedTableId === dismembermentTable.dismembermentId ? 'dismemberment-table__item--selected' : ''
            }`}
          >
            <input
              type='radio'
              id={`radio-${dismembermentTable.dismembermentId}`}
              name='dismemberment-table'
              value={dismembermentTable.dismembermentId}
              checked={selectedTableId === dismembermentTable.dismembermentId}
              onChange={() => handleSelect(dismembermentTable.dismembermentId)}
              className='u-mrm'
            />
            <label htmlFor={`radio-${dismembermentTable.dismembermentId}`} className='radio-label u-pointer flex-1'>
              <DismembermentTableItem table={dismembermentTable} />
            </label>
          </div>
        ))}
      </form>
      <FormFooter
        isLectureMode={false}
        onSubmit={handleSubmit(onSubmit)}
        cancelRedirectUrl={`/${t('url.product.dashboard')}`}
      />
    </>
  )
}

export default DismembermentTableForm
