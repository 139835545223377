import fr from 'date-fns/locale/fr'
import React, { FunctionComponent, InputHTMLAttributes } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Control, Controller, FieldErrors, useWatch } from 'react-hook-form'
import InputMask from 'react-input-mask'
import { dateToString, stringToDate } from '../../../transformer/StringDateToDateObject/StringDateToDateObject'
import { TargetPropertyInObjWithStringPath } from '../../util/TargetPropertyInObjWithStringPath'
import FieldErrorMessage from '../FieldErrorMessage/FieldErrorMessage'
import { IFormFieldProps } from './IFormFieldProps'
import './InputDate.scss'

registerLocale('fr', fr)

interface IProps extends InputHTMLAttributes<HTMLInputElement>, IFormFieldProps {
  id: string
  name: string
  classes?: string
  containerClasses?: string
  labelClasses?: string
  defaultValue?: string
  isLectureMode?: boolean
  disabled?: boolean
  readonly?: boolean
  required?: boolean
  control: Control<any, any>
  errorMessage?: string
  errors?: FieldErrors
  dynamicError?: string
  onChangeCb?: (value: string) => void
  showTimeSelect?: boolean
}

const InputDate: FunctionComponent<IProps> = ({
  id,
  name,
  classes,
  containerClasses,
  labelClasses,
  label,
  defaultValue,
  isLectureMode,
  disabled,
  readOnly,
  required,
  control,
  errorMessage,
  onChangeCb,
  dynamicError,
  showTimeSelect,
  ...rest
}) => {
  const val = useWatch({
    defaultValue: defaultValue,
    control: control,
    name: name,
  })

  const formInput = (
    <Controller
      name={name}
      control={control}
      rules={{ required: required }}
      render={({ field }) => {
        return (
          <DatePicker
            selected={val ? stringToDate(val, showTimeSelect ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY') : null}
            onChange={(date: Date | null) => {
              const formattedDate = dateToString(date, showTimeSelect)
              field.onChange(formattedDate)
              onChangeCb && onChangeCb(formattedDate)
            }}
            name={name}
            className={isLectureMode ? 'form-control__readonly' : ''}
            disabled={disabled}
            readOnly={readOnly}
            showPopperArrow={false}
            isClearable={!disabled && !readOnly}
            placeholderText={showTimeSelect ? '__/__/____ __:__' : '__/__/____'}
            locale='fr'
            dateFormat={showTimeSelect ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy'}
            minDate={new Date(1900, 0, 1)}
            maxDate={new Date(3000, 12, 31)}
            showTimeSelect={showTimeSelect}
            timeFormat='HH:mm'
            timeIntervals={15}
            timeCaption='Heure'
            customInput={
              <InputMask
                name={name}
                id={id}
                value={
                  val
                    ? dateToString(
                        stringToDate(val, showTimeSelect ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY'),
                        showTimeSelect
                      )
                    : ''
                }
                mask={showTimeSelect ? '99/99/9999 99:99' : '99/99/9999'}
                maskPlaceholder={showTimeSelect ? '__/__/____ __:__' : '__/__/____'}
                alwaysShowMask={true}
                required={required}
              />
            }
          />
        )
      }}
    />
  )

  return (
    <div className={`${classes}`}>
      {label ? (
        <div className='form-control relative'>
          <label
            htmlFor={id}
            className={
              (labelClasses ? labelClasses + ' form-control__label' : 'form-control__label') +
              (required ? ' mandatory-field ' : ' ')
            }
          >
            {label}
          </label>
          <div className={`form-control__input ${containerClasses || ''}`}>
            {formInput}
            {rest?.errors && TargetPropertyInObjWithStringPath(name, rest?.errors) && (
              <p className='input-error-msg'>{errorMessage}</p>
            )}
            {!rest?.errors && dynamicError && <FieldErrorMessage message={dynamicError} />}
          </div>
        </div>
      ) : (
        <div className={`form-control__input ${containerClasses || ''}`}>
          {formInput}
          {rest?.errors && TargetPropertyInObjWithStringPath(name, rest?.errors) && (
            <p className='input-error-msg'>{errorMessage}</p>
          )}
          {!rest?.errors && dynamicError && <p className='input-error-msg'>{dynamicError}</p>}
        </div>
      )}
    </div>
  )
}

export default InputDate
