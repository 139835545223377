import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import '../../../../assets/styles/page/_product.scss'
import { SessionInterface } from '../../../../domain/Distribution'
import { TError, ValidationErrorInterface } from '../../../../domain/Error/Error'
import { ReferentielInterface } from '../../../../domain/Referentiel/ReferentielInterface'
import MatchUrl from '../../../../domain/Utils/MatchedUrl'
import SessionGateway from '../../../../gateway/Distribution/SessionGateway'
import SessionPresenter from '../../../../presenter/Distribution/SessionPresenter'
import { setSessionActions } from '../../../store/component/distribution'
import { useAppDispatch, useAppSelector } from '../../../store/hook'
import InvestorDatalist from '../../component/Datalist/Distribution/InvestorDatalist'
import Form from '../../component/Form/Distribution/Form'
import HeaderPage from '../../component/HeaderPage/HeaderPage'
import Spinner from '../../component/Spinner/Spinner'
import TabDistribution from '../../component/Tab/Distribution/TabDistribution'
import ErrorPage from '../Error/ErrorPage'

interface ILayoutProps {
  page: string
  mode: 'add' | 'edit' | 'read'
}

const Layout: React.FC<ILayoutProps> = ({ page, mode }) => {
  const { t } = useTranslation()
  const { sessionType, uuid } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { refreshSession } = useAppSelector(state => state.distribution)
  const isLectureMode = MatchUrl.find(location.pathname, 'lecture')
  const rules = useAppSelector(state => state.me.me?.rules.distribution.actions)
  const referential: ReferentielInterface | null = useAppSelector(({ referential }) => referential.referential)
  const [session, setSession] = useState<SessionInterface | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<TError | null>(null)
  const openMainNavigation = useAppSelector(state => state.event.openMainNavigation)
  const [formErrors, setFormErrors] = useState<ValidationErrorInterface | null>(null)

  useEffect(() => {
    if (rules) {
      if (!rules?.update && !isLectureMode) {
        navigate(`/${t('url.distribution.read-setting')}/${sessionType}/${uuid}`)
      }
    }
  }, [rules])

  useEffect(() => {
    setIsLoading(true)
    if (uuid) {
      fetchSession(uuid)
      new SessionGateway().getActions(uuid).then(actions => dispatch(setSessionActions(actions)))
    } else {
      setIsLoading(false)
    }
  }, [uuid, refreshSession])

  const fetchSession = (uuid: string) => {
    new SessionGateway()
      .get(uuid)
      .then(data => {
        const presenter = new SessionPresenter(data)
        setSession(presenter.load())
        setIsLoading(false)
      })
      .catch(e => {
        setError(e)
        setIsLoading(false)
      })
  }

  const handleChoosePage = () => {
    if (page === 'investor-list' && session && session.id) {
      return <InvestorDatalist sessionId={session.id} formErrors={formErrors} isLectureMode={isLectureMode} />
    }

    return (
      <Form isLectureMode={isLectureMode} session={session} formErrors={formErrors} setFormErrors={setFormErrors} />
    )
  }

  if (error) {
    return <ErrorPage code={error.code.toString()} />
  }

  return (
    <>
      <main className={`main-content ${openMainNavigation ? 'main-content--reduce' : ''}`}>
        <HeaderPage
          title={`${t(`distribution.session.${uuid ? 'edit' : 'add'}.title`)} ${uuid && session?.id ? session?.label : ''}`}
        />
        <TabDistribution mode={mode} uuid={uuid} />
        {!isLoading && referential ? (
          handleChoosePage()
        ) : (
          <section className='container--spinner'>
            <Spinner size={150} />
          </section>
        )}
      </main>
    </>
  )
}

export default Layout
