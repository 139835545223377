import dayjs from 'dayjs'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { v4 as uuidV4 } from 'uuid'
import { ActionItemInterface, ActionList, ActionListInterface } from '../../../../../../domain/Distribution'
import { ValidationErrorInterface } from '../../../../../../domain/Error/Error'
import SessionGateway from '../../../../../../gateway/Distribution/SessionGateway'
import { setRefreshSession, setSessionActions } from '../../../../../store/component/distribution'
import { useAppDispatch, useAppSelector } from '../../../../../store/hook'
import { handleCloseToast, toastError, toastProcess, toastSuccess } from '../../../../util/Toast'
import InputDate from '../../../Elements/InputDate'

interface Props {
  sessionId: string
  uuidRefresh: string
  setFormErrors: React.Dispatch<React.SetStateAction<ValidationErrorInterface | null>>
}

export const ACTIONS = {
  CALCUL: 'calcul',
  CONTROL: 'control',
  VALIDATE: 'validate',
  PAY: 'pay',
  SEND: 'send',
  CANCEL: 'cancel',
}

const Actions: FunctionComponent<Props> = ({ sessionId, uuidRefresh, setFormErrors }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const sessionActions = useAppSelector(state => state.distribution.actions)
  const [actionArray, setActionArray] = useState<ActionListInterface>()
  const [uuidRefreshAction, setUuidRefreshAction] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [firstRender, setFirstRender] = useState<boolean>(true)
  const { register, control, setValue, getValues } = useFormContext()

  useEffect(() => {
    if (!firstRender) {
      new SessionGateway().getActions(sessionId).then(actions => dispatch(setSessionActions(actions)))
    }

    setFirstRender(false)
  }, [uuidRefresh, uuidRefreshAction])

  useEffect(() => {
    if (sessionActions) {
      let data = {} as ActionListInterface

      Object.keys(sessionActions).map(key => {
        data = {
          ...data,
          [key as keyof ActionListInterface]: { ...sessionActions[key as keyof ActionListInterface] },
        }
      })

      setActionArray(data)
    }
  }, [sessionActions])

  useEffect(() => {
    if (actionArray) {
      Object.entries(actionArray).map((action: [string, ActionItemInterface]) => {
        setValue(action[0] as keyof ActionListInterface, action[1])
      })
    }
  }, [actionArray])

  const setAction = (action: string) => {
    if (
      [ACTIONS.CALCUL, ACTIONS.CONTROL, ACTIONS.VALIDATE, ACTIONS.PAY, ACTIONS.SEND, ACTIONS.CANCEL].includes(action)
    ) {
      setIsLoading(true)
      const toastId = toastProcess(t('distribution.notify.calculate-process'))
      let date = getValues()[action as keyof ActionListInterface].date
      const reason = getValues()[action as keyof ActionListInterface].reason

      if (!date) {
        date = dayjs(new Date()).format('DD/MM/YYYY')
      }

      new SessionGateway()
        .setAction(sessionId, action, date, reason)
        .then((response: ActionList | any) => {
          if (response) {
            setFormErrors({})
            dispatch(setSessionActions(response))
            dispatch(setRefreshSession(uuidV4()))
            toastSuccess(t('distribution.session.notify.update-success'))
            return
          }
          toastError(t('distribution.session.notify.update-error'))
        })
        .catch(e => {
          if (400 === e?.code && 'string' === typeof e?.messageApi) {
            toastError(e.messageApi)
          } else {
            if (e?.data?.errors && 'data-validation' === e?.data?.type) {
              setFormErrors(e?.data)
              if (e?.data?.title) {
                toastError(e?.data?.title)
              }
            }
            if (!e?.data?.title) {
              toastError(t('distribution.session.notify.update-error'))
            }
          }
        })
        .finally(() => {
          handleCloseToast(toastId)
          setIsLoading(false)
        })
    }
  }

  const disabledAction = ({ action }: { action: [string, any] }): boolean => {
    return isLoading || !action[1].enabled
  }
  const handleClickAction = (action: [string, any]) => {
    setAction(action[0])
  }

  const getActionTitle = (action: [string, any]) => {
    return action[0]
  }

  return (
    <div className='col-md-6'>
      <div className='form-bloc form-bloc--action'>
        <div className='form-bloc__title'>{t('distribution.form.setting.actions-editions.title-action')}</div>
        <div className='form-bloc__form flex-container'>
          {actionArray &&
            Object.entries(actionArray).map((action: [string, any]) => {
              return (
                <div
                  key={uuidV4()}
                  className={`col-md-6 actions-item ${disabledAction({ action }) && 'disabled-content'}`}
                >
                  <button type='button' className='button button--white' onClick={() => handleClickAction(action)}>
                    {t(`distribution.form.setting.actions-editions.${getActionTitle(action)}`)}
                  </button>
                  <InputDate
                    register={register}
                    control={control}
                    type={'text'}
                    name={`${action[0]}.date`}
                    id={`${action[0]}`}
                    disabled={disabledAction({ action })}
                  />
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}

export default Actions
