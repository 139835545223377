import dayjs from 'dayjs'
import React, { FunctionComponent } from 'react'
import { DismembermentLedgerListInterface } from '../../../../../domain/DismembermentLedger/DismembermentLedgerList'
import { ReferentielInterface } from '../../../../../domain/Referentiel/ReferentielInterface'
import NumberFormat from '../../../../../domain/Utils/NumberFormat'
import { useAppSelector } from '../../../../store/hook'
import { getLabelByValue } from '../../../util/ReferentialI18n'

type Props = {
  row: DismembermentLedgerListInterface
  isRowDisabled: boolean
  isSelected: boolean
  onRowClick: (transactionId: string, nbShares: number, duration: number) => void
  multiSelect: boolean
}

const Row: FunctionComponent<Props> = ({ row, isRowDisabled, isSelected, onRowClick }) => {
  const referential: ReferentielInterface | null = useAppSelector(({ referential }) => referential.referential)

  const handleRowClick = () => {
    if (!isRowDisabled) {
      onRowClick(row.transactionId, row.nbSharesAvailable, row.duration)
    }
  }

  return (
    <tr
      onClick={handleRowClick}
      className={`${isRowDisabled ? 'line-disabled' : ''} ${isSelected ? 'line-selected' : ''}`}
      style={{ cursor: isRowDisabled ? 'not-allowed' : 'pointer' }}
    >
      <td>
        <div className={`badge badge--min badge--without-cta badge--partner-product u-mrs`}>
          <div className='badge__container'>
            <div className='badge__text'>{row.productAcronyme}</div>
          </div>
        </div>
      </td>
      <td>{row.investorCode}</td>
      <td>
        <div data-full-text={row.investorName}>{row.investorName}</div>
      </td>
      <td className={`align-td-right`}>
        {row.nbSharesAvailable} / {row.nbShares}
      </td>
      <td className={`align-td-right`}>{NumberFormat.currencyFormat(row.amount)}</td>
      <td>{row.duration}</td>
      <td>{row.dismembermentKey}</td>
      <td>{row.partnerCode}</td>
      <td>{row.partnerName}</td>
      <td>{getLabelByValue(row.execution ?? '', referential?.wallet_subscription.execution_mandat ?? [])}</td>
      <td>{getLabelByValue(row.status ?? '', referential?.wallet.status ?? [])}</td>
      <td>{getLabelByValue(row.fund ?? '', referential?.wallet_subscription.fund_reception_timing_mandat ?? [])}</td>
      <td>
        {row.horodatageDate
          ? dayjs(row.horodatageDate.date, 'YYYY-MM-DD HH24:II:SS').format('DD/MM/YYYY HH:mm:ss')
          : null}
      </td>
      <td>{row.start ? dayjs(row.start.date, 'YYYY-MM-DD').format('DD/MM/YYYY') : null}</td>
      <td>{row.end ? dayjs(row.end.date, 'YYYY-MM-DD').format('DD/MM/YYYY') : null}</td>
    </tr>
  )
}

export default Row
