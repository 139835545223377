import React from 'react'
import { useTranslation } from 'react-i18next'
import { DismembermentTableList } from '../../../../../domain/DismembermentTable/DismembermentTableList'
import DismembermentTableHeader from './DismembermentTableHeader'
import DismembermentTableRow from './DismembermentTableRow'

interface DismembermentTableContentProps {
  table: DismembermentTableList
}

const DismembermentTableContent: React.FC<DismembermentTableContentProps> = ({ table }) => {
  const { t } = useTranslation()

  return (
    <table className='table--dismemberment-table'>
      <DismembermentTableHeader table={table} />
      <tbody>
        <DismembermentTableRow
          title={t('dismemberment-table.heading.bareOwnershipRate')}
          lines={table.lines}
          classes='table__row--bareOwnershipRate'
          rateKey='bareOwnershipRate'
        />
        <DismembermentTableRow
          title={t('dismemberment-table.heading.usufructuaryRate')}
          lines={table.lines}
          classes='table__row--usufructuaryRate'
          rateKey='usufructuaryRate'
        />
      </tbody>
    </table>
  )
}

export default DismembermentTableContent
