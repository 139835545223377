import { ReferentielItemInterface } from '../Referentiel/ReferentielItemInterface'
import { AddressInterface } from './Address'
import { BeneficialBeneficiariesInterface } from './BeneficialBeneficiaries'
import { ContactInterface } from './Contact'
import { ConventionAnnexInterface } from './ConventionAnnex'
import { DocumentInterface } from './Document/Document'
import { RelationLinkInterface } from './RelationLink'
import { SpecificityInterface } from './Specificity'

interface PartnerInterface {
  id: string | null
  partnerCode: string | null
  legalStatus: string | null
  unicia: string | null
  socialReason: string | null
  tradeName: string | null
  internalCommercialDedicated: string | null
  externalId: string | null
  attachment: boolean
  platform: string | null
  network: string | null
  manager: string | null
  statutJuridique: string | null
  type: string | null
  aggregator: ReferentielItemInterface[] | null
  status: string | null
  releasedAtStatus: string | null
  blockedAtStatus: string | null
  rating: string | null
  contactList: ContactInterface[] | null
  addressList: AddressInterface[] | null
  documentList: DocumentInterface[] | null
  beneficialBeneficiariesList: BeneficialBeneficiariesInterface[] | null
  siret: string | null
  orias: string | null
  rcp: string | null
  platformStart: string | null
  platformEnd: string | null
  networkStart: string | null
  networkEnd: string | null
  managerStart: string | null
  managerEnd: string | null
  tvaNumber: string | null
  accountingAccount: string | null
  billingMandate: boolean
  billingMandateValidatedAt: string | null
  bankName: string | null
  iban: string | null
  swift: string | null
  paymentMode: string | null
  conventions: ConventionAnnexInterface[] | null
  externalAdministratorName: string | null
  externalAdministratorCreatedAt: string | null
  underCodeAccess: boolean | null
  digitalUsage: boolean
  needPaper: string | null
  commission: string | null
  invitation: string | null
  communicationNework: string | null
  subjectBs: boolean
  relations: RelationLinkInterface[] | null
  transfert: boolean
  transfertAt: string | null
  radiationAt: string | null
  partnerTransfert: PartnerInterface | null
  comment: string | null
  tvaSubject: boolean
  receptionPaperReporting: string | null
  communicationNetwork: string | null
  externalAccessList: string | null
  radiation: boolean
  createdAt: string | null
  radiationComment: string | null
  completion: string | null
  specificity: SpecificityInterface | null
  capitalCompany: string | null
  oriasDate: string | null
  rcpExpirationAt: string | null
  ownerName: string | null
  internalCommercial: string | null
  commissionLevel: string | null
  category: string | null
  commercialName?: string
  platformType?: string
  networkType?: string
  commissioned: boolean
  hasCommissionRelation: boolean
  commissionRelations: PartnerInterface[]
}

class Partner implements PartnerInterface {
  public id: string | null
  public partnerCode: string | null
  public legalStatus: string | null
  public unicia: string | null
  public socialReason: string | null
  public tradeName: string | null
  public internalCommercialDedicated: string | null
  public externalId: string | null
  public attachment: boolean
  public platform: string | null
  public network: string | null
  public manager: string | null
  public statutJuridique: string | null
  public type: string | null
  public aggregator: ReferentielItemInterface[] | null
  public status: string | null
  public releasedAtStatus: string | null
  public blockedAtStatus: string | null
  public rating: string | null
  public contactList: ContactInterface[]
  public addressList: AddressInterface[]
  public documentList: DocumentInterface[]
  public beneficialBeneficiariesList: BeneficialBeneficiariesInterface[]
  public siret: string | null
  public orias: string | null
  public rcp: string | null
  public platformStart: string | null
  public platformEnd: string | null
  public networkStart: string | null
  public networkEnd: string | null
  public managerStart: string | null
  public managerEnd: string | null
  public tvaNumber: string | null
  public accountingAccount: string | null
  public billingMandate: boolean
  public billingMandateValidatedAt: string | null
  public bankName: string | null
  public iban: string | null
  public swift: string | null
  public paymentMode: string | null
  public conventions: ConventionAnnexInterface[] | null
  public externalAdministratorName: string | null
  public externalAdministratorCreatedAt: string | null
  public underCodeAccess: boolean | null
  public digitalUsage: boolean
  public needPaper: string | null
  public commission: string | null
  public invitation: string | null
  public communicationNework: string | null
  public subjectBs: boolean
  public relations: RelationLinkInterface[] | null
  public transfert: boolean
  public transfertAt: string | null
  public radiationAt: string | null
  public partnerTransfert: Partner | null
  public comment: string | null
  public tvaSubject: boolean
  public receptionPaperReporting: string | null
  public communicationNetwork: string | null
  public externalAccessList: string | null
  public radiation: boolean
  public radiationComment: string | null
  public createdAt: string | null
  public completion: string | null
  public specificity: SpecificityInterface | null
  public capitalCompany: string | null
  public oriasDate: string | null
  public rcpExpirationAt: string | null
  public ownerName: string | null
  public internalCommercial: string | null
  public commissionLevel: string | null
  public category: string | null
  public commercialName?: string
  public platformType?: string
  public networkType?: string
  public commissioned: boolean
  public hasCommissionRelation: boolean
  public commissionRelations: PartnerInterface[]

  constructor(
    id: string | null,
    partnerCode: string | null,
    legalStatus: string | null,
    unicia: string | null,
    socialReason: string | null,
    tradeName: string | null,
    internalCommercialDedicated: string | null,
    externalId: string | null,
    attachment: boolean,
    platform: string | null,
    network: string | null,
    manager: string | null,
    statutJuridique: string | null,
    type: string | null,
    aggregator: ReferentielItemInterface[] | null,
    status: string | null,
    releasedAtStatus: string | null,
    blockedAtStatus: string | null,
    rating: string | null,
    contactList: ContactInterface[] = [],
    addressList: AddressInterface[] = [],
    documentList: DocumentInterface[] = [],
    beneficialBeneficiariesList: BeneficialBeneficiariesInterface[] = [],
    siret: string | null,
    orias: string | null,
    rcp: string | null,
    platformStart: string | null,
    platformEnd: string | null,
    networkStart: string | null,
    networkEnd: string | null,
    managerStart: string | null,
    managerEnd: string | null,
    tvaNumber: string | null,
    accountingAccount: string | null,
    billingMandate: boolean,
    billingMandateValidatedAt: string | null,
    bankName: string | null,
    iban: string | null,
    swift: string | null,
    paymentMode: string | null,
    conventions: ConventionAnnexInterface[] = [],
    externalAdministratorName: string | null,
    externalAdministratorCreatedAt: string | null,
    underCodeAccess: boolean | null,
    digitalUsage: boolean,
    needPaper: string | null,
    commission: string | null,
    invitation: string | null,
    communicationNework: string | null,
    subjectBs: boolean,
    relations: RelationLinkInterface[] = [],
    transfert: boolean,
    transfertAt: string | null,
    radiationAt: string | null,
    partnerTransfert: Partner | null,
    comment: string | null,
    tvaSubject: boolean,
    receptionPaperReporting: string | null,
    communicationNetwork: string | null,
    externalAccessList: string | null,
    radiation: boolean,
    createdAt: string | null,
    radiationComment: string | null,
    completion: string | null,
    specificity: SpecificityInterface | null,
    capitalCompany: string | null,
    oriasDate: string | null,
    rcpExpirationAt: string | null,
    ownerName: string | null,
    internalCommercial: string | null,
    commissionLevel: string | null,
    category: string | null,
    commissioned: boolean,
    hasCommissionRelation: boolean,
    commissionRelations: PartnerInterface[] = [],
    commercialName?: string,
    platformType?: string,
    networkType?: string
  ) {
    this.id = id
    this.partnerCode = partnerCode
    this.legalStatus = legalStatus
    this.unicia = unicia
    this.socialReason = socialReason
    this.tradeName = tradeName
    this.internalCommercialDedicated = internalCommercialDedicated
    this.externalId = externalId
    this.attachment = attachment
    this.platform = platform
    this.network = network
    this.manager = manager
    this.statutJuridique = statutJuridique
    this.type = type
    this.aggregator = aggregator
    this.status = status
    this.releasedAtStatus = releasedAtStatus
    this.blockedAtStatus = blockedAtStatus
    this.rating = rating
    this.contactList = contactList
    this.addressList = addressList
    this.documentList = documentList
    this.beneficialBeneficiariesList = beneficialBeneficiariesList
    this.siret = siret
    this.orias = orias
    this.rcp = rcp
    this.platformStart = platformStart
    this.platformEnd = platformEnd
    this.networkStart = networkStart
    this.networkEnd = networkEnd
    this.managerStart = managerStart
    this.managerEnd = managerEnd
    this.tvaNumber = tvaNumber
    this.accountingAccount = accountingAccount
    this.billingMandate = billingMandate
    this.billingMandateValidatedAt = billingMandateValidatedAt
    this.bankName = bankName
    this.iban = iban
    this.swift = swift
    this.paymentMode = paymentMode
    this.conventions = conventions
    this.externalAdministratorName = externalAdministratorName
    this.externalAdministratorCreatedAt = externalAdministratorCreatedAt
    this.underCodeAccess = underCodeAccess
    this.digitalUsage = digitalUsage
    this.needPaper = needPaper
    this.commission = commission
    this.invitation = invitation
    this.communicationNework = communicationNework
    this.subjectBs = subjectBs
    this.relations = relations
    this.transfert = transfert
    this.transfertAt = transfertAt
    this.radiationAt = radiationAt
    this.partnerTransfert = partnerTransfert
    this.comment = comment
    this.tvaSubject = tvaSubject
    this.receptionPaperReporting = receptionPaperReporting
    this.communicationNetwork = communicationNetwork
    this.externalAccessList = externalAccessList
    this.radiation = radiation
    this.createdAt = createdAt
    this.radiationComment = radiationComment
    this.completion = completion
    this.specificity = specificity
    this.capitalCompany = capitalCompany
    this.oriasDate = oriasDate
    this.rcpExpirationAt = rcpExpirationAt
    this.ownerName = ownerName
    this.internalCommercial = internalCommercial
    this.commissionLevel = commissionLevel
    this.category = category
    this.commercialName = commercialName
    this.platformType = platformType
    this.networkType = networkType
    this.commissioned = commissioned
    this.hasCommissionRelation = hasCommissionRelation
    this.commissionRelations = commissionRelations
  }
}

export { Partner }
export type { PartnerInterface }
