import Caller from '../../domain/Api/Caller'
import {
  DismembermentLedgerGatewayInterface,
  DismembermentLedgerType,
} from '../../domain/DismembermentLedger/DismembermentLedgerGatewayInterface'
import {
  DismembermentLedgerListInterface,
  FilterDismembermentLedgerInterface,
} from '../../domain/DismembermentLedger/DismembermentLedgerList'
import envVariable from '../../infrastructure/presentation/util/envVariable'

export default class DismembermentLedgerGateway implements DismembermentLedgerGatewayInterface {
  getList(
    type: DismembermentLedgerType,
    currentPage: number,
    itemsPerPage: number,
    filter: FilterDismembermentLedgerInterface | null,
    signal?: AbortSignal,
    sort?: string,
    order?: string
  ): Promise<{ data: DismembermentLedgerListInterface[] | null; numberOfItems: number } | null> {
    return Caller.executeGet(
      `${envVariable('REACT_APP_API_URL')}/dismemberment-ledger/${type}?params[nb_element_per_page]=${itemsPerPage}&params[page]=${currentPage}${sort ? '&sort=' + sort + '&order=' + order : ''}`,
      {
        status: filter?.status ? (filter?.status.length > 0 ? filter.status : ['confirmed']) : ['confirmed'],
        product_acronyme: filter?.product.label || '',
        keywords: filter?.keywords || '',
        executions: filter?.executions ?? [],
        typeDurations: filter?.typeDurations ?? [],
        preferences: filter?.preferences ?? [],
      },
      signal
    )
      .then(data => {
        if (data) {
          return data
        }

        return {
          data: [],
          numberOfItems: 0,
        }
      })
      .catch(() => {
        return {
          data: [],
          numberOfItems: 0,
        }
      })
  }

  match(bareOwners: string[], usufructuaries: string[]): Promise<{ id: string; code: string }[] | null> {
    return Caller.executePost(`${envVariable('REACT_APP_API_URL')}/dismemberment-ledger/match`, {
      'bare-owners': bareOwners,
      usufructuaries: usufructuaries,
    }).then(data => {
      if (data) {
        return data
      }

      return []
    })
  }

  getPartners(): Promise<{ partnerId: string; name: string }[] | null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/dismemberment-ledger/partners`, {})
      .then(data => {
        if (data) {
          return data.data
        }

        return null
      })
      .catch(() => {
        return null
      })
  }

  getInvestors(): Promise<{ accountId: string; name: string }[] | null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/dismemberment-ledger/investors`, {}).then(data => {
      if (data) {
        return data.data
      }

      return []
    })
  }

  createSearchMandateAuto(
    bareOwners: string[],
    investorId: string,
    partnerId: string,
    isMultipleMandates: boolean
  ): Promise<string[] | null> {
    return Caller.executePost(`${envVariable('REACT_APP_API_URL')}/dismemberment-ledger/create-search-mandate-auto`, {
      'bare-owners': bareOwners,
      investorId: investorId,
      partnerId: partnerId,
      isMultipleMandates: isMultipleMandates,
    }).then(data => {
      if (data) {
        return data
      }

      return null
    })
  }
}
