import { z } from 'zod'
import Caller from '../../domain/Api/Caller'
import { DismembermentTableGatewayInterface } from '../../domain/DismembermentTable/DismembermentTableGatewayInterface'
import {
  DismembermentTableListInterface,
  FilterDismembermentTableInterface,
} from '../../domain/DismembermentTable/DismembermentTableList'
import envVariable from '../../infrastructure/presentation/util/envVariable'

const DismembermentTableListLineSchema = z.object({
  years: z.number().int().min(0),
  bareOwnershipRate: z.number().min(0).max(100),
  usufructuaryRate: z.number().min(0).max(100),
})

const TsRangeSchema = z
  .object({
    startDate: z.string().refine(date => !isNaN(Date.parse(date)), { message: 'Invalid startDate' }),
    endDate: z.string().refine(date => !isNaN(Date.parse(date)), { message: 'Invalid endDate' }),
  })
  .nullable()

const DismembermentTableListSchema = z.object({
  dismembermentId: z.string(),
  title: z.string(),
  validityPeriod: TsRangeSchema,
  lines: z.array(DismembermentTableListLineSchema),
})

export default class DismembermentTableGateway implements DismembermentTableGatewayInterface {
  async getList(
    currentPage: number,
    itemsPerPage: number,
    filter: FilterDismembermentTableInterface | null,
    signal?: AbortSignal,
    sort?: string | null,
    order?: string | null
  ): Promise<{ data: DismembermentTableListInterface[]; numberOfItems: number } | null> {
    const url = `${envVariable('REACT_APP_API_URL')}/dismemberment-tables?params[nb_element_per_page]=${itemsPerPage}&params[page]=${currentPage}${
      sort ? '&sort=' + sort + '&order=' + order : ''
    }`

    try {
      const response = await Caller.executeGet(
        url,
        {
          product_acronyme: filter?.product?.label || '',
          keywords: filter?.keywords || '',
        },
        signal
      )

      if (response?.data) {
        const validatedData = response.data.map((item: unknown) => DismembermentTableListSchema.parse(item))

        return {
          data: validatedData,
          numberOfItems: response.numberOfItems || 0,
        }
      }

      return { data: [], numberOfItems: 0 }
    } catch (error) {
      console.error('Error validating data in Gateway:', error)
      return { data: [], numberOfItems: 0 }
    }
  }

  async link(
    productId: string,
    dismembermentTableId: string,
    startDate: string,
    endDate: string | null | undefined
  ): Promise<void> {
    const url = `${envVariable('REACT_APP_API_URL')}/dismemberment-tables/link`
    await Caller.executePost(url, {
      productId,
      dismembermentTableId,
      startDate,
      endDate,
    })
  }
}
