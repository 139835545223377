import React from 'react'
import DismembermentTableContent from './DismembermentTableContent'
import { TableProps } from './Props'

const DismembermentTableItem = ({ table }: TableProps) => {
  return (
    <div className='mb-8'>
      <h2 className='u-txt-size-m u-mbs'>{table.title}</h2>
      <DismembermentTableContent table={table} />
    </div>
  )
}

export default DismembermentTableItem
