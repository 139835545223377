import React, { FunctionComponent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { TError } from '../../../../../domain/Error/Error'
import { ProductInterface } from '../../../../../domain/Product/Product'
import { ReferentielInterface } from '../../../../../domain/Referentiel/ReferentielInterface'
import MatchUrl from '../../../../../domain/Utils/MatchedUrl'
import ProductGateway from '../../../../../gateway/Product/ProductGateway'
import GetUseCase from '../../../../../useCase/Product/Get/GetUseCase'
import InitialiazeProduct from '../../../../../useCase/Product/Initialization/InitialiazeProduct'
import { useAppSelector } from '../../../../store/hook'
import DocumentList from '../../../component/Document/DocumentList'
import BankDetailsFrom from '../../../component/Form/Product/BankDetails/BankDetails'
import CommissionBlock from '../../../component/Form/Product/Commission/Commission'
import DepositaryBlock from '../../../component/Form/Product/Depositary/Depositary'
import DismembermentTableForm from '../../../component/Form/Product/DismembermentTableForm/DismembermentTableForm'
import DistributionBlock from '../../../component/Form/Product/Distribution/Distribution'
import FiscalityBlock from '../../../component/Form/Product/Fiscality/Fiscality'
import GeneralInformationBlock from '../../../component/Form/Product/GeneralInformation/GeneralInformation'
import GeneralMeetingBlock from '../../../component/Form/Product/GeneralMeeting/GeneralMeeting'
import MultiProductBlock from '../../../component/Form/Product/MultiProduct/MultiProduct'
import NetAssetValueBlock from '../../../component/Form/Product/NetAssetValue/NetAssetValue'
import Scale from '../../../component/Form/Product/Scale/Scale'
import HeaderPage from '../../../component/HeaderPage/HeaderPage'
import AddBankInformationForm from '../../../component/Sidebar/Product/AddBankInformationForm'
import ChoiceProduct from '../../../component/Sidebar/Product/ChoiceProduct'
import ScaleItemSidebar from '../../../component/Sidebar/Product/ScaleItemSidebar'
import ScaleSidebar from '../../../component/Sidebar/Product/ScaleSidebar'
import Upload from '../../../component/Sidebar/Product/Upload'
import Spinner from '../../../component/Spinner/Spinner'
import TabProductAdd from '../../../component/Tab/ProductAdd/TabProductAdd'
import TabProductEdit from '../../../component/Tab/ProductEdit/TabProductEdit'
import ErrorPage from '../../Error/ErrorPage'

interface Props {
  page: string
  mode: 'add' | 'edit' | 'read'
}

const ProductLayout: FunctionComponent<Props> = ({ page, mode }) => {
  const { t } = useTranslation()
  const { uuid } = useParams()
  const navigate = useNavigate()

  const getProductUseCase = new GetUseCase(new ProductGateway())

  const productRules = useAppSelector(state => state.me.me?.rules.product.actions)

  const referential: ReferentielInterface | null = useAppSelector(({ referential }) => referential.referential)
  const openMainNavigation = useAppSelector(state => state.event.openMainNavigation)

  const reloadProduct = useAppSelector(state => state.product.reloadProduct)
  const [product, setProduct] = useState<ProductInterface | null>(null)
  const [isMultiProduct, setMultiProduct] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [error, setError] = useState<TError | null>(null)
  const [title, setTitle] = useState<string>(t('product.edit.title'))

  const isLectureMode = MatchUrl.find(location.pathname, 'lecture')

  useEffect(() => {
    if (productRules) {
      if (!productRules?.update && !isLectureMode) navigate(`/${t('url.product.read')}/${uuid}`)
    }
  }, [productRules])

  useEffect(() => {
    setIsLoading(true)
    if (undefined !== uuid) {
      getProductUseCase
        .execute(uuid)
        .then(response => {
          if (response !== null) {
            setProduct(response)
            const productType = response.productType?.label || ''
            const productLabel = response.label || response.generalInformation?.acronyme || ''
            setTitle(`${t('product.edit.title')} : ${productLabel} (${productType})`)
          }
        })
        .catch(e => {
          setError(e)
        })
    } else {
      const product = new InitialiazeProduct().initializeProduct()
      setProduct(product)
    }
    setIsLoading(false)
  }, [reloadProduct, uuid])

  const handleChoosePage = (product: ProductInterface) => {
    if (!referential)
      return (
        <section className='container--spinner'>
          <Spinner size={150} />
        </section>
      )

    switch (page) {
      case 'GeneralInformation':
        if ((mode === 'edit' || mode === 'read') && product) {
          return (
            <GeneralInformationBlock
              product={product}
              callbackIsMultiProduct={(isMulti: boolean) => setMultiProduct(isMulti)}
              isLectureMode={isLectureMode}
              referential={referential}
            />
          )
        }
        if (mode === 'add' && product) {
          return (
            <GeneralInformationBlock
              product={product}
              callbackIsMultiProduct={(isMulti: boolean) => setMultiProduct(isMulti)}
              referential={referential}
            />
          )
        }
        break
      case 'MultiProduct':
        if (product) {
          return <MultiProductBlock product={product} isLectureMode={isLectureMode} />
        }
        break
      case 'Distribution':
        return (
          <DistributionBlock product={product || undefined} isLectureMode={isLectureMode} referential={referential} />
        )
        break
      case 'GeneralMeeting':
        return <GeneralMeetingBlock product={product} isLectureMode={isLectureMode} />
        break
      case 'Fiscality':
        return <FiscalityBlock product={product || undefined} isLectureMode={isLectureMode} />
        break
      case 'BankDetails':
        return <BankDetailsFrom product={product} isLectureMode={isLectureMode} />
        break
      case 'Commission':
        return (
          <CommissionBlock product={product || undefined} isLectureMode={isLectureMode} referential={referential} />
        )
        break
      case 'Barème':
        return product && <Scale product={product} isLectureMode={isLectureMode} />
        break
      case 'Depositary':
        return (
          <DepositaryBlock product={product || undefined} isLectureMode={isLectureMode} referential={referential} />
        )
        break
      case 'NetAssetValue':
        if (product) {
          return <NetAssetValueBlock product={product} isLectureMode={isLectureMode} referential={referential} />
        }
        break
      case 'DismembermentTable':
        if (product && product.id) {
          return <DismembermentTableForm productId={product.id} />
        }

        break
      default:
        console.log(`Sorry, we are out of ${page}.`)
    }
  }

  const handleSidesBarDependLectureMode = () => {
    return (
      <>
        {(page === 'GeneralInformation' || page === 'BankDetails') && product && <Upload />}
        {page === 'BankDetails' && <AddBankInformationForm />}
      </>
    )
  }

  if (error) return <ErrorPage code={error.code.toString()} />

  return (
    <div>
      {!isLectureMode && handleSidesBarDependLectureMode()}
      {page === 'GeneralInformation' && <DocumentList />}
      {page === 'MultiProduct' && <ChoiceProduct />}
      {page === 'Barème' && referential && (
        <>
          <ScaleSidebar referential={referential} isLectureMode={isLectureMode} />
          <ScaleItemSidebar referential={referential} isLectureMode={isLectureMode} />
        </>
      )}

      <main className={`main-content ${openMainNavigation ? 'main-content--reduce' : ''}`}>
        <HeaderPage title={mode === 'add' ? t('product.add.title') : title} />
        {product && (mode === 'edit' || mode === 'read') ? (
          <>
            {uuid && product && (
              <TabProductEdit
                uuid={uuid}
                isMultiProduct={isMultiProduct || product?.generalInformation?.multiproduct || false}
                productType={product.productType?.slug || ''}
                isLectureMode={isLectureMode}
              />
            )}
            {!isLoading && product && handleChoosePage(product)}
          </>
        ) : product && mode === 'add' ? (
          <>
            <TabProductAdd isMultiProduct={isMultiProduct || product?.generalInformation?.multiproduct || false} />
            {handleChoosePage(product)}
          </>
        ) : null}
      </main>
    </div>
  )
}

export default ProductLayout
