import React, { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import { FormProvider, SubmitHandler, useForm, useFormState } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
// Notification lib
import 'react-toastify/dist/ReactToastify.min.css'
import { v4 as uuidV4 } from 'uuid'
import iconAdd from '../../../../../../assets/images/icons/add.svg'
import iconEdit from '../../../../../../assets/images/icons/datalist-edit.svg'
import eyeIcon from '../../../../../../assets/images/icons/eye.svg'
import uploadIcon from '../../../../../../assets/images/icons/upload.svg'
import '../../../../../../assets/styles/components/_rating.scss'
import { Address, PARTNER_ADDRESS_STATUS_ACTIVE } from '../../../../../../domain/Partner/Address'
import { Contact } from '../../../../../../domain/Partner/Contact'
import { DocumentBackEndInterface } from '../../../../../../domain/Partner/Document/DocumentBackEnd'
import { PartnerInterface } from '../../../../../../domain/Partner/Partner'
import { SpecificityInterface } from '../../../../../../domain/Partner/Specificity'
import { ReferentielInterface } from '../../../../../../domain/Referentiel/ReferentielInterface'
import { ReferentielItemInterface } from '../../../../../../domain/Referentiel/ReferentielItemInterface'
import DocumentGateway from '../../../../../../gateway/Partner/Document/DocumentGateway'
import PartnerGateway from '../../../../../../gateway/Partner/PartnerGateway'
import AddUseCase from '../../../../../../useCase/Partner/Add/AddUseCase'
import UpdateUseCase from '../../../../../../useCase/Partner/Update/UpdateUseCase'
import {
  setOpenDocumentList,
  setOpenPartnerAddAddressEvent,
  setOpenPartnerContactFormEvent,
  setOpenPartnerUploadForm,
} from '../../../../../store/component/event'
import { reloadPartner } from '../../../../../store/component/partner'
import { useAppDispatch, useAppSelector } from '../../../../../store/hook'
import { getIconForStatus } from '../../../../util/getState'
import { usePrompt } from '../../../../util/Navigation'
import { setReadOnlyFormElements } from '../../../../util/setReadOnlyFormElements'
import { toastError, toastSuccess } from '../../../../util/Toast'
import FormErrorInfo from '../../../Alert/FormErrorInfo/FormErrorInfo'
import FooterBlock from '../../../Block/FooterBlock'
import Input from '../../../Elements/Input'
import InputDate from '../../../Elements/InputDate'
import InputView from '../../../Elements/InputView'
import MultiSelectCustom from '../../../Elements/MultiSelect'
import RadioYesOrNot from '../../../Elements/RadioYesOrNot'
import SelectCustom from '../../../Elements/Select'
import ConfirmationModal from '../../../Modal/ConfirmationModal'
import { ReferentialDocumentsSpecificity, referentialDocumentsSpecificity } from '../Referential/ReferentialSpecificity'

interface IFormInput {
  partnerCode: string | null
  legalStatus: string | null
  unicia: string | null
  socialReason: string | null
  capitalCompany: string | null
  tradeName: string | null
  internalCommercialDedicated: string | null
  externalId: string | null
  attachment: string
  platform: string | null
  platformStart: string | null
  platformEnd: string | null
  network: string | null
  networkStart: string | null
  networkEnd: string | null
  manager: string | null
  managerStart: string | null
  managerEnd: string | null
  statutJuridique: string | null
  type: string | null
  aggregator: ReferentielItemInterface[] | null
  status: string | null
  releasedAtStatus: string | null
  blockedAtStatus: string | null
  rating: string | null
  siret: string | null
  orias: string | null
  oriasDate: string | null
  rcp: string | null
  rcpExpirationAt: string | null
  specificity: SpecificityInterface
  commissionLevel: string | null
  category: string | null
  contactList: Contact[]
  addressList: Address[]
}

type Props = {
  partner: PartnerInterface
  referential: ReferentielInterface
  setStatus: React.Dispatch<React.SetStateAction<string>>
  isLectureMode?: boolean
}

const GeneralInformation: FunctionComponent<Props> = ({ partner, isLectureMode, referential, setStatus }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const countryByDefault = useAppSelector(({ referential }) => referential.countryByDefault)
  const [referentielDocumentsSpecificityList] = useState<ReferentialDocumentsSpecificity>(
    referentialDocumentsSpecificity
  )
  const [documentArray, setDocumentArray] = useState<DocumentBackEndInterface[] | null>(null)
  const contactArray = useAppSelector(state => state.partner.contactArray)
  const addressArray = useAppSelector(state => state.partner.addressArray)
  const openPartnerUploadForm = useAppSelector(state => state.event.openPartnerUploadForm)
  const [isSubFormDirty, setSubFormDirty] = useState(false)
  const [deleteCommissionRelations, setDeleteCommissionRelations] = useState(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [formErrors, setFormErrors] = useState<string[]>([])

  useEffect(() => {
    setSubFormDirty(contactArray !== partner.contactList)
    setValue('contactList', contactArray)
  }, [contactArray])

  useEffect(() => {
    setSubFormDirty(addressArray !== partner.addressList)
    setValue('addressList', addressArray)
  }, [addressArray])

  const methods = useForm<IFormInput>({
    defaultValues: {
      commissionLevel: partner?.commissionLevel || 'niveau_1',
    },
  })
  const { register, control, watch, handleSubmit, reset, setValue, getValues } = methods

  const watchType = watch('type')
  const watchCommissionLevel = watch('commissionLevel')
  const watchStatus = watch('status')
  const watchLegalStatus = watch('legalStatus')
  const watchSocialReason = watch('socialReason')
  const watchSiret = watch('siret')
  const watchStatutJuridique = watch('statutJuridique')
  const watchCategory = watch('category')

  useEffect(() => {
    checkValidity()
  }, [watchSocialReason, addressArray])

  const checkValidity = () => {
    const errors = []
    if (!watchSocialReason) {
      errors.push('partner.form.bloc-general-information.social-reason')
    }
    if (addressArray) {
      const isAddressNotOK = (adress: Address) => adress.addressPrincipal && !adress.pnd
      if (addressArray.filter(isAddressNotOK).length === 0) {
        errors.push('partner.form.bloc-address.title')
      }
    }
    setFormErrors([...errors])
  }

  useEffect(() => {
    if (partner) {
      const selectedAggregators: ReferentielItemInterface[] =
        partner.aggregator?.map(item => {
          const matchedAggregator = referential?.partner.aggregator.find(aggregator => aggregator.value === item.value)
          return matchedAggregator ? matchedAggregator : { ...item, label: item.label || '' } // Remplace le label si besoin
        }) || []
      setValue('aggregator', selectedAggregators)

      setValue('partnerCode', partner.partnerCode)
      setValue('legalStatus', partner.legalStatus)
      setValue('unicia', partner.unicia)
      setValue('socialReason', partner.socialReason)
      setValue('capitalCompany', partner.capitalCompany)
      setValue('tradeName', partner.tradeName)
      setValue('internalCommercialDedicated', partner.internalCommercialDedicated)
      setValue('externalId', partner.externalId)
      setValue('attachment', partner.attachment ? '1' : '0')
      setValue('platform', partner.platform)
      setValue('platformStart', partner.platformStart)
      setValue('platformEnd', partner.platformEnd)
      setValue('network', partner.network)
      setValue('networkStart', partner.networkStart)
      setValue('networkEnd', partner.networkEnd)
      setValue('manager', partner.manager)
      setValue('managerStart', partner.managerStart)
      setValue('managerEnd', partner.managerEnd)
      setValue('statutJuridique', partner.statutJuridique)
      setValue('type', partner.type)
      setValue('status', partner.status)
      setValue('releasedAtStatus', partner.releasedAtStatus)
      setValue('blockedAtStatus', partner.blockedAtStatus)
      setValue('rating', partner.rating)
      setValue('siret', partner.siret)
      setValue('orias', partner.orias)
      setValue('oriasDate', partner.oriasDate)
      setValue('rcp', partner.rcp)
      setValue('rcpExpirationAt', partner.rcpExpirationAt)
      setValue('specificity.mandatSignedAt', partner.specificity?.mandatSignedAt || null)
      setValue('specificity.encartageAt', partner.specificity?.encartageAt || null)
      setValue('specificity.code', partner.specificity?.code || null)
      setValue('specificity.formationInitialAt', partner.specificity?.formationInitialAt || null)
      setValue('specificity.formationYearAt', partner.specificity?.formationYearAt || null)
      setValue('specificity.policyNumber', partner.specificity?.policyNumber || null)
      setValue('specificity.company', partner.specificity?.company || null)
      setValue('category', partner.category)

      if (partner.id !== null) {
        new DocumentGateway(partner.id).getFiles().then(response => {
          setDocumentArray(response)
        })
      }

      setIsLoading(false)
    }
  }, [partner, openPartnerUploadForm.show])

  useEffect(() => {
    if (
      partner?.commissionLevel &&
      partner?.commissionLevel !== watchCommissionLevel &&
      watchCommissionLevel &&
      partner?.commissionRelations.length
    ) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <ConfirmationModal
              onConfirm={() => setDeleteCommissionRelations(true)}
              onClose={() => {
                setValue('commissionLevel', partner.commissionLevel)
                onClose()
              }}
              message={t('partner.form.bloc-general-information.change-level-msg')}
            />
          )
        },
      })
    } else {
      setDeleteCommissionRelations(false)
    }
  }, [watchCommissionLevel])

  const onSubmit: SubmitHandler<IFormInput> = data => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return <ConfirmationModal onConfirm={() => onConfirm(data)} onClose={onClose} />
      },
    })
  }
  const onConfirm = (data: any) => {
    reset(data)

    if (!data.addressList || data.addressList.length === 0) {
      toastError(t('partner.notify.update-address-status-error'))
      return
    }

    const addressMain = data.addressList.find((address: Address) => address.addressPrincipal)

    if (!addressMain) {
      toastError(t('partner.notify.update-address-main-error'))
      return
    }

    const addressInactiveList = data.addressList.filter(
      (address: Address) => address.status !== PARTNER_ADDRESS_STATUS_ACTIVE
    )

    if (addressInactiveList.length === data.addressList.length) {
      toastError(t('partner.notify.update-address-status-error'))
      return
    }

    partner.partnerCode = data.partnerCode
    partner.legalStatus = data.legalStatus
    partner.unicia = data.unicia
    partner.socialReason = data.socialReason
    partner.capitalCompany = data.capitalCompany
    partner.tradeName = data.tradeName
    partner.internalCommercialDedicated = data.internalCommercialDedicated
    partner.externalId = data.externalId
    partner.attachment = data.attachment === '1'
    partner.platform = data.platform
    partner.platformStart = data.platformStart
    partner.platformEnd = data.platformEnd
    partner.network = data.network
    partner.networkStart = data.networkStart
    partner.networkEnd = data.networkEnd
    partner.manager = data.manager
    partner.managerStart = data.managerStart
    partner.managerEnd = data.managerEnd
    partner.statutJuridique = data.statutJuridique
    partner.type = data.type
    partner.aggregator = data.aggregator
    partner.status = data.status
    partner.releasedAtStatus = data.releasedAtStatus
    partner.blockedAtStatus = data.blockedAtStatus
    partner.rating = data.rating
    partner.contactList = data.contactList
    partner.addressList = data.addressList
    partner.siret = data.siret
    partner.orias = data.orias
    partner.oriasDate = data.oriasDate
    partner.rcp = data.rcp
    partner.rcpExpirationAt = data.rcpExpirationAt
    partner.specificity = data.specificity
    partner.commissionLevel = data.commissionLevel
    partner.category = data.category

    if (deleteCommissionRelations) partner.commissionRelations = []

    setIsLoading(true)
    if (partner.id !== null) {
      new UpdateUseCase(new PartnerGateway()).execute(partner).then(response => {
        dispatch(reloadPartner())
        if (null !== response) {
          toastSuccess(t('partner.notify.update-success'))
          if (watchStatus) {
            setStatus(watchStatus)
          } else {
            setStatus('')
          }
        } else {
          toastError(t('partner.notify.update-error'))
        }
      })
    } else {
      setIsLoading(true)
      new AddUseCase(new PartnerGateway()).execute(partner).then(uuid => {
        if (null !== uuid) {
          toastSuccess(t('partner.notify.add-success'))
          navigate(`/${t('url.partner.edit-general-information')}/${uuid}`)
        } else {
          toastError(t('partner.notify.add-error'))
        }
        setIsLoading(false)
      })
    }
  }

  const { isDirty } = useFormState({
    control,
  })
  usePrompt(isLectureMode ? false : partner.id === null ? isDirty : isDirty || isSubFormDirty, handleSubmit(onConfirm))

  function viewFile(type: string) {
    dispatch(
      setOpenDocumentList({
        show: true,
        documentList: documentArray?.filter((document: any) => document.type === type),
        gateway: new DocumentGateway(partner.id),
      })
    )
  }

  const measuredRef = useCallback(node => {
    if (node !== null && isLectureMode) {
      setReadOnlyFormElements(true, node)
    }
  }, [])

  return (
    <>
      {referential && (
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} ref={measuredRef}>
            <div className='flex-container'>
              <div className='col-md-12'>
                {formErrors.length > 0 && (
                  <FormErrorInfo
                    errors={formErrors}
                    message={t('partner.form.bloc-general-information.form-error-base-message')}
                  />
                )}
              </div>
            </div>
            <div className={`form-bloc form-bloc--general-information`}>
              <div className='form-bloc__title'>{t('partner.form.bloc-general-information.title')}</div>
              <div className='form-bloc__form flex-container'>
                <div className='col-md-6'>
                  <div className='flex-container'>
                    <Input
                      register={register}
                      classes={'col-md-6'}
                      label={t('partner.form.bloc-general-information.partner-num')}
                      type={'text'}
                      name={'partnerCode'}
                      id={'partnerCode'}
                      readOnly={true}
                    />
                    <Input
                      register={register}
                      classes={'col-md-6'}
                      label={t('partner.form.bloc-general-information.unicia')}
                      type={'text'}
                      name={'unicia'}
                      id={'unicia'}
                      readOnly={true}
                    />
                    <SelectCustom
                      id='legalStatus'
                      name='legalStatus'
                      classes={`col-md-12 mandatory-field`}
                      valueClasses={watchLegalStatus ? '' : 'field-in-error'}
                      register={register}
                      label={t('partner.form.bloc-general-information.legal-status')}
                      options={referential?.global.title_moral || null}
                    />
                    <Input
                      register={register}
                      classes={`col-md-12 mandatory-field`}
                      label={t('partner.form.bloc-general-information.social-reason')}
                      type={'text'}
                      name={'socialReason'}
                      id={'socialReason'}
                      inputClasses={watchSocialReason ? '' : 'field-in-error'}
                    />
                    <Input
                      register={register}
                      classes={'col-md-12'}
                      label={t('partner.form.bloc-general-information.trade-name')}
                      type={'text'}
                      name={'tradeName'}
                      id={'tradeName'}
                    />
                    <Input
                      register={register}
                      classes={'col-md-12'}
                      label={t('partner.form.bloc-general-information.capital-company')}
                      type={'text'}
                      name={'capitalCompany'}
                      id={'capitalCompany'}
                    />
                    <Input
                      register={register}
                      classes={`col-md-12 mandatory-field`}
                      label={t('partner.form.bloc-general-information.siret')}
                      type={'text'}
                      name={'siret'}
                      id={'siret'}
                      inputClasses={watchSiret ? '' : 'field-in-error'}
                    />
                    {!['seller-freelance', 'seller'].includes(watchType ?? '') && (
                      <>
                        <Input
                          register={register}
                          classes={'col-md-6'}
                          label={t('partner.form.bloc-general-information.orias')}
                          type={'text'}
                          name={'orias'}
                          id={'orias'}
                        />
                        <InputDate
                          register={register}
                          control={control}
                          classes={'col-md-6'}
                          label={t('partner.form.bloc-general-information.orias-date')}
                          type={'text'}
                          name={'oriasDate'}
                          id={'oriasDate'}
                          readOnly={isLectureMode}
                          isLectureMode={isLectureMode}
                        />
                      </>
                    )}
                    <Input
                      register={register}
                      classes={'col-md-6'}
                      label={t('partner.form.bloc-general-information.rcp')}
                      type={'text'}
                      name={'rcp'}
                      id={'rcp'}
                    />
                    <InputDate
                      register={register}
                      control={control}
                      classes={'col-md-6'}
                      label={t('partner.form.bloc-general-information.rcp-expiration-date')}
                      type={'text'}
                      name={'rcpExpirationAt'}
                      id={'rcpExpirationAt'}
                    />
                    <Input
                      classes={'col-md-12'}
                      id='specificity.company'
                      name='specificity.company'
                      register={register}
                      label={t('partner.form.specificity.company')}
                    />
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='flex-container'>
                    <Input
                      label={t('partner.form.bloc-general-information.external-id')}
                      type={'text'}
                      name={'externalId'}
                      id={'externalId'}
                      register={register}
                      classes={'col-md-6'}
                    />
                    <SelectCustom
                      id='statutJuridique'
                      name='statutJuridique'
                      classes={`col-md-6 mandatory-field`}
                      valueClasses={watchStatutJuridique ? '' : 'field-in-error'}
                      register={register}
                      label={t('partner.form.bloc-general-information.statut-juridique')}
                      options={referential?.partner.statut_juridique || null}
                    />
                    <SelectCustom
                      id='type'
                      name='type'
                      classes={`col-md-6 mandatory-field`}
                      valueClasses={watchType ? '' : 'field-in-error'}
                      register={register}
                      label={t('partner.form.bloc-general-information.type')}
                      options={referential?.partner.partner_type || null}
                    />
                    <RadioYesOrNot
                      id='attachment'
                      name='attachment'
                      label={t('partner.form.bloc-general-information.attachment')}
                      register={register}
                      classes={'col-md-6'}
                    />
                    <MultiSelectCustom
                      id='aggregator'
                      name='aggregator'
                      classes='col-md-12 form-control__input'
                      control={control}
                      label={t('partner.form.bloc-general-information.aggregator')}
                      options={referential?.partner.aggregator || null}
                      placeholder={t('common.choice-option')}
                      customOnChange={options => {
                        setValue('aggregator', options)
                      }}
                      defaultValue={getValues('aggregator') ?? []}
                    />
                    <table>
                      <thead>
                        <tr>
                          <th />
                          <th className='form-control__label'>
                            {t('partner.form.bloc-general-information.th-date-start')}
                          </th>
                          <th className='form-control__label'>
                            {t('partner.form.bloc-general-information.th-date-end')}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div className='form-control__input'>
                              <SelectCustom
                                id='platform'
                                name='platform'
                                classes='col-md-12'
                                register={register}
                                label={t('partner.form.bloc-general-information.platform')}
                                options={referential?.partner.platform || null}
                              />
                            </div>
                          </td>
                          <td>
                            <div className='form-control__input'>
                              <InputDate
                                register={register}
                                control={control}
                                classes={'col-md-12'}
                                type={'text'}
                                name={'platformStart'}
                                id={'platformStart'}
                              />
                            </div>
                          </td>
                          <td>
                            <div className='form-control__input'>
                              <InputDate
                                register={register}
                                control={control}
                                classes={'col-md-12'}
                                type={'text'}
                                name={'platformEnd'}
                                id={'platformEnd'}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <SelectCustom
                              id='network'
                              name='network'
                              classes='col-md-12'
                              register={register}
                              label={t('partner.form.bloc-general-information.network')}
                              options={referential?.partner.network || null}
                            />
                          </td>
                          <td>
                            <div className='form-control__input'>
                              <InputDate
                                register={register}
                                control={control}
                                classes={'col-md-12'}
                                type={'text'}
                                name={'networkStart'}
                                id={'networkStart'}
                              />
                            </div>
                          </td>
                          <td>
                            <div className='form-control__input'>
                              <InputDate
                                register={register}
                                control={control}
                                classes={'col-md-12'}
                                type={'text'}
                                name={'networkEnd'}
                                id={'networkEnd'}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <SelectCustom
                              id='manager'
                              name='manager'
                              classes='col-md-12'
                              register={register}
                              label={t('partner.form.bloc-general-information.manager')}
                              options={referential?.partner.manager || null}
                            />
                          </td>
                          <td>
                            <div className='form-control__input'>
                              <InputDate
                                register={register}
                                control={control}
                                classes={'col-md-12'}
                                type={'text'}
                                name={'managerStart'}
                                id={'managerStart'}
                              />
                            </div>
                          </td>
                          <td>
                            <div className='form-control__input'>
                              <InputDate
                                register={register}
                                control={control}
                                classes={'col-md-12'}
                                type={'text'}
                                name={'managerEnd'}
                                id={'managerEnd'}
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <SelectCustom
                      id='category'
                      name='category'
                      classes={`col-md-12 mandatory-field`}
                      valueClasses={watchCategory ? '' : 'field-in-error'}
                      register={register}
                      label={t('partner.form.bloc-general-information.category')}
                      options={referential?.partner.category || null}
                    />
                    <SelectCustom
                      id='commissionLevel'
                      name='commissionLevel'
                      classes='col-md-12'
                      register={register}
                      label={t('partner.form.bloc-general-information.commission-level')}
                      options={referential?.partner.comission || null}
                      noChoiceOption
                    />
                    <Input
                      register={register}
                      classes={'col-md-12'}
                      label={t('partner.form.bloc-general-information.internal-commercial-dedicated')}
                      type={'text'}
                      name={'internalCommercialDedicated'}
                      id={'internalCommercialDedicated'}
                    />
                  </div>
                </div>
              </div>
            </div>
            {(watchType === 'seller' || watchType === 'seller-employee' || watchType === 'seller-freelance') && (
              <div className={`form-bloc form-bloc--specificity`}>
                <div className='form-bloc__title'>{t('partner.form.specificity.title')}</div>
                <div className='form-bloc__form flex-container'>
                  <InputDate
                    register={register}
                    control={control}
                    classes={'col-md-12'}
                    label={t('partner.form.specificity.mandatSignedAt')}
                    type={'text'}
                    name={'specificity.mandatSignedAt'}
                    id={'specificity.mandatSignedAt'}
                    readOnly={isLectureMode}
                    isLectureMode={isLectureMode}
                  />
                  <InputDate
                    register={register}
                    control={control}
                    classes={'col-md-6'}
                    label={t('partner.form.specificity.encartageAt')}
                    type={'text'}
                    name={'specificity.encartageAt'}
                    id={'specificity.encartageAt'}
                    readOnly={isLectureMode}
                    isLectureMode={isLectureMode}
                  />
                  <Input
                    classes={'col-md-6'}
                    id='specificity.code'
                    name='specificity.code'
                    register={register}
                    label={t('partner.form.specificity.code')}
                    readOnly={isLectureMode}
                  />
                  {Object.entries(referentielDocumentsSpecificityList).map(item => (
                    <div key={uuidV4()} className='col-md-6'>
                      <div className='form-control'>
                        <div className='form-control__label'>{t(`partner.form.specificity.${item[1].label}`)}</div>
                        <div className='flex-container'>
                          <InputDate
                            classes='col-md-10'
                            register={register}
                            control={control}
                            type='text'
                            name={`specificity.${item[0]}`}
                            id={`specificity.${item[0]}`}
                            readOnly={isLectureMode}
                            isLectureMode={isLectureMode}
                          />
                          {partner && (
                            <div className='col-md-2'>
                              <button
                                type='button'
                                className='button-reset u-mxs'
                                onClick={() => viewFile(item[1].constantGED)}
                              >
                                <img src={eyeIcon} alt='' />
                              </button>
                              <button
                                type='button'
                                className='button-reset u-mxs'
                                onClick={() =>
                                  !isLectureMode &&
                                  dispatch(
                                    setOpenPartnerUploadForm({
                                      show: true,
                                      documents: null,
                                      partnerId: partner.id,
                                      type: item[1].constantGED,
                                    })
                                  )
                                }
                              >
                                <img src={uploadIcon} alt='' />
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className={`form-bloc form-bloc--contact`}>
              <div className='form-bloc__title'>{t('partner.form.bloc-contact.title')}</div>
              {!isLectureMode && (
                <div className='form-bloc__shortcut'>
                  <button
                    type='button'
                    className='button-reset u-txt-with-icon'
                    onClick={() => dispatch(setOpenPartnerContactFormEvent({ show: true, contact: null }))}
                  >
                    <img src={iconAdd} alt='' />
                    {t('partner.form.bloc-contact.add')}
                  </button>
                </div>
              )}
              {contactArray.length > 0 &&
                contactArray.map(contact => {
                  return (
                    <div className='form-bloc__form flex-container' key={uuidV4()}>
                      <div className='col-md-12'>
                        <div className='form-resume'>
                          <div className='form-control form-control--label-auto'>
                            <label htmlFor='partnerCode' className='form-control__label'>
                              {t('partner.form.bloc-contact.civility')}
                            </label>
                            <div className='form-control__input'>
                              <select disabled>
                                <option value=''>{t('common.choice-option')}</option>
                                {referential.global.title_physic.map((item, index) => (
                                  <option
                                    key={index}
                                    value={item.value}
                                    selected={contact?.person?.title === item.value}
                                  >
                                    {item.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className='form-control form-control--label-auto'>
                            <label htmlFor='partnerCode' className='form-control__label'>
                              {t('partner.form.bloc-contact.lastname')}
                            </label>
                            <div className='form-control__input'>
                              <input type='text' disabled={true} value={`${contact?.person?.lastname}`} />
                            </div>
                          </div>
                          <div className='form-control form-control--label-auto'>
                            <label className='form-control__label'>{t('partner.form.bloc-contact.firstname')}</label>
                            <div className='form-control__input'>
                              <input type='text' disabled={true} value={`${contact?.person?.firstname}`} />
                            </div>
                          </div>
                          <div className='form-control form-control--label-auto'>
                            <label className='form-control__label'>{t('partner.form.bloc-contact.email')}</label>
                            <div className='form-control__input'>
                              <input type='text' disabled={true} value={`${contact?.person?.email}`} />
                            </div>
                          </div>
                          <div className='form-control form-control--label-auto'>
                            <label className='form-control__label'>{t('partner.form.bloc-contact.role')}</label>
                            <div className='form-control__input'>
                              <input
                                type='text'
                                disabled={true}
                                value={`${referential.partner.role.find(role => role.value === contact?.role)?.label}`}
                              />
                            </div>
                          </div>
                          <div>{contact?.status && getIconForStatus(contact.status === 'active')}</div>
                          <div className='form-resume__expend'>
                            <button
                              type='button'
                              className='button-reset'
                              onClick={() =>
                                !isLectureMode &&
                                dispatch(setOpenPartnerContactFormEvent({ show: true, contact: contact }))
                              }
                              disabled={isLectureMode}
                            >
                              <img src={iconEdit} alt='' />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
            </div>
            <div className={`form-bloc form-bloc--address`}>
              <div className='form-bloc__title'>{t('partner.form.bloc-address.title')}</div>
              <div className='form-bloc__shortcut'>
                {!isLectureMode && (
                  <button
                    type='button'
                    className='button-reset u-txt-with-icon'
                    onClick={() =>
                      dispatch(
                        setOpenPartnerAddAddressEvent({
                          show: true,
                          address: null,
                          defaultCountry: countryByDefault[0],
                        })
                      )
                    }
                  >
                    <img src={iconAdd} alt='' />
                    {t('partner.form.bloc-address.add')}
                  </button>
                )}
              </div>
              {addressArray.length > 0 &&
                addressArray.map(address => {
                  return (
                    <div className='form-bloc__form flex-container' key={uuidV4()}>
                      <div className='col-md-12'>
                        <div className='form-resume'>
                          <div className='form-control form-control--label-auto u-mb0'>
                            <label htmlFor='partnerCode' className='form-control__label'>
                              {t('partner.form.bloc-address.civility')}
                            </label>
                            <div className='form-control__input'>
                              <select disabled>
                                <option value=''>{t('common.choice-option')}</option>
                                {referential.global.title_physic.map((item, index) => (
                                  <option key={index} value={item.value} selected={address.civility === item.value}>
                                    {item.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <InputView
                            classesFormControl='form-control--label-auto u-mb0'
                            label={t('partner.form.bloc-address.lastname')}
                            defaultValue={address.lastName}
                            readOnly={true}
                          />
                          <InputView
                            classesFormControl='form-control--label-auto u-mb0'
                            label={t('partner.form.bloc-address.firstname')}
                            defaultValue={address.firstName}
                            readOnly={true}
                          />
                          <InputView
                            classesFormControl='form-control--label-auto u-mb0'
                            label={t('partner.form.bloc-address.zipcode')}
                            defaultValue={address.zipcode}
                            readOnly={true}
                          />
                          <div className='form-control form-control--label-auto u-mb0'>
                            <label className='form-control__label'>{t('partner.form.bloc-address.city')}</label>
                            <div className='form-control__input'>
                              <input type='text' disabled={true} value={`${address.city?.label}`} />
                            </div>
                          </div>
                          <InputView
                            classesFormControl='form-control--label-auto u-mb0'
                            label={t('partner.form.bloc-address.service')}
                            defaultValue={address.service}
                            readOnly={true}
                          />
                          <div>{address?.status && getIconForStatus(address.status === 'active')}</div>
                          <div className='form-resume__expend'>
                            <button
                              type='button'
                              className='button-reset'
                              onClick={() =>
                                !isLectureMode &&
                                dispatch(setOpenPartnerAddAddressEvent({ show: true, address: address }))
                              }
                              disabled={isLectureMode}
                            >
                              <img src={iconEdit} alt='' />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
            </div>
            <div className={`form-bloc form-bloc--status`}>
              <div className='form-bloc__title'>{t('partner.form.bloc-status.title')}</div>
              <div className='form-bloc__form flex-container'>
                <div className='col-md-12'>
                  <div className='flex-container'>
                    <SelectCustom
                      id='status'
                      name='status'
                      classes='col-md-6 col-lg-3'
                      valueClasses={`${watchStatus ? '' : 'field-in-error'}`}
                      register={register}
                      labelClasses={`mandatory-field`}
                      label={t('partner.form.bloc-status.status')}
                      options={referential?.partner.status || null}
                    />
                  </div>
                </div>
                <div className='col-md-12'>
                  <div className='flex-container'>
                    <div className='col-md-6 col-lg-3'>
                      <div className='form-control'>
                        <label htmlFor='createdAt' className='form-control__label'>
                          {t('partner.form.bloc-status.created-at')}
                        </label>
                        <div className='form-control__input'>
                          <input
                            value={partner && partner !== null && partner.createdAt !== null ? partner.createdAt : ''}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className='col-md-6 col-lg-3'>
                      <div className='form-control'>
                        <label htmlFor='releasedAtStatus' className='form-control__label'>
                          {t('partner.form.bloc-status.released-at')}
                        </label>
                        <div className='form-control__input'>
                          <InputDate
                            id='releasedAtStatus'
                            name='releasedAtStatus'
                            register={register}
                            control={control}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='col-md-6 col-lg-3'>
                      <div className='form-control'>
                        <label htmlFor='blockedAtStatus' className='form-control__label'>
                          {t('partner.form.bloc-status.blocked-at')}
                        </label>
                        <div className='form-control__input'>
                          <InputDate
                            id='blockedAtStatus'
                            name='blockedAtStatus'
                            register={register}
                            control={control}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {!isLectureMode && (
              <FooterBlock disabled={!watchStatus || isLoading} onCancelUrl={t('url.partner.dashboard')} />
            )}
          </form>
        </FormProvider>
      )}
    </>
  )
}

export default GeneralInformation
