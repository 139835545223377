import dayjs from 'dayjs'
import { v4 as uuidV4 } from 'uuid'
import Caller from '../../domain/Api/Caller'
import { CallerType } from '../../domain/Caller/Caller'
import { Address } from '../../domain/Partner/Address'
import { BeneficialBeneficiaries } from '../../domain/Partner/BeneficialBeneficiaries'
import { Contact } from '../../domain/Partner/Contact'
import { ConventionAnnex, ConventionAnnexInterface } from '../../domain/Partner/ConventionAnnex'
import { Document } from '../../domain/Partner/Document/Document'
import { Partner, PartnerInterface } from '../../domain/Partner/Partner'
import { FilterPartnerInterface, PartnerListInterface } from '../../domain/Partner/PartnerList'
import { Person } from '../../domain/Partner/Person'
import { RelationLink } from '../../domain/Partner/RelationLink'
import { Specificity } from '../../domain/Partner/Specificity'
import { TransferObjectSubmit } from '../../domain/Partner/Transfer'
import { ScaleDerogatory, ScaleResponseDetail } from '../../domain/Product/Scale'
import { KpiResponseInterface } from '../../infrastructure/presentation/Type/Kpi'
import envVariable from '../../infrastructure/presentation/util/envVariable'

export default class PartnerGateway {
  get(partnerId: string): Promise<PartnerInterface | null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/partners/${partnerId}`, {}).then(data => {
      if (data) {
        return new Partner(
          data.id,
          data.partnerCode,
          data.legalStatus,
          data.unicia,
          data.socialReason,
          data.commercialName,
          data.internalCommercial,
          data.externalId,
          data.attachment,
          data.platformType,
          data.networkType,
          data.manager,
          data.statutJuridique,
          data.type,
          data.aggregator.map((value: string) => {
            return {
              value: value,
              label: '',
            }
          }),
          data.status,
          data.releasedAtStatus,
          data.blockedAtStatus,
          data.rating,
          data.contacts?.map(
            (contact: {
              id: string
              role: string
              status: string | null
              person: {
                id: string | null
                title: string | null
                firstname: string | null
                lastname: string | null
                maidenName: string | null
                gerant: boolean
                email: string | null
                phone: string | null
              }
            }) => {
              return new Contact(
                contact.id ?? uuidV4(),
                contact.role,
                contact.status,
                new Person(
                  contact.person.id,
                  contact.person.title,
                  contact.person.firstname,
                  contact.person.lastname,
                  contact.person.maidenName,
                  null,
                  null,
                  null,
                  null,
                  null,
                  null,
                  null,
                  contact.person.gerant,
                  contact.person.email,
                  contact.person.phone
                )
              )
            }
          ),
          data.addresses?.map(
            (a: {
              address: {
                id: string
                title: string
                lastname: string
                maidenName: string
                firstname: string
                service: string
                address: string
                address2: string
                address3: string
                postcode: string
                city: any
                country: any
                pnd: boolean
              }
              main: boolean
              status: string
            }) => {
              return new Address(
                a.address.id,
                a.main,
                a.address.title,
                a.address.lastname,
                a.address.maidenName,
                a.address.firstname,
                a.address.service,
                a.address.address,
                a.address.address2,
                a.address.address3,
                a.address.postcode,
                a.address.city,
                a.address.country,
                a.address.pnd,
                a.status
              )
            }
          ),
          data.documents?.map(
            (d: {
              requestAt: string
              sendAt: string
              documentDate: string
              type: string
              waiting: boolean
              title: string
            }) => {
              return new Document(0, d.title, d.type, d.requestAt, d.sendAt, d.waiting, '')
            }
          ),
          data.beneficialOwners?.map((beneficialOwner: any) => {
            return new BeneficialBeneficiaries(
              beneficialOwner.person.id,
              beneficialOwner.status,
              beneficialOwner.ppe,
              beneficialOwner.shareHolding,
              new Person(
                beneficialOwner.person.id,
                beneficialOwner.person.title,
                beneficialOwner.person.firstname,
                beneficialOwner.person.lastname,
                beneficialOwner.person.maidenname,
                beneficialOwner.person.birthdate,
                beneficialOwner.person.birthCity,
                beneficialOwner.person.birthCountry,
                beneficialOwner.person.nationality,
                beneficialOwner.person.ppe,
                beneficialOwner.person.ppeCategory,
                new Address(
                  beneficialOwner.person?.address?.id || '',
                  false,
                  beneficialOwner.person?.address?.title || '',
                  beneficialOwner.person?.address?.lastName || '',
                  beneficialOwner.person?.address?.maidenName || '',
                  beneficialOwner.person?.address?.firstName || '',
                  '',
                  beneficialOwner.person?.address?.address || '',
                  beneficialOwner.person?.address?.address2 || '',
                  beneficialOwner.person?.address?.address3 || '',
                  beneficialOwner.person?.address?.postcode || '',
                  beneficialOwner.person?.address?.city || '',
                  beneficialOwner.person?.address?.country || '',
                  false,
                  null
                ),
                false,
                null,
                null
              )
            )
          }),
          data.siret,
          data.orias,
          data.rcp,
          data.platformStart,
          data.platformEnd,
          data.networkStart,
          data.networkEnd,
          data.managerStart,
          data.managerEnd,
          data.tvaNumber,
          data.accountingAccount,
          data.billingMandate,
          data.billingMandateValidatedAt,
          data.bankName,
          data.iban,
          data.swift,
          data.paymentMode,
          data.conventions?.map((convention: ConventionAnnexInterface) => {
            return new ConventionAnnex(
              convention.id,
              convention.acronym,
              convention.code,
              convention.date,
              convention?.period,
              convention.particularite,
              convention.product,
              convention.scaleItems,
              null,
              convention.scCommissionEarned,
              convention.scCommissionEarnedPercent,
              convention.scCommissionNotEarned,
              convention.scCommissionNotEarnedPercent
            )
          }),
          data.externalAdministratorName,
          data.externalAdministratorCreatedAt,
          data.underCodeAccess,
          data.digitalUsage,
          data.needPaper,
          data.commission,
          data.invitation,
          data.communicationNetwork,
          data.subjectBs,
          data.relations?.map((relation: { id: string; lastname: string; firstname: string; link: string }) => {
            return new RelationLink(relation.id, relation.lastname, relation.firstname, relation.link)
          }),
          data.transfert,
          data.transfertAt,
          data.radiationAt,
          data.partnerTransfert,
          data.comment,
          data.tvaSubject,
          data.receptionPaperReporting,
          data.communicationNetwork,
          data.externalAccessList,
          data.radiation,
          data.createdAt,
          data.radiationComment,
          data.completion,
          new Specificity(
            data.specificity?.mandatSignedAt,
            data.specificity?.encartageAt,
            data.specificity?.code,
            data.specificity?.formationInitialAt,
            data.specificity?.formationYearAt,
            data.specificity?.policyNumber,
            data.specificity?.company
          ),
          data.capitalCompany,
          data.oriasDate,
          data.rcpExpirationAt,
          data.ownerName,
          data.commercialName,
          data.commissionLevel,
          data.category,
          data.commissioned,
          data.hasCommissionRelation,
          data.commissionRelations.map((relation: { relation: PartnerInterface }) => {
            return relation.relation
          })
        )
      }

      return null
    })
  }

  getKpi(): Promise<KpiResponseInterface[] | null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/partners/kpi`, {})
      .then(data => {
        if (data) {
          return data
        }

        return null
      })
      .catch(response => {
        return null
      })
  }

  public async getList(
    callerType: CallerType,
    currentPage: number,
    itemsPerPage: number,
    filter: FilterPartnerInterface | null,
    signal?: AbortSignal,
    sort?: string,
    order?: string
  ): Promise<{ data: PartnerListInterface[] | null; numberOfItems: number } | null> {
    return Caller.executeGet(
      `${envVariable('REACT_APP_API_URL')}/partners${callerType === 'datalist' ? '-datalist' : ''}?params[nb_element_per_page]=${itemsPerPage}&params[page]=${currentPage}${sort ? '&sort=' + sort + '&order=' + order : ''}`,
      {
        idPartner: filter?.idPartner || '',
        keywords: filter?.keywords || '',
        socialReason: filter?.socialReason || '',
        city: filter?.city.id || '',
        idProduct: filter?.product.id || '',
        name: filter?.name || '',
        status: filter?.status?.map(it => it.value) || '',
        type: filter?.type.map(it => it.value) || '',
        platform: filter?.platform.map(it => it.value) || '',
        network: filter?.network.map(it => it.value) || '',
        commission_level: filter?.commissionLevel || '',
      },
      signal
    )
      .then(data => {
        if (data) {
          return data
        }

        return {
          data: [],
          numberOfItems: 0,
        }
      })
      .catch(response => {
        return {
          data: [],
          numberOfItems: 0,
        }
      })
  }

  public async add(partner: PartnerInterface): Promise<string | null> {
    const commissionRelations: { relation: string }[] = []
    partner.commissionRelations.forEach(rel => {
      if (rel.id) {
        commissionRelations.push({
          relation: rel?.id,
        })
      }
    })
    return Caller.executePost(`${envVariable('REACT_APP_API_URL')}/partners`, {
      legalStatus: partner.legalStatus,
      commissionLevel: partner.commissionLevel,
      category: partner.category,
      socialReason: partner.socialReason,
      commercialName: partner.tradeName,
      internalCommercial: partner.internalCommercialDedicated,
      siret: partner.siret,
      orias: partner.orias,
      rcp: partner.rcp,
      externalId: partner.externalId,
      attachment: partner.attachment,
      platformType: partner.platform,
      platformStart: partner.platformStart ? partner.platformStart : null,
      platformEnd: partner.platformEnd ? partner.platformEnd : null,
      networkType: partner.network,
      networkStart: partner.networkStart ? partner.networkStart : null,
      networkEnd: partner.networkEnd ? partner.networkEnd : null,
      manager: partner.manager,
      managerStart: partner.managerStart ? partner.managerStart : null,
      managerEnd: partner.managerEnd ? partner.managerEnd : null,
      status: partner.status,
      releasedAtStatus: partner.releasedAtStatus ? partner.releasedAtStatus : null,
      blockedAtStatus: partner.blockedAtStatus ? partner.blockedAtStatus : null,
      tvaNumber: partner.tvaNumber,
      tvaSubject: partner.tvaSubject,
      accountingAccount: partner.accountingAccount,
      billingMandate: partner.billingMandate,
      billingMandateValidatedAt: partner.billingMandateValidatedAt ? partner.billingMandateValidatedAt : null,
      bankName: partner.bankName,
      iban: partner.iban,
      swift: partner.swift,
      paymentMode: partner.paymentMode,
      externalAdministratorName: partner.externalAdministratorName,
      externalAdministratorCreatedAt: partner.externalAdministratorCreatedAt
        ? partner.externalAdministratorCreatedAt
        : null,
      underCodeAccess: partner.underCodeAccess,
      digitalUsage: partner.digitalUsage,
      receptionPaperReporting: partner.receptionPaperReporting,
      needPaper: partner.needPaper,
      commission: partner.commission,
      invitation: partner.invitation,
      communicationNetwork: partner.communicationNetwork,
      externalAccessList: partner.externalAccessList,
      subjectBs: partner.subjectBs,
      transfert: partner.transfert,
      transfertAt: partner.transfertAt ? partner.transfertAt : null,
      radiation: partner.radiation,
      radiationComment: partner.radiationComment,
      radiationAt: partner.radiationAt ? partner.radiationAt : null,
      statutJuridique: partner.statutJuridique,
      type: partner.type,
      aggregator: partner.aggregator,
      partnerTransfertId: partner.partnerTransfert?.id,
      comment: partner.comment,
      rating: partner.rating,
      addresses: partner.addressList?.map(address => {
        return {
          main: address.addressPrincipal,
          status: address.status,
          address: {
            npaiAt: address.pnd ? dayjs().format('YYYY-DD-MM') : null,
            service: address.service,
            civility: address.civility,
            lastname: address.lastName,
            firstname: address.firstName,
            address: address.addressFirstLine,
            address2: address.addressSecondLine,
            address3: address.addressThirdLine,
            postcode: address.zipcode,
            city: address.city?.id,
            country: address.country?.id,
          },
        }
      }),
      documents: partner.documentList,
      contacts: partner.contactList?.map(contact => {
        return {
          role: contact.role,
          status: contact.status,
          person: {
            title: contact?.person?.title || null,
            firstname: contact?.person?.firstname || null,
            lastname: contact?.person?.lastname || null,
            maidenName: contact?.person?.maidenname || null,
            gerant: contact?.person?.gerant || null,
            email: contact?.person?.email || null,
            phone: contact?.person?.phone || null,
          },
        }
      }),
      beneficialOwners: partner.beneficialBeneficiariesList?.map(beneficialOwner => {
        return {
          ppe: beneficialOwner.ppe,
          shareHolding: parseInt(beneficialOwner.shareholding),
          status: beneficialOwner.status,
          person: {
            title: beneficialOwner.person?.title,
            lastname: beneficialOwner.person?.lastname,
            firstname: beneficialOwner.person?.firstname,
            maidenName: beneficialOwner.person?.maidenname,
            birthdate: beneficialOwner.person?.birthdate,
            birthCountry: beneficialOwner.person?.birthCountry?.id,
            birthCity: beneficialOwner.person?.birthCity?.id,
            nationality: beneficialOwner.person?.nationality?.id,
            ppe: beneficialOwner.person?.ppe,
            ppeCategory: beneficialOwner.person?.ppeCategory,
            address: {
              title: beneficialOwner.person?.address?.civility,
              lastname: beneficialOwner.person?.address?.lastName,
              firstname: beneficialOwner.person?.address?.firstName,
              maidenName: beneficialOwner.person?.address?.maidenName,
              address: beneficialOwner.person?.address?.addressFirstLine,
              address2: beneficialOwner.person?.address?.addressSecondLine,
              address3: beneficialOwner.person?.address?.addressThirdLine,
              postcode: beneficialOwner.person?.address?.zipcode,
              city: beneficialOwner.person?.address?.city?.id,
              country: beneficialOwner.person?.address?.country?.id,
            },
          },
        }
      }),
      conventions: partner.conventions?.map(convention => {
        return {
          id: convention.id,
          code: convention.code,
          date: convention.date ? convention.date : null,
          particularite: convention.particularite,
          product: convention.product,
          acronym:
            convention.acronym !== null && convention.acronym !== '' && convention.acronym !== undefined
              ? convention.acronym
              : convention.product?.generalInformation?.acronyme,
          scaleItems: convention.scaleItems.map(scaleItem => {
            return scaleItem.id
          }),
          derogatory: convention.derogatory
            ? {
                date: convention.derogatory.date,
                id: convention.derogatory.id?.includes('provisional') ? null : convention.derogatory.id,
                items: convention.derogatory.items?.map(item => {
                  return {
                    id: item.id?.includes('provisional') ? null : item.id,
                    value: item.value,
                    approbationAt: item.approbationAt,
                    applicationAt: item.applicationAt,
                    start: item.period?.start,
                    end: item.period?.end,
                    category: item.category?.id,
                    derogatorySelected: item.derogatorySelected,
                  }
                }),
              }
            : null,
          scCommissionEarned: convention.scCommissionEarned,
          scCommissionEarnedPercent: convention.scCommissionEarnedPercent,
          scCommissionNotEarned: convention.scCommissionNotEarned,
          scCommissionNotEarnedPercent: convention.scCommissionNotEarnedPercent,
        }
      }),
      relations: partner.relations?.map(relation => {
        return {
          firstname: relation.firstName,
          lastname: relation.lastName,
          link: relation.link,
        }
      }),
      specificity: partner.specificity,
      capitalCompany: partner.capitalCompany,
      oriasDate: partner.oriasDate,
      rcpExpirationAt: partner.rcpExpirationAt,
      ownerName: partner.ownerName,
      commissioned: partner.commissioned,
      commissionRelations,
      hasCommissionRelation: partner.hasCommissionRelation,
    }).then(data => {
      if (data) {
        return data.uuid
      }

      return null
    })
  }

  public async update(partner: PartnerInterface): Promise<{ uuid: string } | null> {
    const commissionRelations: { relation: string }[] = []
    partner.commissionRelations.forEach(rel => {
      if (rel.id) {
        commissionRelations.push({
          relation: rel?.id,
        })
      }
    })
    return Caller.executePut(`${envVariable('REACT_APP_API_URL')}/partners/${partner.id}`, {
      legalStatus: partner.legalStatus,
      partnerTransfert: partner.partnerTransfert,
      commissionLevel: partner.commissionLevel,
      category: partner.category,
      socialReason: partner.socialReason,
      commercialName: partner.tradeName,
      internalCommercial: partner.internalCommercialDedicated,
      siret: partner.siret,
      orias: partner.orias,
      rcp: partner.rcp,
      externalId: partner.externalId,
      attachment: partner.attachment,
      platformType: partner.platform,
      platformStart: partner.platformStart,
      platformEnd: partner.platformEnd,
      networkType: partner.network,
      networkStart: partner.networkStart,
      networkEnd: partner.networkEnd,
      manager: partner.manager,
      managerStart: partner.managerStart,
      managerEnd: partner.managerEnd,
      status: partner.status,
      releasedAtStatus: partner.releasedAtStatus,
      blockedAtStatus: partner.blockedAtStatus,
      tvaNumber: partner.tvaNumber,
      tvaSubject: partner.tvaSubject,
      accountingAccount: partner.accountingAccount,
      billingMandate: partner.billingMandate,
      billingMandateValidatedAt: partner.billingMandateValidatedAt,
      bankName: partner.bankName,
      iban: partner.iban,
      swift: partner.swift,
      paymentMode: partner.paymentMode,
      externalAdministratorName: partner.externalAdministratorName,
      externalAdministratorCreatedAt: partner.externalAdministratorCreatedAt,
      underCodeAccess: partner.underCodeAccess,
      digitalUsage: partner.digitalUsage,
      receptionPaperReporting: partner.receptionPaperReporting,
      needPaper: partner.needPaper,
      commission: partner.commission,
      invitation: partner.invitation,
      communicationNetwork: partner.communicationNetwork,
      externalAccessList: partner.externalAccessList,
      subjectBs: partner.subjectBs,
      transfert: partner.transfert,
      transfertAt: partner.transfertAt,
      radiation: partner.radiation,
      radiationComment: partner.radiationComment,
      radiationAt: partner.radiationAt,
      statutJuridique: partner.statutJuridique,
      type: partner.type,
      aggregator: partner.aggregator?.map(aggregator => {
        return aggregator.value
      }),
      partnerTransfertId: partner.partnerTransfert?.id,
      comment: partner.comment,
      rating: partner.rating,
      addresses: partner.addressList?.map(address => {
        return {
          main: address.addressPrincipal,
          status: address.status,
          address: {
            id: address.id.includes('provisional') ? null : address.id,
            npaiAt: address.pnd ? dayjs().format('YYYY-DD-MM') : null,
            service: address.service,
            title: address.civility,
            lastname: address.lastName,
            firstname: address.firstName,
            address: address.addressFirstLine,
            address2: address.addressSecondLine,
            address3: address.addressThirdLine,
            postcode: address.zipcode,
            city: address.city?.id,
            country: address.country?.id,
          },
        }
      }),
      documents: partner.documentList,
      contacts: partner.contactList?.map(contact => {
        return {
          id: contact.id?.includes('provisional') ? null : contact.id,
          role: contact.role,
          status: contact.status,
          person: {
            id: contact?.person?.id !== null && contact.person?.id.includes('provisional') ? null : contact.person?.id,
            title: contact?.person?.title || null,
            firstname: contact?.person?.firstname || null,
            lastname: contact?.person?.lastname || null,
            maidenName: contact?.person?.maidenname || null,
            gerant: contact?.person?.gerant || null,
            email: contact?.person?.email || null,
            phone: contact?.person?.phone || null,
          },
        }
      }),
      beneficialOwners: partner.beneficialBeneficiariesList?.map(beneficialOwner => {
        return {
          status: beneficialOwner.status,
          ppe: beneficialOwner.ppe,
          shareHolding: parseInt(beneficialOwner.shareholding),
          person: {
            id:
              beneficialOwner.person?.id !== null && beneficialOwner.person?.id.includes('provisional')
                ? null
                : beneficialOwner.person?.id,
            title: beneficialOwner.person?.title,
            lastname: beneficialOwner.person?.lastname,
            firstname: beneficialOwner.person?.firstname,
            maidenName: beneficialOwner.person?.maidenname,
            birthdate: beneficialOwner.person?.birthdate,
            birthCountry: beneficialOwner.person?.birthCountry?.id,
            birthCity: beneficialOwner.person?.birthCity?.id,
            nationality: beneficialOwner.person?.nationality?.id,
            ppe: beneficialOwner.person?.ppe,
            ppeCategory: beneficialOwner.person?.ppeCategory,
            address: {
              id: beneficialOwner.person?.address?.id,
              title: beneficialOwner.person?.address?.civility,
              lastname: beneficialOwner.person?.address?.lastName,
              firstname: beneficialOwner.person?.address?.firstName,
              maidenName: beneficialOwner.person?.address?.maidenName,
              address: beneficialOwner.person?.address?.addressFirstLine,
              address2: beneficialOwner.person?.address?.addressSecondLine,
              address3: beneficialOwner.person?.address?.addressThirdLine,
              postcode: beneficialOwner.person?.address?.zipcode,
              city: beneficialOwner.person?.address?.city?.id,
              country: beneficialOwner.person?.address?.country?.id,
            },
          },
        }
      }),
      conventions: partner.conventions?.map(convention => {
        return {
          id: convention.id.includes('provisional') ? null : convention.id,
          product: convention.product?.id,
          code: convention.code,
          date: convention.date,
          particularite: convention.particularite,
          scaleItems: convention.scaleItems.map(scaleItem => {
            return scaleItem.id
          }),
          derogatory: convention.derogatory
            ? {
                id: convention.derogatory.id?.includes('provisional') ? null : convention.derogatory.id,
                date: convention.derogatory.date,
                items: convention.derogatory.items?.map(item => {
                  return {
                    id: item.id?.includes('provisional') ? null : item.id,
                    value: item.value,
                    approbationAt: item.approbationAt,
                    applicationAt: item.applicationAt,
                    start: item.period?.start,
                    end: item.period?.end,
                    category: item.category?.id,
                    derogatorySelected: item.derogatorySelected,
                  }
                }),
              }
            : null,
          scCommissionEarned: convention.scCommissionEarned,
          scCommissionEarnedPercent: convention.scCommissionEarnedPercent,
          scCommissionNotEarned: convention.scCommissionNotEarned,
          scCommissionNotEarnedPercent: convention.scCommissionNotEarnedPercent,
        }
      }),
      relations: partner.relations?.map(relation => {
        return {
          id: relation.id.includes('provisional') ? null : relation.id,
          firstname: relation.firstName,
          lastname: relation.lastName,
          link: relation.link,
        }
      }),
      specificity: partner.specificity,
      capitalCompany: partner.capitalCompany,
      oriasDate: partner.oriasDate,
      rcpExpirationAt: partner.rcpExpirationAt,
      ownerName: partner.ownerName,
      commissioned: partner.commissioned,
      commissionRelations,
      hasCommissionRelation: partner.hasCommissionRelation,
    }).then(data => {
      return data.uuid || null
    })
  }

  public async getListDocument(partnerId: string, signal?: AbortSignal): Promise<any> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/partners/${partnerId}/files`, {}, signal)
      .then(data => {
        if (data) {
          return data
        }

        return {
          data: [],
          numberOfItems: 0,
        }
      })
      .catch(response => {
        return {
          data: [],
          numberOfItems: 0,
        }
      })
  }

  public async getExport(filter: FilterPartnerInterface | null): Promise<string | null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/partners/extract`, {
      idPartner: filter?.idPartner || '',
      keywords: filter?.keywords || '',
      socialReason: filter?.socialReason || '',
      idCity: filter?.city.id || '',
      idProduct: filter?.product.id || '',
      name: filter?.name || '',
      status: filter?.status?.map(it => it.value) || '',
      type: filter?.type.map(it => it.value) || '',
      platform: filter?.platform.map(it => it.value) || '',
      network: filter?.network.map(it => it.value) || '',
    })
      .then(data => {
        if (data && data.content) {
          return data.content
        }

        return null
      })
      .catch(response => {
        return null
      })
  }

  public async deleteCommission(partnerId: string, commissionId: string): Promise<any> {
    return Caller.executeDelete(
      `${envVariable('REACT_APP_API_URL')}/partners/${partnerId}/conventions/${commissionId}`,
      {}
    )
      .then(result => {
        if (result && result.data) {
          return result.data
        }
        return null
      })
      .catch(() => null)
  }

  public async getConventionByProduct(partnerId: string, productId: string): Promise<string | null> {
    return Caller.executeGet(
      `${envVariable('REACT_APP_API_URL')}/partners/${partnerId}/conventions/product/${productId}`,
      {}
    )
      .then(data => {
        if (data && data[0]) {
          return data[0]
        }

        return null
      })
      .catch(response => {
        return null
      })
  }

  public async getInvestorList(partnerId: string): Promise<any> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/partners/${partnerId}/accounts`, {})
      .then(data => {
        if (data) {
          return data
        }

        return null
      })
      .catch(response => {
        return null
      })
  }

  public async getMovementLinkedToPartner(
    partnerId: string,
    investorList: string[],
    itemPerPage: number,
    page: number
  ): Promise<any> {
    return Caller.executeGet(
      `${envVariable('REACT_APP_API_URL')}/wallet/movement/${partnerId}/accounts/${investorList.join(',')}?params[nb_element_per_page]=${itemPerPage}&params[page]=${page}`,
      {}
    )
      .then(data => {
        if (data) {
          return data
        }

        return null
      })
      .catch(response => {
        return null
      })
  }
  public async getAllAccountMovementLinkedToPartner(
    partnerId: string,
    itemPerPage: number,
    page: number
  ): Promise<any> {
    return Caller.executeGet(
      `${envVariable('REACT_APP_API_URL')}/wallet/movement/${partnerId}/all-accounts?params[nb_element_per_page]=${itemPerPage}&params[page]=${page} `,
      {}
    )
      .then(data => {
        if (data) {
          return data
        }

        return null
      })
      .catch(response => {
        return null
      })
  }
  public async getTransferHistoryLinkedToPartner(partnerId: string, itemPerPage: number, page: number): Promise<any> {
    return Caller.executeGet(
      `${envVariable('REACT_APP_API_URL')}/partners/${partnerId}/transfer?params[nb_element_per_page]=${itemPerPage}&params[page]=${page}`,
      {}
    )
      .then(data => {
        if (data) {
          return data
        }

        return null
      })
      .catch(response => {
        return null
      })
  }
  public async postTransfer(partnerId: string, transferObj: TransferObjectSubmit): Promise<any> {
    return Caller.executePost(`${envVariable('REACT_APP_API_URL')}/partners/${partnerId}/transfer`, transferObj)
      .then(data => {
        if (data) {
          return data
        }

        return null
      })
      .catch(response => {
        return null
      })
  }
  public async getTransferHistoryDetails(
    partnerId: string,
    transferId: string,
    itemPerPage: number,
    page: number
  ): Promise<any> {
    return Caller.executeGet(
      `${envVariable('REACT_APP_API_URL')}/partners/${partnerId}/transfer/${transferId}?params[nb_element_per_page]=${itemPerPage}&params[page]=${page}`,
      {}
    )
      .then(data => {
        if (data) {
          return data
        }

        return null
      })
      .catch(response => {
        return null
      })
  }

  public async getTransferHistoryDetailsExport(partnerId: string, transfertId: string): Promise<string | null> {
    return Caller.executeGet(
      `${envVariable('REACT_APP_API_URL')}/partners/${partnerId}/transfer/${transfertId}/extract`,
      {}
    )
      .then(data => {
        if (data && data.content) {
          return data.content
        }

        return null
      })
      .catch(response => {
        return null
      })
  }

  public async getConvention(partnerId: string, conventionId: string): Promise<ScaleResponseDetail | null> {
    return Caller.executeGet(
      `${envVariable('REACT_APP_API_URL')}/partners/${partnerId}/conventions/${conventionId}`,
      {}
    ).then(data => {
      if (data) {
        return data
      }

      return null
    })
  }

  public async getConventionList(partnerId: string): Promise<any | null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/partners/${partnerId}/conventions`, {}).then(data => {
      if (data) {
        return data
      }

      return null
    })
  }

  public async addConvention(convention: any, partnerId?: string): Promise<any | null> {
    return Caller.executePost(`${envVariable('REACT_APP_API_URL')}/partners/${partnerId}/conventions`, {
      // id: convention.id,
      enabled: true,
      code: convention.code,
      date: convention.date ? convention.date : null,
      start: convention.dateStart,
      end: convention.dateEnd,
      particularite: convention.particularite,
      product: convention.product.id,
      // acronym: (convention.acronym !== null && convention.acronym !== "" && convention.acronym !== undefined) ? convention.acronym : convention.product?.generalInformation?.acronyme,
      scaleItems: convention.scaleItems,
      derogatory: {
        date: convention.derogatory.date,
        items:
          convention.derogatory && convention.derogatory.items.length
            ? convention.derogatory.items.map((item: ScaleDerogatory) => {
                return {
                  category: item.category,
                  value: item.value,
                  approbationAt: item.approbationAt,
                  applicationAt: item.applicationAt,
                  start: item.start,
                  end: item.end,
                  derogatorySelected: item.derogatorySelected,
                }
              })
            : [],
      },
      scCommissionEarned: convention.scCommissionEarned,
      scCommissionEarnedPercent: convention.scCommissionEarnedPercent,
      scCommissionNotEarned: convention.scCommissionNotEarned,
      scCommissionNotEarnedPercent: convention.scCommissionNotEarnedPercent,
    }).then(data => {
      if (data) {
        return data
      }

      return null
    })
  }

  public async updateConvention(convention: any, partnerId?: string, conventionId?: string): Promise<any | null> {
    return Caller.executePut(`${envVariable('REACT_APP_API_URL')}/partners/${partnerId}/conventions/${conventionId}`, {
      // id: convention.id,
      enabled: true,
      code: convention.code,
      date: convention.date ? convention.date : null,
      start: convention.dateStart,
      end: convention.dateEnd,
      particularite: convention.particularite,
      product: convention.product.id,
      // acronym: (convention.acronym !== null && convention.acronym !== "" && convention.acronym !== undefined) ? convention.acronym : convention.product?.generalInformation?.acronyme,
      scaleItems: convention.scaleItems,
      derogatory:
        convention.derogatory && convention.derogatory.items.length
          ? {
              date: convention.derogatory.date,
              items: convention.derogatory.items.map((item: ScaleDerogatory) => {
                return {
                  id: item.id,
                  category: item.category,
                  value: item.value,
                  approbationAt: item.approbationAt,
                  applicationAt: item.applicationAt,
                  start: item.start,
                  end: item.end,
                  derogatorySelected: item.derogatorySelected,
                }
              }),
            }
          : {},
      scCommissionEarned: convention.scCommissionEarned,
      scCommissionEarnedPercent: convention.scCommissionEarnedPercent,
      scCommissionNotEarned: convention.scCommissionNotEarned,
      scCommissionNotEarnedPercent: convention.scCommissionNotEarnedPercent,
    }).then(data => {
      if (data) {
        return data
      }

      return null
    })
  }
}
