import dayjs from 'dayjs'
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import { FormProvider, SubmitHandler, useForm, useFormState } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
// Notification lib
import 'react-toastify/dist/ReactToastify.min.css'
import ReactTooltip from 'react-tooltip'
import { v4 as uuidV4 } from 'uuid'
import iconAdd from '../../../../../../assets/images/icons/add.svg'
import iconSearch from '../../../../../../assets/images/icons/datalist-search.svg'
import eyeIcon from '../../../../../../assets/images/icons/eye.svg'
import '../../../../../../assets/styles/page/_movement.scss'
import { DocumentInterface, DocumentType } from '../../../../../../domain/Document/DocumentInterface'
import { ActionListInterface } from '../../../../../../domain/Movement/Action/Action'
import { BankInformationFormInterface } from '../../../../../../domain/Movement/BankInformation'
import { RedemptionWithdrawal } from '../../../../../../domain/Movement/RedemptionWithdrawal'
import { Investor } from '../../../../../../domain/PreSubscription/Investor'
import { NetAssetValueItem } from '../../../../../../domain/Product/NetAssetValueItem'
import { ProductInterface } from '../../../../../../domain/Product/Product'
import { BankInformation } from '../../../../../../domain/Prospect/BankInformation'
import { PersonInterface } from '../../../../../../domain/Prospect/Person'
import { ReferentielInterface } from '../../../../../../domain/Referentiel/ReferentielInterface'
import { ReferentielItemInterface } from '../../../../../../domain/Referentiel/ReferentielItemInterface'
import DocumentGateway from '../../../../../../gateway/Movement/RedemptionWithrawal/Document/DocumentGateway'
import RedemptionWithdrawalGateway from '../../../../../../gateway/Movement/RedemptionWithrawal/RedemptionWithdrawalGateway'
import ProductGateway from '../../../../../../gateway/Product/ProductGateway'
import {
  setOpenChoiceInvestorEvent,
  setOpenChoicePartnerEvent,
  setOpenChoiceSliceToGiveUpEvent,
} from '../../../../../store/component/event'
import {
  reloadMovement,
  setInvestorOwner,
  setInvestorUsufructuary,
  setPartner,
} from '../../../../../store/component/movement'
import { useAppDispatch, useAppSelector } from '../../../../../store/hook'
import BankInformationUtils from '../../../../util/BankInformationUtils'
import CKEditorUtil from '../../../../util/CKEditor'
import DocumentUtils from '../../../../util/DocumentUtils'
import List from '../../../../util/List'
import { usePrompt } from '../../../../util/Navigation'
import ProspectUtils from '../../../../util/ProspectUtils'
import { setReadOnlyFormElements } from '../../../../util/setReadOnlyFormElements'
import { toastError, toastSuccess } from '../../../../util/Toast'
import AlertInfo from '../../../Alert/AlertInfo/AlertInfo'
import FormErrorInfoDropdown from '../../../Alert/FormErrorInfoDropdown/FormErrorInfoDropdown'
import CommentBlock from '../../../Block/CommentBlock'
import FooterBlock from '../../../Block/FooterBlock'
import NoteBlock from '../../../Block/NoteBlock'
import ListToSell from '../../../Datalist/Parts/ListToSell'
import ProductDalalist from '../../../Datalist/Product/ProductDalalist'
import Checkbox from '../../../Elements/Checkbox'
import Input from '../../../Elements/Input'
import InputDate from '../../../Elements/InputDate'
import InputNumber from '../../../Elements/InputNumber'
import SelectCustom from '../../../Elements/Select'
import ConfirmationModal from '../../../Modal/ConfirmationModal'
import MovementTag from '../../../Tag/MovementTag/MovementTag'
import ActionReason from '../../../Transaction/ActionReason'
import Status from '../../../Transaction/Status'
import ActionsForm from '../../Action/ActionsForm'
import BankInformationBlock from '../../Blocks/Movement/BankInformation'
import DocumentForm from '../../Document/Form'
import RecapForm from '../../Recap/RecapForm'

interface IFormInput {
  generalInformation: {
    product: {
      id: string | null
      label: string | null
      value: string | null
    }
    movementNumber: string | null
    propertyType: string | null
    administrator: string | null
    possessionMode: string | null
    subscribedAt: string | null
    horodatage: string | null
    updatedAt: string | null
    lastUpdateUser: string | null
  }
  investor: {
    first: {
      id: string | null
      identity: string | null
      partnerNumber: string
      partnerSocialReason: string
      platformNetworkNumber: string | null
      platformNetworkLastName: string | null
      bankInformation: BankInformation
      partnerType: string | null
    }
    second: {
      id: string | null
      identity: string | null
      partnerNumber: string
      partnerSocialReason: string
      platformNetworkNumber: string | null
      platformNetworkLastName: string | null
      bankInformation: BankInformation
      partnerType: string | null
    }
  }
  withdrawalRequest: {
    typeOfMovement: string | null
    executedAt: string | null
    shareCount: number | null
    selectAllUnit: boolean
    choiceUnitNumbers: boolean
    sharePrice: number | null
    dateVL: string | null
    penalties: number | null
    fixedFee: number | null
    amountSurplus: number | null
    amountOfWithdrawal: number | null
    investor: {
      amountToBePaid: number | null
    }
    organization: {
      amountToBePaid: number | null
    }
  }
  note: string
  comment: string

  documents: {
    [key: string]: {
      [key: string]: {
        [key: string]: {
          date: string
          updatedAt: string
        }
      }
    }
  }

  bankInformations: BankInformationFormInterface
}

type Props = {
  movement: RedemptionWithdrawal
  isLectureMode: boolean
}

const PRODUCT_TYPE_CAL_VL = ['sc', 'fia', 'other']

const Form: FunctionComponent<Props> = ({ movement, isLectureMode }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const referential: ReferentielInterface | null = useAppSelector(({ referential }) => referential.referential)
  const partners = useAppSelector(state => state.movement.partners)
  const investors = useAppSelector(state => state.movement.investors)
  const shareSlices = useAppSelector(state => state.slice.sliceToTransaction)
  const shareSlicesFreeze = useAppSelector(state => state.slice.sliceToTransactionFreeze)
  const [product, setProduct] = useState<ProductInterface | null>(null)
  const [decimalStep, setDecimalStep] = useState<number>(8)
  const [documentArray, setDocumentArray] = useState<DocumentType>({})
  const openUploadForm = useAppSelector(state => state.event.openUploadForm)
  const [uuidRefresh, setUuidRefresh] = useState<string>('')
  const [actions, setActions] = useState<ActionListInterface | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [formError, setFormError] = useState<any>(null)
  const [ownerLink, setOwnerLink] = useState<string | null>(null)
  const [partnerLink, setPartnerLink] = useState<string | null>(null)
  const [usuLink, setUsuLink] = useState<string | null>(null)
  const [secondPartnerLink, setSecondPartnerLink] = useState<string | null>(null)
  const documentUtils = new DocumentUtils()
  const prospectUtils = new ProspectUtils()
  const bankInformationUtils = new BankInformationUtils()

  useEffect(() => {
    const investor = movement.investors?.find(investor => {
      return investor?.type === 'investor' || investor?.type === 'transferor' || null
    })
    if (investor) dispatch(setInvestorOwner(investor))

    const usufructuary = movement.investors?.find(investor => {
      return investor?.type === 'usufruct' || null
    })
    if (usufructuary) dispatch(setInvestorUsufructuary(usufructuary))

    if (movement && movement.investors) {
      movement.investors.map(investor => {
        investor.bankInformations?.map(bankI => {
          if (investor.prospect) {
            setValue(`bankInformations.${investor.prospect.prospectCode}.${bankI.type}`, {
              id: bankI.id,
              bankInformationSelected: bankI.bankInformation?.id ?? null,
              bankInformation: {
                id: bankI.bankInformation?.id ?? null,
                label: bankI.bankInformation?.label ?? null,
                bank: bankI.bankInformation?.bank ?? null,
                iban: bankI.bankInformation?.iban ?? null,
                swift: bankI.bankInformation?.swift ?? null,
              },
              directDebitMandateAccepted: bankI.directDebitMandateAccepted,
              rumCode: bankI.rumCode,
              rumStatus: bankI.rumStatus,
            })
          }
        })
      })
    }

    return () => {
      dispatch(setPartner({ partner: null, key: 'reset' }))
    }
  }, [movement])

  useEffect(() => {
    new DocumentGateway(movement.id, null).getFiles().then(response => {
      if (response) {
        setDocumentArray(response)
      }
    })
  }, [openUploadForm])

  useEffect(() => {
    if (movement && movement.investors) {
      movement.investors.map(investor => {
        if (investor.prospect && investor.prospect.persons) {
          investor.prospect.persons.map((person: PersonInterface) => {
            if (person.id && documentArray[person.id]) {
              documentArray[person.id].map((doc: DocumentInterface) => {
                setValue(`documents.${investor?.prospect?.prospectCode}.${person.id}.${doc.type}.date`, doc.date)
                setValue(
                  `documents.${investor?.prospect?.prospectCode}.${person.id}.${doc.type}.updatedAt`,
                  doc.updatedAt
                )
              })
            }
          })
        }
      })
    }
  }, [documentArray])

  useEffect(() => {
    if (
      investors.owner &&
      investors.owner.prospect &&
      investors.owner.prospect.persons &&
      investors.owner.prospect.persons.length > 0
    ) {
      if (movement && movement.investors) {
        movement.investors.map((investor, index) => {
          if (investor.id === investors?.owner?.id && movement && movement.investors && movement.investors[index]) {
            movement.investors[index] = investors.owner
          }
        })
      }

      setOwnerLink(investors.owner.prospect.id)
      setValue('investor.first.id', investors.owner.prospect.prospectCode)
      setValue('investor.first.identity', prospectUtils.formatProspectPersonIdentity(investors.owner.prospect))
      setValue('investor.first.platformNetworkNumber', investors.owner.prospect.partner?.platform ?? '')
      setValue('investor.first.platformNetworkLastName', investors.owner.prospect.partner?.network ?? '')
    }
  }, [investors.owner])

  useEffect(() => {
    if (
      investors.usufructuary &&
      investors.usufructuary.prospect &&
      investors.usufructuary.prospect.persons &&
      investors.usufructuary.prospect.persons.length > 0
    ) {
      if (movement && movement.investors) {
        movement.investors.map((investor, index) => {
          if (
            investor.id === investors?.usufructuary?.id &&
            movement &&
            movement.investors &&
            movement.investors[index]
          ) {
            movement.investors[index] = investors.usufructuary
          }
        })
      }

      setUsuLink(investors.usufructuary.prospect.id)
      setValue('investor.second.id', investors.usufructuary.prospect.prospectCode)
      setValue('investor.second.identity', prospectUtils.formatProspectPersonIdentity(investors.usufructuary.prospect))
      setValue('investor.second.platformNetworkNumber', investors.usufructuary.prospect.partner?.platform ?? '')
      setValue('investor.second.platformNetworkLastName', investors.usufructuary.prospect.partner?.network ?? '')
    }
  }, [investors.usufructuary])

  const methods = useForm<IFormInput>({
    defaultValues: {
      generalInformation: {
        product: movement
          ? {
              id: movement?.product?.id,
              label: movement?.product?.label,
              value: movement?.product?.id,
            }
          : { id: null, label: null, value: null },
        movementNumber: movement?.transactionCode !== null ? movement?.transactionCode : '',
        propertyType: movement?.propertyType,
        administrator: movement.user ? `${movement.user.lastname} ${movement.user.firstname}` : '',
        possessionMode: movement?.possessionMode,
        subscribedAt: movement?.subscribedAt,
        horodatage: movement?.horodatage,
        updatedAt: movement?.updatedAt,
        lastUpdateUser: movement?.lastUpdateUser
          ? `${movement?.lastUpdateUser.lastname} ${movement?.lastUpdateUser.firstname}`
          : '',
      },
      investor: {
        first: {
          id: null,
          identity: null,
          partnerNumber: '',
          partnerSocialReason: '',
          platformNetworkNumber: null,
          platformNetworkLastName: null,
        },
        second: {
          id: null,
          identity: null,
          partnerNumber: '',
          partnerSocialReason: '',
          platformNetworkNumber: null,
          platformNetworkLastName: null,
        },
      },
      withdrawalRequest: {
        selectAllUnit: movement?.allShares,
        executedAt: movement?.transactionExecutionAt,
        shareCount: movement?.shareCount,
        sharePrice: movement?.sharePrice,
        dateVL: movement?.valueDate,
        penalties: movement?.penalty ? movement.penalty : 0,
        fixedFee: movement?.flatFee,
        amountSurplus: movement?.capitalGain,
        amountOfWithdrawal: movement?.withdrawalAmount,
        investor: {
          amountToBePaid: movement?.investorBank?.amount,
        },
        organization: {
          amountToBePaid: movement?.lenderBank?.amount,
        },
        typeOfMovement: movement?.subType,
      },
    },
  })
  const { register, control, handleSubmit, reset, watch, setValue, getValues, setError } = methods

  const watchProduct = watch('generalInformation.product')
  const watchShareCount = watch('withdrawalRequest.shareCount')
  const watchSharePrice = watch('withdrawalRequest.sharePrice')
  const watchPenalties = watch('withdrawalRequest.penalties', 0)
  const watchFixedFee = watch('withdrawalRequest.fixedFee', 0)
  const watchAmountSurplus = watch('withdrawalRequest.amountSurplus', 0)
  const watchAmountOfWithdrawal = watch('withdrawalRequest.amountOfWithdrawal', 0)
  const watchChoicePartSelectAll = watch('withdrawalRequest.selectAllUnit')
  const watchChoicePart = watch('withdrawalRequest.choiceUnitNumbers')
  const watchPropertyType = watch('generalInformation.propertyType')
  const watchDateVL = watch('withdrawalRequest.dateVL')
  const calcAmountOfWithdrawalVL = (
    sharePrice: number | null,
    shareCount: number | null,
    penalties: number | null,
    fixedFee: number | null,
    amountSurplus: number | null
  ) => {
    return +(sharePrice || 0) * +(shareCount || 1)
  }
  const calcAmountOfWithdrawal = (
    sharePrice: number | null,
    shareCount: number | null,
    penalties: number | null,
    fixedFee: number | null,
    amountSurplus: number | null
  ) => {
    const withdrawalBasicPrice = (sharePrice || 1) * (shareCount || 1)
    const withdrawalMinusPrice = +(penalties || 0) + +(fixedFee || 0) + +(amountSurplus || 0)
    return withdrawalBasicPrice - withdrawalMinusPrice
  }

  const setValuesWithdrawalVL = (
    shareCount: number | null,
    penalties: number | null,
    fixedFee: number | null,
    amountSurplus: number | null,
    amountOfWithdrawal: number | null
  ) => {
    setValue('withdrawalRequest.shareCount', shareCount)
    setValue('withdrawalRequest.penalties', penalties)
    setValue('withdrawalRequest.fixedFee', fixedFee)
    setValue('withdrawalRequest.amountSurplus', amountSurplus)
    setValue('withdrawalRequest.amountOfWithdrawal', amountOfWithdrawal)
    setValue('withdrawalRequest.investor.amountToBePaid', (amountOfWithdrawal || 0) - (penalties || 0))
    setValue('withdrawalRequest.organization.amountToBePaid', 0)
  }

  const setValuesWithdrawal = (
    shareCount: number | null,
    penalties: number | null,
    fixedFee: number | null,
    amountSurplus: number | null,
    amountOfWithdrawal: number | null
  ) => {
    setValue('withdrawalRequest.shareCount', shareCount)
    setValue('withdrawalRequest.penalties', penalties)
    setValue('withdrawalRequest.fixedFee', fixedFee)
    setValue('withdrawalRequest.amountSurplus', amountSurplus)
    setValue('withdrawalRequest.amountOfWithdrawal', amountOfWithdrawal)
    setValue('withdrawalRequest.investor.amountToBePaid', amountOfWithdrawal)
    setValue('withdrawalRequest.organization.amountToBePaid', 0)
  }

  const isVL = useMemo(() => {
    return !!product?.productType?.slug && PRODUCT_TYPE_CAL_VL.includes(product.productType.slug)
  }, [product])

  const netAssetValueItems = useMemo(() => {
    if (product && product?.netAssetValue?.items) {
      return product.netAssetValue.items.sort((a, b) =>
        dayjs(a.period.start, 'DD/MM/YYYY').isAfter(dayjs(b.period.start, 'DD/MM/YYYY'))
          ? 1
          : dayjs(b.period.start, 'DD/MM/YYYY').isAfter(dayjs(a.period.start, 'DD/MM/YYYY'))
            ? -1
            : 0
      )
    }

    return [] as NetAssetValueItem[]
  }, [product])

  const penalty = useMemo(() => {
    if (product && product?.withdraw?.cost) {
      return Number.parseFloat(product.withdraw.cost)
    }

    return 0
  }, [product])

  const handleInputDateVL = (value: string) => {
    if (!isVL) return

    const shareCount = watchShareCount || 0
    const dateVL = dayjs(value, 'DD/MM/YYYY')
    const netAssetValueItem = netAssetValueItems?.find(item => {
      const startDate = dayjs(item.period.start, 'DD/MM/YYYY')
      const endDate = dayjs(item.period.end, 'DD/MM/YYYY')

      return (
        dateVL.isSame(startDate) || dateVL.isSame(endDate) || (dateVL.isAfter(startDate) && dateVL.isBefore(endDate))
      )
    })

    if (!netAssetValueItem) {
      setValue('withdrawalRequest.dateVL', null)
      setValue('withdrawalRequest.sharePrice', 0)
      setValuesWithdrawalVL(shareCount, 0, watchFixedFee, watchAmountSurplus, 0)
      toastError(t('movement.redemption-withdrawal.notify.select-date-vl-error'))
      return
    }

    setValue('withdrawalRequest.sharePrice', netAssetValueItem?.value || 0)

    const sharePrice = netAssetValueItem?.value || 0
    const penalties = (shareCount * sharePrice * penalty) / 100
    const amountOfWithdrawal = calcAmountOfWithdrawalVL(
      sharePrice,
      shareCount,
      penalties,
      watchFixedFee,
      watchAmountSurplus
    )

    setValuesWithdrawalVL(shareCount, penalties, watchFixedFee, watchAmountSurplus, amountOfWithdrawal)
  }

  const handleInputShareCount = (value: string) => {
    const shareCount = value ? parseFloat(value) : 0
    const sharePrice = watchSharePrice || 0
    const penalties = (shareCount * sharePrice * penalty) / 100
    if (isVL) {
      const amountOfWithdrawal = calcAmountOfWithdrawalVL(
        sharePrice,
        shareCount,
        penalties,
        watchFixedFee,
        watchAmountSurplus
      )
      setValuesWithdrawalVL(shareCount, penalties, watchFixedFee, watchAmountSurplus, amountOfWithdrawal)
      return
    }
    setValue('withdrawalRequest.penalties', penalties)
    const amountOfWithdrawal = calcAmountOfWithdrawal(
      watchSharePrice,
      shareCount,
      penalties,
      watchFixedFee,
      watchAmountSurplus
    )
    setValuesWithdrawal(shareCount, penalties, watchFixedFee, watchAmountSurplus, amountOfWithdrawal)
  }

  const handleInputPenalties = (value: string) => {
    const penalty = value ? parseFloat(value) : 0
    const amountOfWithdrawal = calcAmountOfWithdrawal(
      watchSharePrice,
      watchShareCount,
      penalty,
      watchFixedFee,
      watchAmountSurplus
    )
    setValuesWithdrawal(watchShareCount, penalty, watchFixedFee, watchAmountSurplus, amountOfWithdrawal)
  }

  const handleInputFixedFee = (value: string) => {
    const fixedFee = value ? parseFloat(value) : 0
    const amountOfWithdrawal = calcAmountOfWithdrawal(
      watchSharePrice,
      watchShareCount,
      watchPenalties,
      fixedFee,
      watchAmountSurplus
    )
    setValuesWithdrawal(watchShareCount, watchPenalties, fixedFee, watchAmountSurplus, amountOfWithdrawal)
  }

  const handleInputAmountSurplus = (value: string) => {
    const amountSurplus = value ? parseFloat(value) : 0
    const amountOfWithdrawal = calcAmountOfWithdrawal(
      watchSharePrice,
      watchShareCount,
      watchPenalties,
      watchFixedFee,
      amountSurplus
    )
    setValuesWithdrawal(watchShareCount, watchPenalties, watchFixedFee, amountSurplus, amountOfWithdrawal)
  }

  const handleInputInvestorAmountToBePaid = (value: string) => {
    const investorAmountToBePaid = value ? parseFloat(value) : 0
    setValue('withdrawalRequest.organization.amountToBePaid', (watchAmountOfWithdrawal || 0) - investorAmountToBePaid)
  }

  const handleInputOrganizationAmountToBePaid = (value: string) => {
    const organizationAmountToBePaid = value ? parseFloat(value) : 0
    setValue('withdrawalRequest.investor.amountToBePaid', (watchAmountOfWithdrawal || 0) - organizationAmountToBePaid)
  }

  const onSubmit: SubmitHandler<IFormInput> = data => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return <ConfirmationModal onConfirm={() => onConfirm(data)} onClose={onClose} />
      },
    })
  }

  const onConfirm = (data: any) => {
    reset(data)
    movement.propertyType = data.generalInformation.propertyType
    movement.possessionMode = data.generalInformation.possessionMode
    movement.subscribedAt = data.generalInformation.subscribedAt
    movement.product = product
    movement.horodatage = data.generalInformation.horodatage
    movement.comment = data.comment
    movement.note = data.note

    movement.allShares = data.withdrawalRequest.selectAllUnit
    movement.executedAt = data.withdrawalRequest.executedAt
    movement.shareCount = data.withdrawalRequest.shareCount
    movement.sharePrice = data.withdrawalRequest.sharePrice
    movement.valueDate = data.withdrawalRequest.dateVL
    movement.penalty = data.withdrawalRequest.penalties
    movement.flatFee = data.withdrawalRequest.fixedFee
    movement.capitalGain = data.withdrawalRequest.amountSurplus
    movement.withdrawalAmount = data.withdrawalRequest.amountOfWithdrawal
    movement.subType = data.withdrawalRequest.typeOfMovement
    movement.transactionExecutionAt = data.withdrawalRequest.executedAt
    movement.investorBank = {
      amount: data.withdrawalRequest.investor.amountToBePaid,
      bank: data.withdrawalRequest.investor.nameOfTheBank,
      iban: data.withdrawalRequest.investor.iban,
      swift: data.withdrawalRequest.investor.swift,
    }
    movement.lenderBank = {
      amount: data.withdrawalRequest.organization.amountToBePaid,
      bank: data.withdrawalRequest.organization.nameOfTheBank,
      iban: data.withdrawalRequest.organization.iban,
      swift: data.withdrawalRequest.organization.swift,
    }

    movement.slices = []
    if (shareSlices) {
      shareSlices.map(item => {
        movement.slices.push(item)
      })
    }

    const owner = movement.investors?.find(investor => {
      return investor?.type === 'transferor' || null
    })

    const usufructuary = movement.investors?.find(investor => {
      return investor?.type === 'usufruct' || null
    })

    const movementInvestors = []

    if (investors && investors.owner) {
      let partner = undefined
      if (partners && partners.owner) {
        partner = partners.owner
      } else if (investors.owner?.partner) {
        partner = investors.owner.partner
      } else if (!investors.owner.partner && owner) {
        partner = owner.partner
      }
      const investor = new Investor(
        investors.owner.id,
        'transferor',
        investors.owner.prospect,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        investors.owner.prospect &&
        investors.owner.prospect.prospectCode &&
        data.bankInformations &&
        data.bankInformations[investors.owner.prospect.prospectCode]
          ? bankInformationUtils.getBankInformationsForProspect(
              data.bankInformations[investors.owner.prospect.prospectCode]
            )
          : [],
        data.investor.first.partnerType,
        investors.owner.prospect &&
        investors.owner.prospect.prospectCode &&
        data.documents &&
        data.documents[investors.owner.prospect.prospectCode]
          ? documentUtils.getDocumentsForProspect(data.documents[investors.owner.prospect.prospectCode], documentArray)
          : [],
        partner
      )

      movementInvestors.push(investor)
    } else if (owner) {
      owner.partner = partners?.owner ?? undefined
      movementInvestors.push(owner)
    }

    if (investors && investors.usufructuary) {
      let partner = undefined
      if (partners && partners.usufructuary) {
        partner = partners.usufructuary
      } else if (investors.usufructuary?.partner) {
        partner = investors.usufructuary.partner
      } else if (!investors.usufructuary.partner && usufructuary) {
        partner = usufructuary.partner
      }
      const usufructuaryInvestor = new Investor(
        investors.usufructuary.id,
        'usufruct',
        investors.usufructuary.prospect,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        investors.usufructuary.prospect &&
        investors.usufructuary.prospect.prospectCode &&
        data.bankInformations &&
        data.bankInformations[investors.usufructuary.prospect.prospectCode]
          ? bankInformationUtils.getBankInformationsForProspect(
              data.bankInformations[investors.usufructuary.prospect.prospectCode]
            )
          : [],
        data.investor.second.partnerType,
        investors.usufructuary.prospect &&
        investors.usufructuary.prospect.prospectCode &&
        data.documents &&
        data.documents[investors.usufructuary.prospect.prospectCode]
          ? documentUtils.getDocumentsForProspect(
              data.documents[investors.usufructuary.prospect.prospectCode],
              documentArray
            )
          : [],
        partner
      )

      movementInvestors.push(usufructuaryInvestor)
    } else if (usufructuary) {
      usufructuary.partner = partners?.usufructuary ?? undefined
      movementInvestors.push(usufructuary)
    }

    movement.investors = movementInvestors

    if (null !== movement.id) {
      new RedemptionWithdrawalGateway()
        .update(movement)
        .then(response => {
          if (null !== response) {
            movement = response
            setUuidRefresh(uuidV4())
            toastSuccess(t('movement.redemption-withdrawal.notify.update-success'))
            dispatch(reloadMovement())
          } else {
            toastError(t('movement.redemption-withdrawal.notify.update-error'))
          }
        })
        .catch(xhr => {
          toastError(xhr?.data?.errors ?? t('movement.redemption-withdrawal.notify.update-error'))
        })
    } else {
      setIsLoading(true)
      new RedemptionWithdrawalGateway()
        .create(movement)
        .then(response => {
          if (null !== response) {
            toastSuccess(t('movement.redemption-withdrawal.notify.add-success'))
            navigate(`/${t('url.movements.edit-redemption-withdrawal')}/${response.id}`)
          } else {
            toastError(t('movement.redemption-withdrawal.notify.add-error'))
          }
        })
        .catch(xhr => {
          toastError(xhr?.data?.errors ?? t('movement.redemption-withdrawal.notify.update-error'))
        })

      setIsLoading(false)
    }
  }

  const { isDirty } = useFormState({
    control,
  })
  usePrompt(isDirty, handleSubmit(onConfirm))

  useEffect(() => {
    if (referential) {
      const usufructuary = movement.investors?.find(investor => {
        return investor?.type === 'usufruct' || null
      })
      let platformTypeId = ''
      let networkTypeId = ''
      let platformType
      let networkType

      if (usufructuary) {
        platformTypeId = (partners.usufructuary?.platformType ?? usufructuary.partner?.platformType) || ''
        networkTypeId = (partners.usufructuary?.networkType ?? usufructuary.partner?.networkType) || ''

        platformType = referential.partner?.platform.find(el => el.value === platformTypeId)
        networkType = referential?.partner?.network.find(el => el.value === networkTypeId)

        setSecondPartnerLink(partners.usufructuary?.id || usufructuary.partner?.id || null)
        setValue(
          'investor.second.partnerNumber',
          partners.usufructuary?.partnerCode || usufructuary.partner?.partnerCode || ''
        )
        setValue(
          'investor.second.partnerSocialReason',
          partners.usufructuary?.socialReason || usufructuary.partner?.socialReason || ''
        )
        setValue('investor.second.platformNetworkNumber', platformType?.label || '')
        setValue('investor.second.platformNetworkLastName', networkType?.label || '')
      } else {
        platformTypeId = partners.usufructuary?.platformType || ''
        networkTypeId = partners.usufructuary?.networkType || ''

        platformType = referential?.partner?.platform.find(el => el.value === platformTypeId)
        networkType = referential?.partner?.network.find(el => el.value === networkTypeId)

        setSecondPartnerLink(partners.usufructuary?.id || null)
        setValue('investor.second.partnerNumber', partners.usufructuary?.partnerCode || '')
        setValue('investor.second.partnerSocialReason', partners.usufructuary?.socialReason || '')
        setValue('investor.second.platformNetworkNumber', platformType?.label || '')
        setValue('investor.second.platformNetworkLastName', networkType?.label || '')
      }

      const owner = movement.investors?.find(investor => {
        return investor?.type === 'transferor' || investor?.type === 'investor' || null
      })

      if (owner) {
        platformTypeId = (partners.owner?.platformType ?? owner.partner?.platformType) || ''
        networkTypeId = (partners.owner?.networkType ?? owner.partner?.networkType) || ''

        platformType = referential.partner?.platform.find(el => el.value === platformTypeId)
        networkType = referential?.partner?.network.find(el => el.value === networkTypeId)

        setPartnerLink(partners.owner?.id || owner.partner?.id || null)
        setValue('investor.first.partnerNumber', partners.owner?.partnerCode || owner.partner?.partnerCode || '')
        setValue(
          'investor.first.partnerSocialReason',
          partners.owner?.socialReason || owner.partner?.socialReason || ''
        )
        setValue('investor.first.platformNetworkNumber', platformType?.label || '')
        setValue('investor.first.platformNetworkLastName', networkType?.label || '')
      } else {
        platformTypeId = partners.owner?.platformType || ''
        networkTypeId = partners.owner?.networkType || ''

        platformType = referential?.partner?.platform.find(el => el.value === platformTypeId)
        networkType = referential?.partner?.network.find(el => el.value === networkTypeId)

        setPartnerLink(partners.owner?.id || null)
        setValue('investor.first.partnerNumber', partners.owner?.partnerCode || '')
        setValue('investor.first.partnerSocialReason', partners.owner?.socialReason || '')
        setValue('investor.first.platformNetworkNumber', platformType?.label || '')
        setValue('investor.first.platformNetworkLastName', networkType?.label || '')
      }

      if (
        !getValues('withdrawalRequest.typeOfMovement') &&
        referential?.wallet_withdrawal?.transaction_subtype &&
        referential.wallet_withdrawal.transaction_subtype?.length > 0
      ) {
        setValue(
          'withdrawalRequest.typeOfMovement',
          referential.wallet_withdrawal.transaction_subtype[0].value.toString()
        )
      }
    }
  }, [partners, referential, investors])

  useEffect(() => {
    if (watchProduct && watchProduct.id) {
      new ProductGateway().get(watchProduct.id).then(response => {
        const isCalVL = !!response?.productType?.slug && PRODUCT_TYPE_CAL_VL.includes(response.productType.slug)
        if (!isCalVL && response?.valorisation?.withdraw.value)
          setValue('withdrawalRequest.sharePrice', Number.parseFloat(response?.valorisation?.withdraw.value))
        setProduct(response)
        if (response?.decimalStep) {
          setDecimalStep(response?.decimalStep)
        }
      })
    }
  }, [watchProduct.id])

  useEffect(() => {
    if (movement?.id || !isVL) return
    const shareCount = watchShareCount || 0
    const sharePrice = watchSharePrice || 0
    const penalties = (shareCount * sharePrice * penalty) / 100

    const amountOfWithdrawal = calcAmountOfWithdrawalVL(
      sharePrice,
      watchShareCount,
      penalties,
      watchFixedFee,
      watchAmountSurplus
    )
    setValuesWithdrawalVL(shareCount, penalties, watchFixedFee, watchAmountSurplus, amountOfWithdrawal)
  }, [product, watchSharePrice, watchShareCount])

  useEffect(() => {
    if (watchChoicePart) {
      setValue('withdrawalRequest.selectAllUnit', false)
    }
  }, [watchChoicePart])

  useEffect(() => {
    if (watchChoicePartSelectAll) {
      setValue('withdrawalRequest.choiceUnitNumbers', false)
    }
  }, [watchChoicePartSelectAll])

  function handleClickChoicePartView() {
    if ((watchShareCount && watchShareCount > 0) || watchChoicePartSelectAll) {
      dispatch(
        setOpenChoiceSliceToGiveUpEvent({
          show: true,
          prospectId: investors?.owner?.prospect?.id,
          productId: getValues('generalInformation.product.id'),
          shareCount: watchShareCount,
          choicePartSelectAll: watchChoicePartSelectAll,
          propertyType: watchPropertyType,
          usufructId: investors?.usufructuary?.prospect?.id,
        })
      )
    } else {
      toastError(t('movement.notify.choice-share-count-before'))
    }
  }

  const blockGeneralInformation = (
    <div className={`form-bloc form--bloc--general-information`}>
      <div className='form-bloc__title'>{t('movement.redemption-withdrawal.form.general-information.title')}</div>
      <div className='form-bloc__form flex-container'>
        <ProductDalalist
          classes='col-md-6'
          id='generalInformation.product'
          name='generalInformation.product'
          label={t('movement.redemption-withdrawal.form.general-information.product-acronym')}
          labelClasses='mendatory-fild'
          control={control}
          defaultValue={getValues('generalInformation.product')}
          disabled={isLectureMode}
          filterRegistry={true}
        />
        <Input
          register={register}
          classes={'col-md-6'}
          label={t('movement.redemption-withdrawal.form.general-information.movement-number')}
          type={'text'}
          name={'generalInformation.movementNumber'}
          id={'generalInformation.movementNumber'}
          readOnly={true}
        />
        <SelectCustom
          register={register}
          classes='col-md-6'
          id='generalInformation.propertyType'
          name='generalInformation.propertyType'
          label={t('movement.redemption-withdrawal.form.general-information.nature-property')}
          options={List.filterNaturePropertyList(
            movement.status,
            product?.productType ?? null,
            referential?.wallet_withdrawal.property_type ?? []
          )}
        />
        <Input
          register={register}
          classes={'col-md-6'}
          label={t('movement.redemption-withdrawal.form.general-information.administrator')}
          type={'text'}
          name={'generalInformation.administrator'}
          id={'generalInformation.administrator'}
          readOnly={true}
        />
        <SelectCustom
          register={register}
          classes='col-md-6'
          id='generalInformation.possessionMode'
          name='generalInformation.possessionMode'
          label={t('movement.redemption-withdrawal.form.general-information.detention-mode')}
          options={List.filterDetentionModeList(
            product?.productType ?? null,
            referential?.wallet_withdrawal.possession_mode ?? []
          )}
        />
        <InputDate
          id='generalInformation.horodatage'
          label={t('movement.pre-subscription.form.general-information.timestamp-date')}
          name='generalInformation.horodatage'
          classes='col-md-6'
          register={register}
          control={control}
          readOnly={isLectureMode}
          isLectureMode={isLectureMode}
        />
        {movement.id !== null ? (
          <div className='u-txt-size-s u-txt-lightbold u-txt-italic col-md-12'>
            {t('movement.pre-subscription.form.general-information.lastUpdateUser')}{' '}
            {getValues('generalInformation.lastUpdateUser')}
            {t('movement.pre-subscription.form.general-information.updatedAt')}{' '}
            {getValues('generalInformation.updatedAt')}
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
  const blockInvestor = (
    <div className={`form-bloc form--bloc--investor`}>
      <ReactTooltip />
      <div className='form-bloc__title'>{t('movement.redemption-withdrawal.form.investor.title')}</div>
      <div className='flex-container'>
        <div className='col-md-6 form-bloc__form'>
          <div className='flex-container'>
            <div className='col-md-6'>
              <button
                type='button'
                className='button button-reset u-txt-with-icon'
                onClick={() =>
                  !isLectureMode &&
                  dispatch(
                    setOpenChoiceInvestorEvent({
                      show: true,
                      key: 'owner',
                      dataListType: 'all',
                    })
                  )
                }
              >
                <img src={iconAdd} alt='' />
                {t('movement.choice-investor-owner')}
              </button>
            </div>
            <div className='col-md-6'>
              <button
                type='button'
                className='button button-reset u-txt-with-icon'
                onClick={() => dispatch(setOpenChoicePartnerEvent({ show: true, key: 'owner' }))}
              >
                <img src={iconAdd} alt='' />
                {t('account.search-a-partner')}
              </button>
            </div>
          </div>
          <table className='table--investor'>
            <thead>
              <tr>
                <th />
                <th className='form-control__label'>{t('movement.redemption-withdrawal.form.investor.th-number')}</th>
                <th className='form-control__label'>{t('movement.pre-subscription.form.investor.th-identity')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <label htmlFor='partnerNum' className='form-control__label u-mrs'>
                    {t('movement.redemption-withdrawal.form.investor.investor-owner')}
                  </label>
                  {ownerLink && (
                    <Link
                      to={`/${t('url.customer.read-general-information')}/${ownerLink}`}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <button type='button' className='button-reset'>
                        <img src={iconSearch} alt='Loupe' />
                      </button>
                    </Link>
                  )}
                </td>
                <td>
                  <Input
                    register={register}
                    type={'text'}
                    name={'investor.first.id'}
                    id={'investor.first.id'}
                    disabled
                  />
                </td>
                <td>
                  <Input
                    register={register}
                    type={'text'}
                    name={'investor.first.identity'}
                    id={'investor.first.identity'}
                    readOnly={true}
                    data-tip={
                      investors?.owner?.prospect
                        ? prospectUtils.formatProspectPersonIdentity(investors.owner.prospect)
                        : ''
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor='partnerNum' className='form-control__label u-mrs'>
                    {t('movement.redemption-withdrawal.form.investor.investor-partner')}
                  </label>
                  {partnerLink && (
                    <Link
                      to={`/${t('url.partner.read-general-information')}/${partnerLink}`}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <button type='button' className='button-reset'>
                        <img src={iconSearch} alt='Loupe' />
                      </button>
                    </Link>
                  )}
                </td>
                <td>
                  <Input
                    register={register}
                    type={'text'}
                    name={'investor.first.partnerNumber'}
                    id={'investor.first.partnerNumber'}
                    disabled
                  />
                </td>
                <td>
                  <Input
                    register={register}
                    type={'text'}
                    name={'investor.first.partnerSocialReason'}
                    id={'investor.first.partnerSocialReason'}
                    disabled
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor='partnerNum' className='form-control__label'>
                    {t('movement.redemption-withdrawal.form.investor.platform-network')}
                  </label>
                </td>
                <td>
                  <Input
                    register={register}
                    type={'text'}
                    name={'investor.first.platformNetworkNumber'}
                    id={'investor.first.platformNetworkNumber'}
                    disabled
                  />
                </td>
                <td>
                  <Input
                    register={register}
                    type={'text'}
                    name={'investor.first.platformNetworkLastName'}
                    id={'investor.first.platformNetworkLastName'}
                    disabled
                  />
                </td>
                <td />
              </tr>
            </tbody>
          </table>
        </div>
        {watchPropertyType !== 'full_property' && (
          <div className='col-md-6 form-bloc__form'>
            <div className='flex-container'>
              <div className='col-md-6'>
                <button
                  type='button'
                  className='button button-reset u-txt-with-icon'
                  onClick={() =>
                    !isLectureMode &&
                    dispatch(
                      setOpenChoiceInvestorEvent({
                        show: true,
                        key: 'usufructuary',
                        dataListType: 'all',
                      })
                    )
                  }
                >
                  <img src={iconAdd} alt='' />
                  {t('movement.choice-investor-usufructuary')}
                </button>
              </div>
              <div className='col-md-6'>
                <button
                  type='button'
                  className='button button-reset u-txt-with-icon'
                  onClick={() => dispatch(setOpenChoicePartnerEvent({ show: true, key: 'usufructuary' }))}
                >
                  <img src={iconAdd} alt='' />
                  {t('account.search-a-partner')}
                </button>
              </div>
            </div>
            <table className='table--investor'>
              <thead>
                <tr>
                  <th />
                  <th className='form-control__label'>{t('movement.redemption-withdrawal.form.investor.th-number')}</th>
                  <th className='form-control__label'>{t('movement.pre-subscription.form.investor.th-identity')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <label htmlFor='partnerNum' className='form-control__label u-mrs'>
                      {t('movement.redemption-withdrawal.form.investor.investor-usufructuary')}
                    </label>
                    {usuLink && (
                      <Link
                        to={`/${t('url.customer.read-general-information')}/${usuLink}`}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <button type='button' className='button-reset'>
                          <img src={iconSearch} alt='Loupe' />
                        </button>
                      </Link>
                    )}
                  </td>
                  <td>
                    <Input
                      register={register}
                      type={'text'}
                      name={'investor.second.id'}
                      id={'investor.second.id'}
                      disabled
                    />
                  </td>
                  <td>
                    <Input
                      register={register}
                      type={'text'}
                      name={'investor.second.identity'}
                      id={'investor.second.identity'}
                      readOnly={true}
                      data-tip={
                        investors?.usufructuary?.prospect
                          ? prospectUtils.formatProspectPersonIdentity(investors.usufructuary.prospect)
                          : ''
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label htmlFor='partnerNum' className='form-control__label u-mrs'>
                      {t('movement.redemption-withdrawal.form.investor.usufructuary-partner')}
                    </label>
                    {secondPartnerLink && (
                      <Link
                        to={`/${t('url.partner.read-general-information')}/${secondPartnerLink}`}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <button type='button' className='button-reset'>
                          <img src={iconSearch} alt='Loupe' />
                        </button>
                      </Link>
                    )}
                  </td>
                  <td>
                    <Input
                      register={register}
                      type={'text'}
                      name={'investor.second.partnerNumber'}
                      id={'investor.second.partnerNumber'}
                      disabled
                    />
                  </td>
                  <td>
                    <Input
                      register={register}
                      type={'text'}
                      name={'investor.second.partnerSocialReason'}
                      id={'investor.second.partnerSocialReason'}
                      disabled
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label htmlFor='partnerNum' className='form-control__label'>
                      {t('movement.redemption-withdrawal.form.investor.platform-network')}
                    </label>
                  </td>
                  <td>
                    <Input
                      register={register}
                      type={'text'}
                      name={'investor.second.platformNetworkNumber'}
                      id={'investor.second.platformNetworkNumber'}
                      disabled
                    />
                  </td>
                  <td>
                    <Input
                      register={register}
                      type={'text'}
                      name={'investor.second.platformNetworkLastName'}
                      id={'investor.second.platformNetworkLastName'}
                      disabled
                    />
                  </td>
                  <td />
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  )
  const blockWithdrawalRequest = (
    <div className={`form-bloc form--bloc--withdrawal-request`}>
      <div className='form-bloc__title'>{t('movement.redemption-withdrawal.form.withdrawal-request.title')}</div>
      <div className='flex-container'>
        {/*Type du mouvement*/}
        <SelectCustom
          register={register}
          classes='col-md-6'
          id='withdrawalRequest.typeOfMovement'
          name='withdrawalRequest.typeOfMovement'
          label={t('movement.redemption-withdrawal.form.withdrawal-request.type-of-movement')}
          options={referential?.wallet_withdrawal.transaction_subtype || null}
          noChoiceOption={true}
        />
        <InputDate
          register={register}
          control={control}
          classes='col-md-6'
          type='text'
          name='withdrawalRequest.executedAt'
          id='withdrawalRequest.executedAt'
          label={t('movement.redemption-withdrawal.form.withdrawal-request.execution-date')}
        />
        {/*Nombre de parts à vendre*/}
        <InputNumber
          id={'withdrawalRequest.shareCount'}
          classes='col-md-6'
          labelClasses='mandatory-field'
          name={'withdrawalRequest.shareCount'}
          control={control}
          decimalScale={decimalStep}
          onChangeCb={handleInputShareCount}
          label={t('movement.redemption-withdrawal.form.withdrawal-request.number-of-shares-for-sale')}
          register={register}
        />
        <div className='col-md-3 choices-shares'>
          <Checkbox
            register={register}
            id='withdrawalRequest.selectAllUnit'
            name='withdrawalRequest.selectAllUnit'
            label={t('movement.redemption-withdrawal.form.withdrawal-request.shares-select-all')}
          />
          {watchChoicePartSelectAll && (
            <div className='u-pointer' onClick={() => handleClickChoicePartView()}>
              <img src={eyeIcon} alt='' />
            </div>
          )}
        </div>
        <div className='col-md-3 choices-shares'>
          <Checkbox
            register={register}
            id='withdrawalRequest.choiceUnitNumbers'
            name='withdrawalRequest.choiceUnitNumbers'
            label={t('movement.mutation.form.request.choice-of-unit-numbers')}
          />
          {watchChoicePart && (
            <div className='u-pointer' onClick={() => handleClickChoicePartView()}>
              <img src={eyeIcon} alt='' />
            </div>
          )}
        </div>
        {/*Prix de la part / VL (€)*/}
        <InputNumber
          id={'withdrawalRequest.sharePrice'}
          name={'withdrawalRequest.sharePrice'}
          classes='col-md-6'
          readOnly={true}
          control={control}
          decimalScale={decimalStep}
          register={register}
          label={t('movement.redemption-withdrawal.form.withdrawal-request.unit-price-vl')}
        />
        <InputDate
          register={register}
          control={control}
          onChangeCb={handleInputDateVL}
          classes='col-md-6'
          type='text'
          name='withdrawalRequest.dateVL'
          id='withdrawalRequest.dateVL'
          required={isVL}
          labelClasses={`${isVL ? 'mandatory-field' : ''}`}
          label={t('movement.redemption-withdrawal.form.withdrawal-request.vl-date-to-be-taken-into-account')}
        />
        {/*Pénalité (€)*/}
        <InputNumber
          id={'withdrawalRequest.penalties'}
          name={'withdrawalRequest.penalties'}
          classes='col-md-6'
          onChangeCb={handleInputPenalties}
          control={control}
          readOnly={isVL}
          decimalScale={decimalStep}
          register={register}
          label={t('movement.redemption-withdrawal.form.withdrawal-request.penalties')}
        />
        <div className='col-md-6' />
        {/*Frais forfaitaire (€)*/}
        <InputNumber
          id={'withdrawalRequest.fixedFee'}
          name={'withdrawalRequest.fixedFee'}
          classes='col-md-6'
          onChangeCb={handleInputFixedFee}
          control={control}
          decimalScale={decimalStep}
          register={register}
          label={t('movement.redemption-withdrawal.form.withdrawal-request.fixed-fee')}
        />
        <div className='col-md-6' />
        {/*Montant plus-value (€)*/}
        <InputNumber
          id={'withdrawalRequest.amountSurplus'}
          name={'withdrawalRequest.amountSurplus'}
          classes='col-md-6'
          onChangeCb={handleInputAmountSurplus}
          control={control}
          decimalScale={decimalStep}
          register={register}
          label={t('movement.redemption-withdrawal.form.withdrawal-request.amount-surplus')}
        />
        <div className='col-md-6' />
        {/*Montant du retrait (€)*/}
        <InputNumber
          id={'withdrawalRequest.amountOfWithdrawal'}
          name={'withdrawalRequest.amountOfWithdrawal'}
          classes='col-md-6'
          readOnly={true}
          control={control}
          decimalScale={decimalStep}
          register={register}
          label={t('movement.redemption-withdrawal.form.withdrawal-request.amount-of-withdrawal')}
        />
        <div className='col-md-6' />
        {/*Montant à verser investisseur (€)*/}
        <InputNumber
          id={'withdrawalRequest.investor.amountToBePaid'}
          name={'withdrawalRequest.investor.amountToBePaid'}
          classes='col-md-6'
          labelClasses='mandatory-field'
          onChangeCb={handleInputInvestorAmountToBePaid}
          control={control}
          decimalScale={decimalStep}
          register={register}
          label={t('movement.redemption-withdrawal.form.withdrawal-request.amount-to-be-paid-investor')}
        />
        {/*Montant à verser à l’organisme prêteur si nantissement (€)*/}
        <InputNumber
          id={'withdrawalRequest.organization.amountToBePaid'}
          name={'withdrawalRequest.organization.amountToBePaid'}
          classes='col-md-6'
          onChangeCb={handleInputOrganizationAmountToBePaid}
          control={control}
          decimalScale={decimalStep}
          register={register}
          label={t(
            'movement.redemption-withdrawal.form.withdrawal-request.amount-to-be-paid-to-the-lender-if-collateral'
          )}
        />
      </div>
    </div>
  )
  const blockSliceList = (
    <div className='form-bloc form--bloc--list-slices'>
      <ListToSell
        isLectureMode={isLectureMode}
        SliceArray={shareSlices}
        title={t('movement.heading-slice.state-recovery-heritage-post-transaction')}
        priceNoDependToProduct={true}
      />
    </div>
  )

  const blockSliceDetailsList = (
    <div className='form-bloc form--bloc--list-slices'>
      <ListToSell
        isLectureMode={isLectureMode}
        SliceArray={shareSlicesFreeze}
        title={t('movement.heading-slice.state-recovery-heritage-actual-transaction')}
        deleteDisabled={true}
        priceNoDependToProduct={true}
      />
    </div>
  )
  const blockListDocumentsInvestors = movement?.investors?.map(investor => {
    if (investor && investor.prospect && investor.prospect.persons) {
      return investor.prospect.persons.map(person => {
        if (movement.id && investor && investor.prospect && investor.prospect && person && person.id) {
          return (
            <>
              <div className='col-md-6'>
                <DocumentForm
                  title={`${t('movement.pre-subscription.form.list-of-documents.title')} : ${investor?.prospect?.prospectCode} - ${person.lastname} ${person.firstname}`}
                  name={`documents.${investor.prospect.prospectCode}.${person.id}`}
                  uuid={person.id}
                  documentGateway={new DocumentGateway(movement.id, investor.prospect.id)}
                  register={register}
                  control={control}
                  documentData={documentArray[person.id]}
                  isLectureMode={isLectureMode}
                />
              </div>
            </>
          )
        }
      })
    }
  })

  const measuredRef = useCallback(
    node => {
      if (node !== null && isLectureMode) {
        setReadOnlyFormElements(true, node)
      }
    },
    [isLectureMode]
  )

  const getActions = (actionArray: any) => {
    setActions(actionArray)
  }

  return (
    <>
      {referential && (
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} ref={measuredRef}>
            <Status label={'movement.state'} status={movement.status} statusList={referential.wallet.status} />
            <ActionReason
              reason={
                referential?.wallet_subscription?.cancel_reason?.find(reason => {
                  return reason.value == movement.actionReason
                })?.label ?? movement.actionReason
              }
            />
            {!movement.id && <AlertInfo text={t('movement.warning-action-possible')} classes='u-mys' />}
            {movement.id && (
              <MovementTag
                transactionId={movement.id}
                tags={movement.tags || []}
                optionsReferential={referential.transaction.tag}
                readOnly={isLectureMode}
              />
            )}
            <div className='flex-container'>
              <div className='col-md-6'>{blockGeneralInformation}</div>
              <div className='col-md-6'>
                {movement.id && (
                  <ActionsForm
                    gateway={new RedemptionWithdrawalGateway()}
                    transactionUuid={movement.id}
                    uuidRefresh={uuidRefresh}
                    callback={getActions}
                    setFormError={setFormError}
                  />
                )}
              </div>
              <FormErrorInfoDropdown errorArray={formError} />
              {actions?.invalidate.date && !actions?.confirm.date && (
                <div className='col-md-12'>
                  <CommentBlock
                    data={movement.comment}
                    onChange={(data: string) => setValue('comment', data)}
                    config={CKEditorUtil.getConfig()}
                    readonly={isLectureMode}
                  />
                </div>
              )}
              <div className='col-md-12'>{blockInvestor}</div>
              <div className='col-md-12'>{blockWithdrawalRequest}</div>
              {shareSlicesFreeze.length > 0 && <div className='col-md-12'>{blockSliceDetailsList}</div>}
              <div className='col-md-12'>{blockSliceList}</div>
              <div className='col-md-12'>
                <div className='flex-container'>
                  {movement?.investors?.map((investor, index) => {
                    if (investor && referential) {
                      return referential.prospect.bank_type.map((bankType: ReferentielItemInterface) => {
                        if (investor.prospect) {
                          return (
                            <BankInformationBlock
                              type={bankType.value.toString()}
                              key={`bankInformations.${investor.prospect.prospectCode}.${bankType.value}`}
                              name={`bankInformations.${investor.prospect.prospectCode}.${bankType.value}`}
                              title={`${t('movement.pre-subscription.form.banking-information.title')} :
                              ${bankType.label}
                              ${investor.prospect.prospectCode}`}
                              isLectureMode={isLectureMode}
                              prospect={investor.prospect}
                              product={product}
                            />
                          )
                        }
                      })
                    }
                  })}
                </div>
              </div>
              <div className='col-md-12'>
                <div className='flex-container'>{blockListDocumentsInvestors}</div>
              </div>
              {movement.status === 'subscribed' && movement.id && (
                <div className='col-md-12'>
                  <RecapForm product={product} transactionUuid={movement.id} typeMvt='withdrawal' />
                </div>
              )}
              <div className='col-md-12'>
                <NoteBlock
                  data={movement.note}
                  onChange={(data: string) => {
                    setValue('note', data)
                  }}
                  config={CKEditorUtil.getConfig()}
                  readonly={isLectureMode || ['canceled', 'subscribed'].includes(movement.status ?? 'draft')}
                />
              </div>
            </div>
            {!isLectureMode && (
              <FooterBlock disabled={!watchProduct.id || isLoading} onCancelUrl={t('url.movements.dashboard')} />
            )}
          </form>
        </FormProvider>
      )}
    </>
  )
}

export default Form
