import React, { ReactNode, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
// Notification lib
import 'react-toastify/dist/ReactToastify.min.css'
import { FilterSessionInvestorInterface } from '../../../../../domain/Distribution'
import { ReferentielInterface } from '../../../../../domain/Referentiel/ReferentielInterface'
import { setOpenDatalistFilterDistributionSessionInvestor } from '../../../../store/component/event'
import { useAppDispatch, useAppSelector } from '../../../../store/hook'
import blockScrollBody from '../../../util/BlockScroll'
import ProductDalalist from '../../Datalist/Product/ProductDalalist'
import SelectCustom from '../../Elements/Select'

type Props = {
  children: ReactNode
}

const DistributionDatalistFilter = ({ children }: Props) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const referential: ReferentielInterface | null = useAppSelector(({ referential }) => referential.referential)
  const openDatalistFilterDistributionSessionInvestor = useAppSelector(
    state => state.event.openDatalistFilterDistributionSessionInvestor
  )

  const { register, control, handleSubmit, getValues, reset } = useFormContext()

  useEffect(() => {
    blockScrollBody(openDatalistFilterDistributionSessionInvestor.show)
  }, [openDatalistFilterDistributionSessionInvestor])

  const onConfirm = (data: FilterSessionInvestorInterface) => {
    dispatch(
      setOpenDatalistFilterDistributionSessionInvestor({
        show: false,
        count: 0,
        filters: {
          keyword: '',
          product: data.product,
          term: data.term,
          periodicity: data.periodicity,
          paymentMethod: data.paymentMethod,
        },
      })
    )
  }

  const resetFilterState = () => {
    reset({
      product: null,
      term: '',
      periodicity: '',
      paymentMethod: '',
    })
  }

  return (
    <>
      {referential && (
        <form onSubmit={handleSubmit(onConfirm)} className='flex-container'>
          <ProductDalalist
            classes='col-md-6'
            id='product'
            name='product'
            label={t('distribution.form.filter.product')}
            control={control}
            defaultValue={getValues('product') || ''}
          />
          <SelectCustom
            classes='col-md-6'
            id='term'
            name='term'
            label={t('distribution.form.filter.term')}
            options={referential.product.distribution_term}
            register={register}
          />
          <SelectCustom
            classes='col-md-6'
            id='periodicity'
            name='periodicity'
            label={t('distribution.form.filter.periodicity')}
            options={referential.product.periodicity}
            register={register}
          />
          <SelectCustom
            classes='col-md-6'
            id='paymentMethod'
            name='paymentMethod'
            label={t('distribution.form.filter.payment-method')}
            options={referential.prospect.payment_method}
            register={register}
          />
          <div className='col-md-12'>
            <div className='datalist__header flex justify-end'>
              <button type='submit' className='button button--submit u-mrs'>
                {t('search.submit')}
              </button>
              <button type='reset' className='button button--white u-mrs' onClick={resetFilterState}>
                {t('search.cancel')}
              </button>
              {children}
            </div>
          </div>
        </form>
      )}
    </>
  )
}

export default DistributionDatalistFilter
