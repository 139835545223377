import dayjs from 'dayjs'
import { SessionInterface } from '../../domain/Distribution'
import { AmountDistributedInterface } from '../../domain/Distribution/AmountDistributed'

interface SessionFromApi {
  id: string
  month: string
  year: {
    date: string
  }
  status: string
  type: string
  amounts: AmountDistributedInterface[]
  label: string
  createdAt: string
  updatedAt: string
  deletedAt: string
  paidAt: string
  sendAt: string
}

class SessionPresenter {
  constructor(public sessionFromAPI: any) {}

  load(): SessionInterface {
    return {
      id: this.sessionFromAPI.id,
      month: this.sessionFromAPI.month,
      year: parseInt(dayjs(this.sessionFromAPI.year).format('YYYY')),
      status: this.sessionFromAPI.status,
      type: this.sessionFromAPI.type,
      amounts: this.sessionFromAPI.amounts.map((amount: AmountDistributedInterface) => {
        return {
          product: {
            id: amount.product.id,
            label: amount.product.label,
            ribTitle: amount.product.ribTitle,
            ribBank: amount.product.ribBank,
            ribIban: amount.product.ribIban,
            ribSwift: amount.product.ribSwift,
            periodicity: amount.product.periodicity,
            term: amount.product.term,
          },
          financialAmount: amount.financialAmount,
          financialAmountPerShare: amount.financialAmountPerShare,
          propertyAmount: amount.propertyAmount,
          propertyAmountPerShare: amount.propertyAmountPerShare,
          nbShares: amount.nbShares,
          totalBrut: amount.totalBrut,
        }
      }),
      label: this.sessionFromAPI.label,
      createdAt: this.sessionFromAPI.createdAt,
      updatedAt: this.sessionFromAPI.updatedAt,
      deletedAt: this.sessionFromAPI.deletedAt,
      paidAt: this.sessionFromAPI.paidAt,
      sendAt: this.sessionFromAPI.sendAt,
    }
  }
}

export default SessionPresenter
