import React, { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import { SubmitHandler, useForm, useFormState } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
// Notification lib
import 'react-toastify/dist/ReactToastify.min.css'
import { ProductInterface } from '../../../../../../domain/Product/Product'
import { ScaleDerogatory, ScalesAvailable } from '../../../../../../domain/Product/Scale'
import { ScaleItemInterface } from '../../../../../../domain/Product/ScaleItem'
import PartnerGateway from '../../../../../../gateway/Partner/PartnerGateway'
import ProductGateway from '../../../../../../gateway/Product/ProductGateway'
import { setOpenPartnerConventionAnnexFormEvent } from '../../../../../store/component/event'
import { addConventionAnnex, updateConventionAnnex } from '../../../../../store/component/partner'
import { useAppDispatch, useAppSelector } from '../../../../../store/hook'
import blockScrollBody from '../../../../util/BlockScroll'
import { usePrompt } from '../../../../util/Navigation'
import { setReadOnlyFormElements } from '../../../../util/setReadOnlyFormElements'
import getClassForOverlay from '../../../../util/Sidebar'
import { toastError, toastSuccess } from '../../../../util/Toast'
import ProductDalalist, { ProductDalalistItem } from '../../../Datalist/Product/ProductDalalist'
import Input from '../../../Elements/Input'
import InputDate from '../../../Elements/InputDate'
import ConfirmationModal from '../../../Modal/ConfirmationModal'
import ScaleBlock from './ScaleBlock'

export interface IFormConventionAnnexInput {
  id: string
  code: string | null
  date: string | null
  dateStart: string | null
  dateEnd: string | null
  particularite: string | null
  product: {
    id: string | null
    label: string | null
    value: string | null
  }
  scCommissionEarnedPercent: string
  scales: {
    [key: string]: {
      [key: string]: ScaleItemInterface
    } & { date: string }
  }
  scCommissionNotEarnedPercent: string
  scCommissionEarned: string
  scCommissionNotEarned: string
}

type Props = {
  isLectureMode?: boolean
}

const AddConventionAnnex: FunctionComponent<Props> = ({ isLectureMode }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const partnerGateway = new PartnerGateway()

  const [product, setProduct] = useState<ProductInterface | null>(null)
  const [productIsSc, setProductIsSc] = useState<boolean | null | undefined>(null)
  const [availableScales, setAvailableScales] = useState<ScalesAvailable | []>([])
  const [scaleSelected, setScaleSelected] = useState<{ scaleLabelCategory: string; subScaleLabelCategory: string[] }>({
    scaleLabelCategory: '',
    subScaleLabelCategory: [],
  })
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const openPartnerConventionAnnex = useAppSelector(state => state.event.openPartnerConventionAnnexForm)

  const {
    register,
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<IFormConventionAnnexInput>()

  const watchProduct = watch('product')
  const watchDateStart = watch('dateStart')
  const watchDateEnd = watch('dateEnd')
  const [missingScaleDate, setMissingScaleDate] = useState<boolean>(false)

  useEffect(() => {
    if (
      openPartnerConventionAnnex.partner?.id &&
      watchProduct &&
      watchProduct.id &&
      watchDateStart &&
      watchDateEnd &&
      !isLoading
    ) {
      new ProductGateway()
        .getScalesAvailable(
          openPartnerConventionAnnex?.conventionAnnex?.id || null,
          openPartnerConventionAnnex.partner.id,
          watchProduct.id,
          watchDateStart,
          watchDateEnd
        )
        .then(response => {
          if (response) {
            setAvailableScales(response)
          }
        })
    }
  }, [watchProduct?.id, watchDateStart, watchDateEnd])

  useEffect(() => {
    blockScrollBody(openPartnerConventionAnnex.show)
    if (openPartnerConventionAnnex.conventionAnnex) {
      setIsLoading(true)
      new PartnerGateway()
        .getConvention(openPartnerConventionAnnex?.partner?.id || '', openPartnerConventionAnnex.conventionAnnex.id)
        .then(response => {
          if (response?.product) {
            setProduct({ ...response.product })
          }
          setProductIsSc(
            response?.scCommissionEarned !== null ||
              response?.scCommissionEarnedPercent !== null ||
              response?.scCommissionNotEarned !== null ||
              response?.scCommissionNotEarnedPercent !== null
          )
          setValue('id', response?.id || '')
          setValue('code', response?.code || null)
          setValue('date', response?.date || null)
          setValue('particularite', response?.particularite || null)
          setValue('product', {
            id: response?.product?.id ?? null,
            label: response?.product?.generalInformation?.acronyme ?? null,
            value: response?.product?.generalInformation?.acronyme ?? null,
          })
          setValue('dateStart', response?.period?.start || '', { shouldDirty: true })
          setValue('dateEnd', response?.period?.end || '', { shouldDirty: true })
          setValue('scCommissionEarned', response?.scCommissionEarned?.toString() || '')
          setValue('scCommissionEarnedPercent', response?.scCommissionEarnedPercent?.toString() || '')
          setValue('scCommissionNotEarned', response?.scCommissionNotEarned?.toString() || '')
          setValue('scCommissionNotEarnedPercent', response?.scCommissionNotEarnedPercent?.toString() || '')

          const scaleSelected: { scaleLabelCategory: string; subScaleLabelCategory: string[] } = {
            scaleLabelCategory: '',
            subScaleLabelCategory: [],
          }
          if (response?.scaleItems && response?.scaleItems[0]) {
            scaleSelected.scaleLabelCategory =
              response?.scaleItems[0]?.scaleCategory?.label.replaceAll(' ', '').toLowerCase() || ''
            response.scaleItems.forEach(scale => scaleSelected.subScaleLabelCategory.push(scale.id))
          }

          setScaleSelected({ ...scaleSelected })
          return {
            partnerId: openPartnerConventionAnnex?.partner?.id || null,
            productId: response?.product?.id || null,
            start: response?.period?.start || null,
            end: response?.period?.end || null,
          }
        })
        .then(
          (data: { partnerId: string | null; productId: string | null; start: string | null; end: string | null }) => {
            if (data.partnerId && data.productId && data.start && data.end) {
              new ProductGateway()
                .getScalesAvailable(
                  openPartnerConventionAnnex?.conventionAnnex?.id || null,
                  data.partnerId,
                  data.productId,
                  data.start,
                  data.end
                )
                .then(response => {
                  const filteredScalesAvailable = Object.entries(response)
                  const filteredScalesAvailableToObject: ScalesAvailable = {}

                  filteredScalesAvailable.forEach(el => (filteredScalesAvailableToObject[el[0]] = el[1]))

                  setAvailableScales(() => {
                    return { ...filteredScalesAvailableToObject }
                  })
                })
            }
            setIsLoading(current => false)
          }
        )
    } else {
      setProduct(null)
      setAvailableScales([])
      setValue('id', 'provisional_' + crypto.getRandomValues(new Uint32Array(1)).join())
      setValue('code', '')
      setValue('date', '')
      setValue('particularite', '')
      setValue('product', { id: '', label: '', value: '' })
      setValue('scCommissionEarnedPercent', '')
      setValue('scCommissionNotEarnedPercent', '')
      setIsLoading(false)
    }
  }, [openPartnerConventionAnnex])

  const fetchProduct = (product: ProductDalalistItem) => {
    if (product?.id) {
      new ProductGateway().get(product.id).then(response => {
        setProduct(response)
        setValue('scCommissionEarned', response?.commission?.sc?.commissionEarned ?? '')
        setValue('scCommissionNotEarned', response?.commission?.sc?.commissionNotEarned ?? '')
      })
    }
  }

  const onSubmit: SubmitHandler<IFormConventionAnnexInput> = data => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return <ConfirmationModal onConfirm={() => onConfirm(data)} onClose={onClose} />
      },
    })
  }

  const onConfirm = (data: IFormConventionAnnexInput) => {
    const conventionAnnex = {
      id: data.id,
      code: data.code,
      date: data.date,
      dateStart: data.dateStart,
      dateEnd: data.dateEnd,
      particularite: data.particularite,
      product: product,
      scaleItems: scaleSelected.subScaleLabelCategory,
      derogatory: {
        date: '',
        items: [] as ScaleDerogatory[],
      },
      scCommissionEarned: data.scCommissionEarned || '',
      scCommissionEarnedPercent: data.scCommissionEarnedPercent || '',
      scCommissionNotEarned: data.scCommissionNotEarned || '',
      scCommissionNotEarnedPercent: data.scCommissionNotEarnedPercent || '',
    }

    if (
      scaleSelected.scaleLabelCategory.toLowerCase().includes('dérogatoire') &&
      data.scales[scaleSelected.scaleLabelCategory]
    ) {
      const scale = data.scales[scaleSelected.scaleLabelCategory]
      conventionAnnex.scaleItems = []
      conventionAnnex.derogatory.date = scale.date || ''

      const idUpFront = '06d6a9ab-0bff-4929-89bb-5116fe44c963'
      const idRunning1 = 'e7646819-474d-4318-a5c9-9c8664447086'
      const idRunning2 = 'ca41bf4a-327a-41ef-8ea1-45b85ecb1c35'

      const parseDerogatory = Object.entries<ScaleItemInterface>(scale)
        .map(([key, value]): ScaleDerogatory | undefined => {
          if (key !== 'date') {
            return {
              category: key.toLowerCase().includes('front')
                ? idUpFront
                : key.toLowerCase().includes('running 1')
                  ? idRunning1
                  : idRunning2,
              id: value.id,
              value: Number(value.value),
              approbationAt: value.approbationAt,
              applicationAt: value.applicationAt,
              start: value?.period?.start || '',
              end: value?.period?.end || '',
              derogatorySelected: true,
            }
          } else return undefined
        })
        .filter((subScale): subScale is ScaleDerogatory => {
          return subScale !== undefined && scaleSelected.subScaleLabelCategory.includes(subScale.id)
        })

      conventionAnnex.derogatory.items = [...parseDerogatory]
    }

    const messages: { [key: string]: string } = {
      '06d6a9ab-0bff-4929-89bb-5116fe44c963': 'Up Front',
      'e7646819-474d-4318-a5c9-9c8664447086': 'Running 1',
      'ca41bf4a-327a-41ef-8ea1-45b85ecb1c35': 'Running 2',
    }

    setIsLoading(true)
    if (openPartnerConventionAnnex.conventionAnnex) {
      partnerGateway
        .updateConvention(conventionAnnex, openPartnerConventionAnnex?.partner?.id || undefined, conventionAnnex.id)
        .then(res => {
          if (res.scaleItems) {
            conventionAnnex.scaleItems = res.scaleItems
            conventionAnnex.id = res.id
          }
          dispatch(updateConventionAnnex(conventionAnnex))
          dispatch(
            setOpenPartnerConventionAnnexFormEvent({
              show: false,
              conventionAnnex: null,
            })
          )
          reset()
          setValue('id', 'provisional_' + crypto.getRandomValues(new Uint32Array(1)).join())
          toastSuccess(t('partner.notify.add-convention-annex-success'))
        })
        .catch(error => {
          if (error?.data?.errors?.period && Object.keys(messages).includes(`${error.data.errors.period}`)) {
            toastError(t('partner.notify.derogatory-overlap-error', { type: messages[error.data.errors.period] }))
            return
          }
          toastError(t('partner.notify.add-convention-annex-error'))
        })
        .finally(() => {
          setIsLoading(false)
        })
    } else {
      partnerGateway
        .addConvention(conventionAnnex, openPartnerConventionAnnex?.partner?.id || undefined)
        .then(res => {
          if (res.scaleItems) {
            conventionAnnex.scaleItems = res.scaleItems
            conventionAnnex.id = res.id
          }
          dispatch(addConventionAnnex(conventionAnnex))
          dispatch(
            setOpenPartnerConventionAnnexFormEvent({
              show: false,
              conventionAnnex: null,
            })
          )
          reset()
          setValue('id', 'provisional_' + crypto.getRandomValues(new Uint32Array(1)).join())
          toastSuccess(t('partner.notify.add-convention-annex-success'))
        })
        .catch(error => {
          if (error?.data?.errors?.period && Object.keys(messages).includes(`${error.data.errors.period}`)) {
            toastError(t('partner.notify.derogatory-overlap-error', { type: messages[error.data.errors.period] }))
            return
          }
          toastError(t('partner.notify.add-convention-annex-error'))
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  const { isDirty } = useFormState({ control })

  usePrompt(isDirty, handleSubmit(onConfirm))

  function handleClose() {
    reset()
    dispatch(setOpenPartnerConventionAnnexFormEvent({ show: false, conventionAnnex: null }))

    setProduct(null)
    setAvailableScales([])
    setMissingScaleDate(false)
    setScaleSelected({ scaleLabelCategory: '', subScaleLabelCategory: [] })
  }

  const onInvalid = () => {
    if (errors?.scales && errors.scales[scaleSelected.scaleLabelCategory]) {
      setMissingScaleDate(true)
    } else {
      setMissingScaleDate(false)
    }
    toastError(t('common.toasts.form-invalid'))
  }

  const renderFooter = () => {
    if (isLectureMode) return null
    return (
      <footer className='sidebar__footer'>
        <button type='submit' className='button button--ink-2 u-mrm' disabled={isLoading}>
          {t('common.save')}
        </button>
        <button type='button' className='button button--ink-2 button--ink-2--outline' onClick={() => handleClose()}>
          {t('common.cancel')}
        </button>
      </footer>
    )
  }

  const measuredRef = useCallback(node => {
    if (node !== null && isLectureMode) {
      setReadOnlyFormElements(true, node)
    }
  }, [])

  const commissionBlock = (
    <div className={`form-bloc-scale form--bloc--general-scale`}>
      <div className='form-bloc__title'>{t('partner.form.commission.commission')}</div>
      <table className='w-full'>
        <thead>
          <tr>
            <th />
            <th />
            <th className='form-control__label'>{t('partner.form.commission.percentage-awarded')}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <label htmlFor='scCommissionEarnedPercent' className='form-control__label'>
                {t('partner.form.commission.commission-earned')}
              </label>
            </td>
            <td>
              <Input
                register={register}
                classes='u-mrb'
                type={'text'}
                name={'scCommissionEarned'}
                id={'scCommissionEarned'}
                defaultValue={product?.commission?.commissionEarned}
                readOnly
              />
            </td>
            <td>
              <Input
                register={register}
                type={'number'}
                name={'scCommissionEarnedPercent'}
                id={'scCommissionEarnedPercent'}
                min={0}
                max={100}
                step='.01'
              />
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor='scCommissionNotEarnedPercent' className='form-control__label'>
                {t('partner.form.commission.commission-not-earned')}
              </label>
            </td>
            <td>
              <Input
                register={register}
                classes='u-mrb'
                type={'text'}
                name={'scCommissionNotEarned'}
                id={'scCommissionNotEarned'}
                defaultValue={product?.commission?.commissionNotEarned}
                readOnly
              />
            </td>
            <td>
              <Input
                register={register}
                type={'number'}
                name={'scCommissionNotEarnedPercent'}
                id={'scCommissionNotEarnedPercent'}
                min={0}
                max={100}
                step='.01'
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )

  return (
    <>
      <div className={`overlay ${getClassForOverlay(openPartnerConventionAnnex.show)}`} onClick={() => handleClose()} />
      <div
        className={`sidebar sidebar--right sidebar--right ${openPartnerConventionAnnex.show ? 'sidebar--active' : ''}`}
      >
        {!isLoading && (
          <form
            onSubmit={handleSubmit(onSubmit)}
            className='form-bloc form-bloc--partner-address-form'
            ref={measuredRef}
          >
            <div className='sidebar__content'>
              <div className='title'>
                {openPartnerConventionAnnex.conventionAnnex
                  ? t('partner.form.convention-annex.edit')
                  : t('partner.form.convention-annex.add')}
              </div>
              <div className='form-bloc__form flex-container'>
                <ProductDalalist
                  classes='col-md-6 relative'
                  id='product'
                  name='product'
                  label={t('movement.redemption-withdrawal.form.general-information.product-acronym')}
                  labelClasses='mandatory-field'
                  control={control}
                  defaultValue={getValues('product')}
                  disabled={isLectureMode}
                  customOnchange={fetchProduct}
                />
                <div className='col-md-6'>
                  <div className='form-control'>
                    <label htmlFor='number' className='form-control__label mandatory-field'>
                      {t('partner.form.convention-annex.number')}
                    </label>
                    <div className='form-control__input'>
                      <input type='text' {...register('code')} required={true} />
                    </div>
                  </div>
                </div>
                <InputDate
                  required={true}
                  id='date'
                  name='date'
                  register={register}
                  control={control}
                  label={t('partner.form.convention-annex.date')}
                  classes='col-md-6'
                  labelClasses='mandatory-field'
                  readOnly={isLectureMode}
                />
                <div className='col-md-6'>
                  <div className='form-control form-control--textarea'>
                    <label htmlFor='particularities' className='form-control__label'>
                      {t('partner.form.convention-annex.particularities')}
                    </label>
                    <div className='form-control__input'>
                      <textarea {...register('particularite')} />
                    </div>
                  </div>
                </div>
                <InputDate
                  required={true}
                  id='dateStart'
                  name='dateStart'
                  register={register}
                  control={control}
                  label={t('partner.form.convention-annex.dateStart')}
                  classes='col-md-6'
                  labelClasses='mandatory-field'
                  readOnly={isLectureMode}
                />
                <InputDate
                  required={false}
                  id='dateEnd'
                  name='dateEnd'
                  register={register}
                  control={control}
                  label={t('partner.form.convention-annex.dateEnd')}
                  classes='col-md-6'
                  readOnly={isLectureMode}
                />
                <div className='col-md-12'>
                  {product && (
                    <>
                      <p className='u-mts'>{t('partner.form.convention-annex.subtitle')}</p>
                      {(product?.productType?.description === 'SC' || productIsSc) && commissionBlock}
                      {product?.productType?.description !== 'SC' &&
                        availableScales &&
                        Object.entries(availableScales).map(([key, value]) => {
                          return (
                            <ScaleBlock
                              scale={value}
                              register={register}
                              control={control}
                              setValue={setValue}
                              key={key.replaceAll(' ', '').toLowerCase()}
                              nameScale={value.label.toLowerCase().replaceAll(' ', '')}
                              scaleSelected={scaleSelected}
                              setScaleSelected={setScaleSelected}
                            />
                          )
                        })}
                    </>
                  )}
                </div>
              </div>
            </div>
            {renderFooter()}
          </form>
        )}
      </div>
    </>
  )
}
export default AddConventionAnnex
