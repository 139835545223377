import React, { FunctionComponent, useEffect } from 'react'
import { useForm, useFormState } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
// Notification lib
import 'react-toastify/dist/ReactToastify.min.css'
import { FilterDismembermentLedgerInterface } from '../../../../../domain/DismembermentLedger/DismembermentLedgerList'
import { ReferentielInterface } from '../../../../../domain/Referentiel/ReferentielInterface'
import { ReferentielItemInterface } from '../../../../../domain/Referentiel/ReferentielItemInterface'
import { setOpenDatalistFilterDismembermentLedger } from '../../../../store/component/event'
import { useAppDispatch, useAppSelector } from '../../../../store/hook'
import blockScrollBody from '../../../util/BlockScroll'
import { usePrompt } from '../../../util/Navigation'
import getClassForOverlay from '../../../util/Sidebar'
import ProductDalalist from '../../Datalist/Product/ProductDalalist'
import MultiSelectCustom from '../../Elements/MultiSelect'

const DismembermentLedgerDatalistFilter: FunctionComponent = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const referential: ReferentielInterface | null = useAppSelector(({ referential }) => referential.referential)
  const durationReferential: ReferentielItemInterface[] = [
    { value: 3, label: '3 ans', isEnabled: true },
    { value: 4, label: '4 ans', isEnabled: true },
    { value: 5, label: '5 ans', isEnabled: true },
    { value: 6, label: '6 ans', isEnabled: true },
    { value: 7, label: '7 ans', isEnabled: true },
    { value: 8, label: '8 ans', isEnabled: true },
    { value: 9, label: '9 ans', isEnabled: true },
    { value: 10, label: '10 ans', isEnabled: true },
    { value: 11, label: '11 ans', isEnabled: true },
    { value: 12, label: '12 ans', isEnabled: true },
    { value: 13, label: '13 ans', isEnabled: true },
    { value: 14, label: '14 ans', isEnabled: true },
    { value: 15, label: '15 ans', isEnabled: true },
    { value: 16, label: '16 ans', isEnabled: true },
    { value: 17, label: '17 ans', isEnabled: true },
    { value: 18, label: '18 ans', isEnabled: true },
    { value: 19, label: '19 ans', isEnabled: true },
    { value: 20, label: '20 ans', isEnabled: true },
  ]
  const openDatalistFilterDismembermentLedger = useAppSelector(
    state => state.event.openDatalistFilterDismembermentLedger
  )

  const { control, handleSubmit, reset, setValue, getValues } = useForm<FilterDismembermentLedgerInterface>()
  const allFilters = false

  useEffect(() => {
    blockScrollBody(openDatalistFilterDismembermentLedger.show)
  }, [openDatalistFilterDismembermentLedger])

  useEffect(() => {
    setValue('status', openDatalistFilterDismembermentLedger.filters.status)
    setValue('product', openDatalistFilterDismembermentLedger.filters.product)
    setValue('keywords', null)
    setValue('executions', openDatalistFilterDismembermentLedger.filters.executions)
    setValue('typeDurations', openDatalistFilterDismembermentLedger.filters.typeDurations)
    setValue('preferences', openDatalistFilterDismembermentLedger.filters.preferences)
  }, [openDatalistFilterDismembermentLedger.filters, setValue])

  const onConfirm = (data: FilterDismembermentLedgerInterface) => {
    reset(data)
    dispatch(
      setOpenDatalistFilterDismembermentLedger({
        show: false,
        count: openDatalistFilterDismembermentLedger.count,
        filters: {
          status: data.status,
          product: data.product,
          executions: data.executions,
          typeDurations: data.typeDurations,
          preferences: data.preferences,
          keywords: {
            'bare-owners': '',
            usufructuaries: '',
          },
        },
      })
    )
  }

  const { isDirty } = useFormState({
    control,
  })
  usePrompt(isDirty, handleSubmit(onConfirm))

  function handleClose() {
    dispatch(
      setOpenDatalistFilterDismembermentLedger({
        show: false,
        count: 0,
        filters: {
          product: {
            id: '',
            value: '',
            label: '',
          },
          executions: [],
          typeDurations: [],
          preferences: [],
          keywords: {
            'bare-owners': '',
            usufructuaries: '',
          },
        },
      })
    )
  }

  return (
    <>
      {referential && (
        <>
          <div
            className={`overlay ${getClassForOverlay(openDatalistFilterDismembermentLedger.show)}`}
            onClick={() => handleClose()}
          />
          <div
            className={`sidebar sidebar--right sidebar--right ${
              openDatalistFilterDismembermentLedger.show ? 'sidebar--active' : ''
            }`}
          >
            <form onSubmit={handleSubmit(onConfirm)} className='form-bloc form-bloc--partner-address-form'>
              <div className='sidebar__content'>
                <div className='title'>{t('common.filters')}</div>
                <div className='form-bloc__form flex-container'>
                  <MultiSelectCustom
                    id='status'
                    name='status'
                    classes='col-md-6'
                    control={control}
                    label={t('dismemberment-ledger.filter.status')}
                    defaultValue={
                      referential?.wallet.status.filter(
                        (status: ReferentielItemInterface) => status.value === 'confirmed'
                      ) ?? []
                    }
                    options={referential?.wallet.status || []}
                    customOnChange={options => {
                      setValue('status', options)
                    }}
                  />
                  <ProductDalalist
                    classes='col-md-6'
                    id='product'
                    name='product'
                    label={t('dismemberment-ledger.filter.product')}
                    control={control}
                    defaultValue={getValues('product')}
                  />
                  <MultiSelectCustom
                    id='executions'
                    name='executions'
                    classes='col-md-6'
                    control={control}
                    label={t('dismemberment-ledger.filter.executions')}
                    options={referential?.wallet_subscription.execution_mandat || []}
                    customOnChange={options => {
                      setValue('executions', options)
                    }}
                  />
                  <MultiSelectCustom
                    id='typeDurations'
                    name='typeDurations'
                    classes='col-md-6'
                    control={control}
                    label={t('dismemberment-ledger.filter.typeDurations')}
                    options={durationReferential || []}
                    customOnChange={options => {
                      setValue('typeDurations', options)
                    }}
                  />
                  {allFilters && (
                    <>
                      <MultiSelectCustom
                        id='preferences'
                        name='preferences'
                        classes='col-md-6'
                        control={control}
                        label={t('dismemberment-ledger.filter.preferences')}
                        options={referential?.wallet_subscription.execution_mandat || []}
                        customOnChange={options => {
                          setValue('preferences', options)
                        }}
                      />
                    </>
                  )}
                  <footer className='sidebar__footer'>
                    <button type='submit' className='button button--ink-2 u-mrm'>
                      {t('common.save')}
                    </button>
                    <button
                      type='button'
                      className='button button--ink-2 button--ink-2--outline'
                      onClick={() => handleClose()}
                    >
                      {t('common.cancel')}
                    </button>
                  </footer>
                </div>
              </div>
            </form>
          </div>
        </>
      )}
    </>
  )
}

export default DismembermentLedgerDatalistFilter
