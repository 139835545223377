import React from 'react'
import { useTranslation } from 'react-i18next'
import { TableProps } from './Props'

const DismembermentTableHeader = ({ table }: TableProps) => {
  const { t } = useTranslation()

  return (
    <thead>
      <tr className='table__header'>
        <th align='left'>{t('dismemberment-table.heading.years')}</th>
        {table.lines.map(line => (
          <th key={line.years}>{line.years}</th>
        ))}
      </tr>
    </thead>
  )
}

export default DismembermentTableHeader
