import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

interface FormFooterProps {
  isLectureMode: boolean
  onSubmit?: () => void
  onCancel?: () => void
  cancelRedirectUrl?: string
  customClassName?: string
}

const FormFooter: React.FC<FormFooterProps> = ({
  isLectureMode,
  onSubmit,
  onCancel,
  cancelRedirectUrl,
  customClassName,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  if (isLectureMode) {
    return null
  }

  return (
    <footer className={`form-bloc__footer ${customClassName || ''}`}>
      <button type='button' onClick={onSubmit} className='button button--ink-2'>
        {t('common.save')}
      </button>
      <button
        type='button'
        className='button button--ink-2 button--ink-2--outline'
        onClick={() => {
          if (onCancel) {
            onCancel()
          } else if (cancelRedirectUrl) {
            navigate(cancelRedirectUrl)
          }
        }}
      >
        {t('common.cancel')}
      </button>
    </footer>
  )
}

export default FormFooter
