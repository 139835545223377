import { useCallback, useEffect, useState } from 'react'
import {
  DismembermentTableListInterface,
  FilterDismembermentTableInterface,
} from '../../../domain/DismembermentTable/DismembermentTableList'
import DismembermentTableGateway from '../../../gateway/DismembermentTable/DismembermentTableGateway'

const useDismembermentTableList = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [dismembermentTables, setDismembermentTables] = useState<DismembermentTableListInterface[] | null>(null)
  const [count, setCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(100)
  const [filter, setFilter] = useState<FilterDismembermentTableInterface | null>(null)
  const [sort, setSort] = useState<string | null>(null)
  const [order, setOrder] = useState<string | null>(null)

  const fetchDismembermentTables = useCallback(async () => {
    setIsLoading(true)
    try {
      const gateway = new DismembermentTableGateway()
      const response = await gateway.getList(currentPage, itemsPerPage, filter, undefined, sort, order)

      if (response) {
        setDismembermentTables(response.data)
        setCount(response.numberOfItems)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }, [currentPage, itemsPerPage, filter, sort, order])

  useEffect(() => {
    fetchDismembermentTables()
  }, [fetchDismembermentTables])

  return {
    isLoading,
    dismembermentTables,
    count,
    currentPage,
    itemsPerPage,
    setCurrentPage,
    setItemsPerPage,
    setFilter,
    setSort,
    setOrder,
  }
}

export default useDismembermentTableList
