interface RecurrenceInterface {
  id: string | null
  status: string | null
  periodicity: string | null
  shareCount: number | null
  sharePrice: number | null
  totalAmount: number | null
  rumCode: string | null
  rumStatus: string | null
  period: { start: string | null; end: string | null }
  mode: string | null
}

class Recurrence implements RecurrenceInterface {
  public id: string | null
  public status: string | null
  public periodicity: string | null
  public shareCount: number | null
  public sharePrice: number | null
  public totalAmount: number | null
  public rumCode: string | null
  public rumStatus: string | null
  public period: { start: string | null; end: string | null }
  public mode: string | null

  constructor(
    id: string | null,
    status: string | null,
    periodicity: string | null,
    shareCount: number | null,
    sharePrice: number | null,
    totalAmount: number | null,
    rumCode: string | null,
    rumStatus: string | null,
    period: { start: string | null; end: string | null },
    mode: string | null
  ) {
    this.id = id
    this.status = status
    this.periodicity = periodicity
    this.shareCount = shareCount
    this.sharePrice = sharePrice
    this.totalAmount = totalAmount
    this.rumCode = rumCode
    this.rumStatus = rumStatus
    this.period = period
    this.mode = mode
  }
}

export { Recurrence }
export type { RecurrenceInterface }
