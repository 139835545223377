import React, { FormEvent, FunctionComponent, ReactElement, SelectHTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'
import { ReferentielItemInterface } from '../../../../domain/Referentiel/ReferentielItemInterface'
import FieldErrorMessage from '../FieldErrorMessage/FieldErrorMessage'
import { IFormFieldProps } from './IFormFieldProps'

interface IProps extends SelectHTMLAttributes<HTMLSelectElement>, IFormFieldProps {
  classes: string
  containerClasses?: string
  id: string
  name: string
  label?: string | ReactElement
  labelClasses?: string
  valueClasses?: string
  options: ReferentielItemInterface[] | null
  onChange?: (e: FormEvent<HTMLSelectElement>) => void
  noChoiceOption?: boolean
  errorMessage?: string
  defaultOption?: ReferentielItemInterface
}

const SelectCustom: FunctionComponent<IProps> = ({
  id,
  name,
  classes,
  containerClasses,
  label,
  labelClasses,
  valueClasses,
  onChange,
  register,
  required,
  noChoiceOption,
  options,
  errorMessage,
  defaultOption,
  ...rest
}) => {
  const { t } = useTranslation()

  console.log(defaultOption?.value)

  const select = (
    <div className='form-control__input'>
      <select
        id={id}
        required={required}
        disabled={rest.readOnly}
        name={name}
        title={rest.help}
        {...register(name, { onChange })}
        className={valueClasses ?? ''}
        defaultValue={defaultOption?.value || ''}
      >
        {!noChoiceOption && <option value=''>{t('common.choice-option')}</option>}
        {options?.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {errorMessage && <FieldErrorMessage message={errorMessage} />}
    </div>
  )

  return (
    <div className={classes}>
      {label && (
        <div className={`form-control ${containerClasses}`}>
          <label htmlFor={id} className={`form-control__label ${labelClasses} ${required ? 'mandatory-field' : ''}`}>
            {label}
          </label>
          {select}
        </div>
      )}
      {!label && select}
    </div>
  )
}

export default SelectCustom
