import React, { FormEvent, FunctionComponent, InputHTMLAttributes, useState } from 'react'
import FieldErrorMessage from '../FieldErrorMessage/FieldErrorMessage'
import { IFormFieldProps } from './IFormFieldProps'
import { validators } from './Validation'

interface IProps extends InputHTMLAttributes<HTMLInputElement>, IFormFieldProps {
  id: string
  name: string
  classes?: string
  labelClasses?: string
  inputClasses?: string
  enableValidation: boolean
}

const Bic: FunctionComponent<IProps> = ({
  id,
  name,
  classes,
  label,
  register,
  error,
  required,
  labelClasses,
  inputClasses,
  enableValidation,
  ...rest
}) => {
  const [errorMsg, setErrorMsg] = useState<string | null>(null)

  const validateBic = (bic: string) => {
    if (bic === '' || !bic) {
      setErrorMsg(null)
      return true
    }

    const validate = validators['bic']

    if (validate) {
      const errorMessage = validate(bic)
      if (errorMessage) {
        setErrorMsg(errorMessage)
        return false
      }

      setErrorMsg(null)
      return true
    }
  }

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value
    validateBic(newValue)
  }

  return (
    <>
      <div className={classes ? `${classes}` : ''}>
        {label && (
          <>
            <div className='form-control'>
              <div className='flex items-end'>
                <label
                  htmlFor={id}
                  className={
                    (labelClasses ? labelClasses + ' form-control__label' : 'form-control__label') +
                    (required ? ' mandatory-field ' : ' ')
                  }
                >
                  {label}
                </label>
              </div>
              <div className='form-control__input'>
                <input
                  type='text'
                  id={id}
                  name={name}
                  {...register(name, { required: required, validate: validateBic })}
                  onChange={handleChange}
                  className={inputClasses ? inputClasses : ''}
                />
                {errorMsg && <FieldErrorMessage message={errorMsg} />}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default Bic
