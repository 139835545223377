import { z } from 'zod'
import i18n from '../../../../../i18n/i18n'

export const schema = z
  .object({
    intraVatNumber: z.string().nullable().optional(),
    accountingAccount: z.string().nullable().optional(),
    invoiceMandate: z.string().nullable().optional(),
    invoiceMandateValidationDate: z.string().nullable().optional(),
    vatLiable: z.string().nullable().optional(),
    bankName: z.string().nullable().optional(),
    ibanCode: z.string().nullable().optional(),
    swiftCode: z.string().nullable().optional(),
    paymentMode: z.string().nullable().optional(),
    ownerName: z.string().nullable().optional(),
    commissioned: z.boolean(),
    hasCommissionRelation: z.boolean(),
  })
  .refine(
    data => {
      if (data.paymentMode === 'nothing') {
        return true
      }

      if (data.commissioned) {
        return !!data.ownerName && !!data.bankName && !!data.ibanCode && !!data.swiftCode && !!data.paymentMode
      }
      return true
    },
    {
      message: i18n.t('partner.form.commission.fields-required'),
      path: ['ownerName', 'bankName', 'ibanCode', 'swiftCode', 'paymentMode'],
    }
  )
