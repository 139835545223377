import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)

function StringDateToDateObject(dateString: string, format: string) {
  return dayjs(dateString, format)
}

function dateToString(date: Date | null, includeTime = false): string {
  if (!date) return ''

  return dayjs(date).format(includeTime ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY')
}

function stringToDate(date?: string | null, format = 'DD/MM/YYYY HH:mm'): Date | null {
  if (!date) return null

  const parsedDate = dayjs(date, format, true)
  return parsedDate.isValid() ? parsedDate.toDate() : null
}

export { StringDateToDateObject, dateToString, stringToDate }
