import React, { FunctionComponent, useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import Select from 'react-select'
import { ReferentielItemInterface } from '../../../../domain/Referentiel/ReferentielItemInterface'
import FieldErrorMessage from '../FieldErrorMessage/FieldErrorMessage'

type Props = {
  control: any
  classes: string
  id: string
  name: string
  label?: string
  options: ReferentielItemInterface[] | null
  value?: any
  defaultValue?: ReferentielItemInterface[]
  customOnChange: (e: ReferentielItemInterface[]) => void
  clearValues?: string
  selectAllOption?: boolean
  selectAllOptionLabel?: string
  disabled?: boolean
  placeholder?: string
  errorMessage?: string
}
const MultiSelectCustom: FunctionComponent<Props> = ({
  control,
  classes,
  id,
  name,
  label,
  options,
  defaultValue,
  customOnChange,
  clearValues,
  selectAllOption,
  selectAllOptionLabel,
  disabled,
  placeholder,
  errorMessage,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<ReferentielItemInterface[]>(defaultValue ?? [])
  const [offeredOptions, setOfferedOptions] = useState<ReferentielItemInterface[]>(options ?? [])

  useEffect(() => {
    setSelectedOptions(defaultValue as ReferentielItemInterface[])
  }, [defaultValue])

  useEffect(() => {
    customOnChange(selectedOptions as any)
  }, [selectedOptions])

  useEffect(() => {
    setOfferedOptions(
      selectAllOption && options
        ? ([{ value: 'selectAll', label: selectAllOptionLabel ?? 'select all options' }, ...options] as any)
        : (options as any)
    )
  }, [selectAllOption, options])

  useEffect(() => {
    if (clearValues) {
      setSelectedOptions([])
    }
  }, [clearValues])

  const handleSelectAllOption = (optionsSelect: ReferentielItemInterface[]) => {
    const findSelectAll = optionsSelect.find(opt => opt.value === 'selectAll')
    if (findSelectAll) {
      if (options && options?.length > 1) {
        setSelectedOptions([{ value: 'selectAll', label: selectAllOptionLabel ?? 'select all options' }])
      }

      setOfferedOptions([])
    } else {
      if (options) {
        setOfferedOptions([{ value: 'selectAll', label: selectAllOptionLabel ?? 'select all options' }, ...options])
      }
      setSelectedOptions(optionsSelect as any)
    }
  }

  const select = (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, name } }) => (
        <>
          <Select
            defaultValue={defaultValue}
            value={selectedOptions}
            onChange={optionsSelect => {
              if (selectAllOption) {
                handleSelectAllOption(optionsSelect as any)
              } else {
                setSelectedOptions(optionsSelect as any)
              }
            }}
            isMulti
            isClearable={true}
            closeMenuOnSelect={false}
            options={offeredOptions}
            isDisabled={disabled || false}
            placeholder={placeholder}
            styles={{
              control: (baseStyles, state) => {
                return {
                  ...baseStyles,
                  fontSize: '0.85714em',
                }
              },
            }}
          />
        </>
      )}
    />
  )

  return (
    <>
      <div className={`${classes}`}>
        {label && (
          <div className='form-control form-control--center'>
            <label htmlFor={id} className='form-control__label'>
              {label}
            </label>
            <div>
              {select}
              {errorMessage && <FieldErrorMessage message={errorMessage} />}
            </div>
          </div>
        )}
        {!label && select}
      </div>
    </>
  )
}
export default MultiSelectCustom
