import { City } from '../Referentiel/City/City'
import { Country } from '../Referentiel/Country/Country'

interface GeneralInformationInterface {
  createdAt: string
  updatedAt: string | null
  deletedAt: string | null
  acronyme: string
  label: string
  address: string
  postcode: string
  city: City | null
  country: Country | null
  visaAmf: string
  visaDate: string
  marketingDate: string
  isin: string
  kbis: string
  giin: string
  rcs: string
  rcsPlace: string
  aif: string
  lei: string
  liquidationDate: string
  validatedAt: string
  shareType: string
  shareCount: string
  shareLastNumber: string
  capital: string
  msConfrontation: boolean
  recurrence: boolean
  subscribable: string
  logo: []
  multiproduct: boolean
  subproduct: boolean
  registryKeeper: string
}

class GeneralInformation implements GeneralInformationInterface {
  public createdAt: string
  public updatedAt: string | null
  public deletedAt: string | null
  public acronyme: string
  public label: string
  public address: string
  public postcode: string
  public city: City | null
  public country: Country | null
  public visaAmf: string
  public visaDate: string
  public marketingDate: string
  public isin: string
  public kbis: string
  public giin: string
  public rcs: string
  public rcsPlace: string
  public aif: string
  public lei: string
  public liquidationDate: string
  public validatedAt: string
  public shareType: string
  public shareCount: string
  public shareLastNumber: string
  public capital: string
  public msConfrontation: boolean
  public recurrence: boolean
  public subscribable: string
  public logo: []
  public multiproduct: boolean
  public subproduct: boolean
  public registryKeeper: string

  constructor(
    createdAt: string,
    updatedAt: string | null,
    deletedAt: string | null,
    acronyme: string,
    label: string,
    address: string,
    postcode: string,
    city: City | null,
    country: Country | null,
    visaAmf: string,
    visaDate: string,
    marketingDate: string,
    isin: string,
    kbis: string,
    giin: string,
    rcs: string,
    rcsPlace: string,
    aif: string,
    lei: string,
    liquidationDate: string,
    validatedAt: string,
    shareType: string,
    shareCount: string,
    shareLastNumber: string,
    capital: string,
    msConfrontation: boolean,
    recurrence: boolean,
    subscribable: string,
    logo: [],
    multiproduct: boolean,
    subproduct: boolean,
    registryKeeper: string
  ) {
    this.createdAt = createdAt
    this.updatedAt = updatedAt
    this.deletedAt = deletedAt
    this.acronyme = acronyme
    this.label = label
    this.address = address
    this.postcode = postcode
    this.city = city
    this.country = country
    this.visaAmf = visaAmf
    this.visaDate = visaDate
    this.marketingDate = marketingDate
    this.isin = isin
    this.kbis = kbis
    this.giin = giin
    this.rcs = rcs
    this.rcsPlace = rcsPlace
    this.aif = aif
    this.lei = lei
    this.liquidationDate = liquidationDate
    this.validatedAt = validatedAt
    this.shareCount = shareCount
    this.shareType = shareType
    this.shareLastNumber = shareLastNumber
    this.capital = capital
    this.msConfrontation = msConfrontation
    this.recurrence = recurrence
    this.subscribable = subscribable
    this.logo = logo
    this.multiproduct = multiproduct
    this.subproduct = subproduct
    this.registryKeeper = registryKeeper
  }
}

export { GeneralInformation }
export type { GeneralInformationInterface }
